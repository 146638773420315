import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;
  React.useEffect(() => {
    if (playerRef.current) {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    } else {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));
    }
  }, [options, videoRef]);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <video
        ref={videoRef}
        x5-video-player-type="h5" //启用同层H5播放器，就是在视频全屏的时候，div可以呈现在视频层上(比如弹幕)
        x-webkit-airplay="true"
        webkit-playsinline="true" //playsinline可以让视频在小窗内播放，可以解决ios播放视频默认全屏问题，
        autoPlay
        preload="none" //向浏览器建议是否应在<video>加载元素后立即开始下载视频数据,立即开始加载视频（如果浏览器支持）。一些移动设备不会预加载视频以保护其用户的带宽/数据使用。这就是为什么该值被称为“自动”而不是像'true'
        controlsList="nodownload"
        loop="loop" //使视频在结束后立即重新开始
        data-wf-ignore="true"
        data-object-fit="cover"
        controls
        className="video-js vjs-big-play-centered"
        x5-video-player-fullscreen="true"
      />
    </div>
  );
};

export default VideoJS;
