import React, { useState, useEffect } from "react";
import { Mask } from "antd-mobile";
import VideoJS from "../../../components/VideoJS";
import PublicSearchBar from "../../../components/PublicSearchBar";
import CourseList from "../../../components/CourseList";
import { searchTitle } from "../../../utils/allSearchBar";
import { recordCourseList, recordCourseClick } from "../../../services/index";
import "./index.less";

function RecordCourse() {
  const [isActive, setIsActive] = useState(1); //切换
  const [visible, setVisible] = useState(false);
  const [video, setVideo] = useState("");
  const [type, setType] = useState();
  const [sourse, setSourse] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1); //页数
  const [hasMore, setHasMore] = useState(false);
  const [sourse2, setSourse2] = useState([]);
  const [dataList2, setDataList2] = useState([]);
  const [data2, setData2] = useState([]);
  const [page2, setPage2] = useState(1); //页数
  const [hasMore2, setHasMore2] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [keyData, setKeyData] = useState([]);

  useEffect(() => {
    fn({ type: 1, page: 1, pageSize: 10 });
  }, []);

  const fn = async (params) => {
    setType(params?.type);
    if (params?.type === 1) {
      let res = await recordCourseList(params);
      setData(res?.data.data_list);
      setSourse(res?.data?.data_list?.data);
      setHasMore(
        res?.data?.data_list?.current_page <= res?.data?.data_list?.last_page
      );
      setPage(res?.data?.data_list?.current_page);
    } else {
      let res = await recordCourseList(params);
      setData2(res?.data.data_list);
      setSourse2(res?.data?.data_list?.data);
      setHasMore2(
        res?.data?.data_list?.current_page <= res?.data?.data_list?.last_page
      );
      setPage2(res?.data?.data_list?.current_page);
    }
  };

  const loadMore = async () => {
    let curPage = page + 1;
    setPage(curPage);
    await fn({ type: 1, page: curPage, pageSize: 10 });
    setHasMore(data?.current_page <= data?.last_page);
    setDataList((val) => [...val, ...sourse]);
  };
  const loadMore2 = async () => {
    let curPage = page2 + 1;
    setPage2(curPage);
    await fn({ type: 2, page: curPage, pageSize: 10 });
    setHasMore2(data2?.current_page <= data2?.last_page);
    setDataList2((val) => [...val, ...sourse2]);
  };

  const getSearch = async (param) => {
    setKeyword(param);
    let res = await recordCourseList({ keyword: param });
    setKeyData(res?.data?.data_list?.data);
  };
  const handleRead = async (params) => {
    await recordCourseClick(params?.id);
    const newList = dataList.map((item) => {
      if (item.id === params?.id) {
        item.is_cat = 2;
      }
      return item;
    });
    setDataList(newList);
    const newList2 = dataList2.map((item) => {
      if (item.id === params?.id) {
        item.is_cat = 2;
      }
      return item;
    });
    setDataList2(newList2);
    setVideo(params?.video);
    setVisible(true);
  };

  const playerData = [
    {
      src: video,
      type: "video/mp4",
    },
  ];
  const activeType = async (params) => {
    setType(params);
    setIsActive(params);
    setDataList([]);
    setDataList2([]);
    await fn({ type: params, pageSize: 10, page: 1 });
  };

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: video,
        type: "video/mp4",
      },
    ],
  };
  return (
    <div className="recordCourse">
      <div className="course flexBoxAround">
        <div className="courseType" onClick={() => activeType(1)}>
          <div className={isActive === 1 ? "activeStyle" : "defaultStyle"}>
            文化课
          </div>
          <div
            className={
              isActive === 1 ? "activeBottomStyle" : "defaultBottomStyle"
            }
          ></div>
        </div>
        <div className="courseType" onClick={() => activeType(2)}>
          <div className={isActive === 2 ? "activeStyle" : "defaultStyle"}>
            实操课
          </div>
          <div
            className={
              isActive === 2 ? "activeBottomStyle" : "defaultBottomStyle"
            }
          ></div>
        </div>
      </div>

      <PublicSearchBar
        getSearch={getSearch}
        searchData={searchTitle?.searchCourse.title}
      />
      {keyword ? (
        <CourseList
          data={keyData}
          handleRead={handleRead}
          loadMore={loadMore}
          hasMore={hasMore}
        />
      ) : (
        <>
          {type === 1 ? (
            <>
              <CourseList
                data={dataList}
                handleRead={handleRead}
                loadMore={loadMore}
                hasMore={hasMore}
              />
            </>
          ) : (
            <CourseList
              data={dataList2}
              handleRead={handleRead}
              loadMore={loadMore2}
              hasMore={hasMore2}
            />
          )}
        </>
      )}

      {visible === true ? (
        <Mask
          visible={visible}
          onMaskClick={() => {
            setVisible(false);
          }}
        >
          <VideoJS options={videoJsOptions} playerData={playerData} />
        </Mask>
      ) : (
        ""
      )}
    </div>
  );
}

export default RecordCourse;
