import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Selector, TextArea } from "antd-mobile";
import Background from "../../../image/orderTrain/background.png";
import BackgroundAnswer from "../../../image/orderTrain/backgroundAnswer.png";
import ProblemAnswer from "../../../components/ProblemAnswer";
import {
  getOrderTrain,
  submitApi,
  collectApi,
  analysisApi,
} from "../../../services/index";

import {
  StarFill,
  StarOutline,
  CheckCircleFill,
  CloseCircleFill,
} from "antd-mobile-icons";
import "./index.less";

function OrderTest() {
  const navigate = useNavigate();
  const [is_collect, setIsCollect] = useState(1);
  const [isShowAnswer, SetisShowAnswer] = useState(false);
  const [subjectType, setSubjectType] = useState(1); //caseAnalysis briefAnswer
  const [initData, setInitData] = useState();
  const [answer, setAnswer] = useState();
  const [answerData, setAnswerData] = useState();
  const [options, setOptions] = useState([]);
  const [AnswerOptions, setAnswerOptions] = useState([]);
  const [code, setCode] = useState();
  const [value, setValue] = useState();
  const [exerciseId, setExerciseId] = useState();
  useEffect(() => {
    fn();
  }, []);

  const fn = async () => {
    let res = await getOrderTrain();
    setIsCollect(res?.data?.is_collect);
    setInitData(res?.data);
    setCode(res?.code);
    setExerciseId(res?.data?.id);
    const stype = res?.data?.type;
    setSubjectType(stype);
    if (stype === 1 || stype === 2 || stype === 4) {
      const options =
        res?.data?.answer?.map((item) => {
          item.label = item.name;
          item.value = item.id;
          return item;
        }) || [];
      setOptions(options);
    }
  };
  const fnAnswer = async (params) => {
    let response = await analysisApi(params);
    setAnswerData(response.data);
    const rtype = response?.data?.type;
    setSubjectType(rtype);
    setCode(response?.code);
    if (rtype === 1 || rtype === 2 || rtype === 4) {
      const AnswerOptions =
        response?.data?.answer?.map((item) => {
          item.label = item.name;
          item.value = item.id;
          return item;
        }) || [];
      setAnswerOptions(AnswerOptions);
    }
  };
  const getAnswer = (v) => {
    setAnswer(v);
  };
  const collect = (params) => {
    return async () => {
      await collectApi({ exercise_id: exerciseId });
      setIsCollect(params);
    };
  };
  const addData = {
    exercise_id: exerciseId,
    answer_id: answer,
  };
  const submit = async (param) => {
    if (code === 404) {
      Dialog.confirm({
        bodyClassName: "collectDetailsBody",
        content: (
          <>
            <div className="subjectTips">已无最新练习题</div>
            <div className="subjectTips">点击确认返回首页</div>
          </>
        ),
        showCloseButton: true,
        onConfirm: () => {
          navigate("/mytrain");
        },
      });
    } else {
      await submitApi({ ...addData });
      setAnswer();
      setValue();
      setAnswerOptions([]);
      fnAnswer(param);
      SetisShowAnswer(true);
    }
  };
  const nextSubject = () => {
    if (answerData.current_num === answerData.total_num || code === 404) {
      Dialog.confirm({
        bodyClassName: "collectDetailsBody",
        content: (
          <>
            <div className="subjectTips">已无最新练习题</div>
            <div className="subjectTips">点击确认返回首页</div>
          </>
        ),
        showCloseButton: true,
        onConfirm: () => {
          navigate("/mytrain");
        },
      });
    } else {
      SetisShowAnswer(false);
      setAnswer();
      setValue();
      setAnswerOptions([]);
      fn();
    }
  };
  return (
    <div className="orderTest">
      {isShowAnswer === false ? (
        <img className="background" src={Background} alt="" />
      ) : (
        <img className="background" src={BackgroundAnswer} alt="" />
      )}
      {isShowAnswer === false ? (
        <>
          <div className="problem">
            {subjectType === 1 ? (
              <>
                <div className="topic">
                  <div className="topicTitle">
                    <span className="title">{initData?.type_name}</span>
                    <span className="titleValue">
                      <span className="titleCurrent">
                        {initData?.current_num}
                      </span>
                      /{initData?.total_num}
                    </span>
                  </div>
                  <div className="topBackground"></div>
                  <div className="subjectTitle">{initData?.title}</div>
                  <div className="subjectOption">
                    <Selector
                      columns={1}
                      style={{
                        "--border-radius": "100px",
                        "--border": "solid transparent 1px",
                        "--checked-border":
                          "solid var(--adm-color-primary) 1px",
                        "--padding": "8px 24px",
                      }}
                      showCheckMark={false}
                      options={options}
                      onChange={(arr, extend) => {
                        getAnswer(arr.join(","));
                      }}
                    />
                  </div>
                </div>
              </>
            ) : subjectType === 2 ? (
              <div className="topic">
                <div className="topicTitle">
                  <span className="title">{initData?.type_name}</span>
                  <span className="titleValue">
                    <span className="titleCurrent">
                      {initData?.current_num}
                    </span>
                    /{initData?.total_num}
                  </span>
                </div>
                <div className="topBackground"></div>
                <div className="subjectTitle">{initData?.title}</div>
                <div className="subjectOption">
                  <Selector
                    showCheckMark={false}
                    columns={1}
                    options={options}
                    multiple={true}
                    onChange={(arr, extend) => {
                      getAnswer(arr.join(","));
                    }}
                  />
                </div>
              </div>
            ) : subjectType === 4 ? (
              <div className="topic">
                <div className="topicTitle">
                  <span className="title">{initData?.type_name}</span>
                  <span className="titleValue">
                    <span className="titleCurrent">
                      {initData?.current_num}
                    </span>
                    /{initData?.total_num}
                  </span>
                </div>
                <div className="topBackground"></div>
                <div className="subjectTitle">{initData?.title}</div>
                <div className="subjectjudge">
                  <Selector
                    showCheckMark={false}
                    columns={1}
                    options={options}
                    onChange={(arr, extend) => {
                      getAnswer(arr.join(","));
                    }}
                  />
                </div>
              </div>
            ) : (
              //案例分析和简答题
              <>
                <div className="areaposition">
                  <div className="topicTitlearea">
                    <span className="title">{initData?.type_name}</span>
                    <span className="titleValue">
                      <span className="titleCurrent">
                        {initData?.current_num}
                      </span>
                      /{initData?.total_num}
                    </span>
                  </div>
                  <div className="topBackground"></div>
                </div>
                <div className="topic lastTopic">
                  <div className="subjectTitle">{initData?.title}</div>
                  <div className="subjectBriefAnswer">
                    <div className="briefPicture">
                      <div
                        className="briefContent"
                        dangerouslySetInnerHTML={{ __html: initData?.content }}
                      ></div>
                    </div>
                    <TextArea
                      placeholder="请输入内容"
                      value={value}
                      showCount
                      maxLength={250}
                      autoSize={{ minRows: 10, maxRows: 12 }}
                      onChange={(val) => {
                        setValue(val);
                        getAnswer(val);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="orderTrainBottom flexBoxBetween">
            <span className="collection">
              {is_collect === 2 ? (
                <>
                  <StarFill onClick={collect(1)} style={{ color: "#2C68FF" }} />
                  <span
                    className="collectionTitle"
                    style={{ color: "#2C68FF" }}
                  >
                    已收藏
                  </span>
                </>
              ) : (
                <>
                  <StarOutline onClick={collect(2)} />
                  <span className="collectionTitle">收藏</span>
                </>
              )}
            </span>
            <span className="subjectTrue">
              <CheckCircleFill />
              <span className="subjectTrueTitle">{initData?.ok_num}</span>
            </span>
            <span className="subjectFalse">
              <CloseCircleFill />
              <span className="subjectFalseTitle">{initData?.error_num}</span>
            </span>
            <span
              className="submit"
              onClick={() => submit({ exercise_id: exerciseId })}
            >
              确定
            </span>
          </div>
        </>
      ) : (
        <>
          <ProblemAnswer
            SubjectType={subjectType}
            AnswerOptions={AnswerOptions}
            answerData={answerData}
          />
          <div className="orderTrainBottom flexBoxBetween">
            <span className="collection">
              {is_collect === 2 ? (
                <>
                  <StarFill onClick={collect(1)} style={{ color: "#2C68FF" }} />
                  <span
                    className="collectionTitle"
                    style={{ color: "#2C68FF" }}
                  >
                    已收藏
                  </span>
                </>
              ) : (
                <>
                  <StarOutline onClick={collect(2)} />
                  <span className="collectionTitle">收藏</span>
                </>
              )}
            </span>
            <span className="subjectTrue">
              <CheckCircleFill />
              <span className="subjectTrueTitle">{answerData?.ok_num}</span>
            </span>
            <span className="subjectFalse">
              <CloseCircleFill />
              <span className="subjectFalseTitle">{answerData?.error_num}</span>
            </span>
            <span className="submit" onClick={nextSubject}>
              下一题
            </span>
          </div>
        </>
      )}
    </div>
  );
}

export default OrderTest;
