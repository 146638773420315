import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, Toast, Grid } from "antd-mobile";
import { data } from "../../../utils/allList.js";
import Order from "../../../image/myTrainImg/order.png";
import SimulationTest from "../../../image/myTrainImg/simulationTest.png";
import { myTrainApi } from "../../../services/index";
import "./index.less";

function MyTrain() {
  const [dataRes, setDataRes] = useState();
  useEffect(() => {
    fn();
  }, []);

  const fn = async () => {
    let res = await myTrainApi();
    setDataRes(res?.data);
  };
  const navigate = useNavigate();
  const items = dataRes?.carousel?.map((i, index) => (
    <Swiper.Item allowTouchMove className="wrapBox" key={index}>
      <img
        className="content"
        style={{ width: "100%", height: "100%" }}
        src={i.image}
        alt=""
      />
    </Swiper.Item>
  ));
  return (
    <div className="myTrain">
      <div className="myTrainTop">
        <div className="course">
          <span className="courseTitle">课程</span>
          <span className="courseGrade">{dataRes?.grade_name}</span>
        </div>
      </div>
      <div className="swiper">
        <Swiper autoplay loop>
          {items}
        </Swiper>
      </div>
      <div className="subject flexBoxBetween">
        <div className="subjectItem">
          <span className="subjectTitle">未做题</span>
          <span className="subjectContent">{dataRes?.not_done}</span>
        </div>
        <div className="subjectItem">
          <span className="subjectTitle">错题</span>
          <span className="subjectContent">{dataRes?.error_num}</span>
        </div>
        <div className="subjectItem subjectLast">
          <span className="subjectTitle">答错率</span>
          <span className="subjectContent">{dataRes?.error_rate}%</span>
        </div>
      </div>
      <div className="subjectList">
        <Grid columns={2} gap={20}>
          <Grid.Item
            onClick={() => {
              navigate("/ordertest");
            }}
          >
            <div className="subjectListItem">
              <div className="listItemLf">
                <div className="itemLfTitle">顺序练习</div>
                <div className="itemLfNum">
                  {dataRes?.done_num}/{dataRes?.total_num}
                </div>
              </div>
              <div className="listItemRg">
                <img src={Order} alt="" />
              </div>
            </div>
          </Grid.Item>
          <Grid.Item
            onClick={() => {
              navigate("/mockexamination");
            }}
          >
            <div className="subjectListItem">
              <div className="listItemLf">
                <div className="itemLfTitle">模拟考试</div>
                <div className="itemFraction">{dataRes?.total_score}分</div>
              </div>
              <div className="listItemRg">
                <img src={SimulationTest} alt="" />
              </div>
            </div>
          </Grid.Item>
        </Grid>
        <Grid columns={2} style={{ "--gap-horizontal": ".2rem" }}>
          {data.subjectList.map((i) => (
            <Grid.Item
              key={i.id}
              onClick={() => {
                navigate(i.itemJumpRouter);
              }}
            >
              <div className="subjectListItem">
                <div className="listItemLf">
                  <div className="itemLfTitle">{i.title}</div>
                  <div className="itemLfdesc">{i.describe}</div>
                </div>
                <div className="listItemRg">
                  <img src={i.avatar} alt="" />
                </div>
              </div>
            </Grid.Item>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default MyTrain;
