import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Image, List } from "antd-mobile";
import { applyStatus } from "../../../services/index";
import arrow from "../../../image/assistantImg/arrow.png";
import applyMoveInto from "../../../image/employImg/applyMoveInto.png";
import "./index.less";

function Employ() {
  const navigate = useNavigate();
  const [sourse, setSourse] = useState({});
  useEffect(() => {
    fn();
  }, []);
  const fn = async () => {
    let res = await applyStatus();
    setSourse(res.data);
  };

  return (
    <div className="employ">
      <div className="styleRec"></div>
      <List
        mode="card"
        style={{
          "--border-inner": "none",
          "--border-top": "none",
          "--border-bottom": "none",
          "--align-items": "center",
        }}
      >
        <List.Item
          style={{
            // marginTop: ".2rem",
            position: "relative",
            width: "100%",
            height: "1.3rem",
            fontSize: ".34rem",
          }}
          prefix={
            <Image
              src={applyMoveInto}
              style={{
                width: ".66rem",
                height: ".66rem",
              }}
              fit="cover"
            />
          }
          arrow={<Image src={arrow} fit="cover" />}
          onClick={() => {
            if (sourse?.yanglao_status === 1) {
              Dialog.alert({
                content: "审核中，请你耐心等待",
              });
            } else if (sourse?.yanglao_status === 2) {
              navigate("/applysuccess", {
                state: {
                  type: 2,
                },
              });
            } else {
              navigate("/employapply");
            }
          }}
        >
          申请入驻
        </List.Item>
      </List>
    </div>
  );
}

export default Employ;
