import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "antd-mobile";
import moment from "moment";
import InfoTableHeader from "../../../components/InfoTableHeader";
import InfoTableItem from "../../../components/InfoTableItem";
import { infoTableItemData } from "../../../utils/allInfoTableItem";
import { myAwardBaseInfo, talentApi } from "../../../services/index";
import "./index.less";

function TalentAward() {
  const navigate = useNavigate();
  const [res, setRes] = useState({});
  const [education, setEducation] = useState("");
  const [entryPosition, setEntryPosition] = useState("");
  const [workTime, setWorkTime] = useState("");
  const [windUpCertificate, setWindUpCertificate] = useState("");
  const [entryTimeValue, setEntryTimeValue] = useState("");
  const [diploma, setEdu] = useState("");
  const [contract_img, setCont] = useState("");

  useEffect(() => {
    fn();
  }, []);
  const getEducation = (v) => {
    setEducation(v[0]);
  };
  const getWindUpCertificate = (v) => {
    setWindUpCertificate(v[0]);
  };
  const getentryPosition = (v) => {
    setEntryPosition(v[0]);
  };
  const getWorkTime = (v) => {
    setWorkTime(v[0]);
  };
  const getTimer1 = (v) => {
    setEntryTimeValue(v);
  };
  const getPicture = (v) => {
    if (Object.keys(v).includes("diploma")) {
      setEdu(v.diploma);
    } else if (Object.keys(v).includes("contract_img")) {
      setCont(v.contract_img);
    }
  };
  let entryTimeVal = moment(entryTimeValue).format("YYYY-MM-DD");
  const fn = async () => {
    let res = await myAwardBaseInfo();
    setRes(res?.data);
  };
  const educationColumns =
    res?.data_education?.map((item) => {
      item.label = item.name;
      item.value = item.id;
      return item;
    }) || [];
  const entryPositionColumns =
    res?.data_entry?.map((item) => {
      item.label = item.name;
      item.value = item.id;
      return item;
    }) || [];
  const workTimeColumns =
    res?.data_job_years?.map((item) => {
      item.label = item.name;
      item.value = item.id;
      return item;
    }) || [];
  const windUpCertificateColumns =
    res?.data_contract_years?.map((item) => {
      item.label = item.name;
      item.value = item.id;
      return item;
    }) || [];
  const addData = {
    education_id: education,
    entry_time: entryTimeVal, //入职时间
    entry_id: entryPosition, //入职岗位
    contract_years_id: windUpCertificate, //合同签订年限
    job_years_id: workTime, //工作年限
  };
  const onFinishTalent = async (values) => {
    if (addData?.entry_time === "Invalid date") {
      Toast.show(`请选择入职时间`, 2);
    } else {
      let res = await talentApi({
        ...values,
        ...addData,
        diploma,
        contract_img,
      });
      if (res.code === 404) {
        navigate("/applytips", {
          state: {
            type: res.data.type,
          },
        });
      }
    }
  };

  return (
    <div className="talentAward">
      <InfoTableHeader />
      <InfoTableItem
        precision1="day"
        formatData1="YYYY-MM-DD"
        initData={{ ...res }}
        getTimer1={getTimer1} //入职时间
        FormItemData={infoTableItemData?.talentAwardForm}
        getEducation={getEducation}
        educationColumns={[educationColumns]} //学历
        entryPositionColumns={[entryPositionColumns]}
        getentryPosition={getentryPosition}
        getSalaryRequire={getWorkTime} //工作年限
        salaryRequireColumns={[workTimeColumns]} //工作年限
        getRentalType={getWindUpCertificate} //获取合同签订年限
        rentalTypeColumns={[windUpCertificateColumns]} //合同签订年限
        onFinish={onFinishTalent}
        getPicture={getPicture}
      />
    </div>
  );
}

export default TalentAward;
