import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Dialog } from "antd-mobile";
import Background from "../../../image/orderTrain/background.png";
import BackgroundAnswer from "../../../image/orderTrain/backgroundAnswer.png";
import ProblemAnswer from "../../../components/ProblemAnswer";
import Problem from "../../../components/Problem";
import {
  collectSubmit, //收藏页面提交
  clickCollect, //点击收藏
  collectAnalysis, //收藏题目答案分析
  collectSubject, //获取顺序练习题库
} from "../../../services/index";

import {
  StarFill,
  StarOutline,
  CheckCircleFill,
  CloseCircleFill,
} from "antd-mobile-icons";
import "./index.less";

function OrderTrain() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [is_collect, setIsCollect] = useState(1);
  const [isShowAnswer, SetisShowAnswer] = useState(false);
  const [subjectType, setSubjectType] = useState(1); //caseAnalysis briefAnswer
  const [initData, setInitData] = useState();
  const [answer, setAnswer] = useState();
  const [answerData, setAnswerData] = useState();
  const [options, setOptions] = useState([]);
  const [AnswerOptions, setAnswerOptions] = useState([]);
  const [code, setCode] = useState();
  const type = state?.type;
  useEffect(() => {
    fn({ type: type });
  }, []);
  const fn = async (params) => {
    let res = await collectSubject(params);
    setIsCollect(res?.data?.is_collect);
    setInitData(res?.data);
    const stype = res?.data?.type;
    setSubjectType(stype);
    if (stype === 1 || stype === 2 || stype === 4) {
      const options =
        res?.data?.answer?.map((item) => {
          item.label = item.name;
          item.value = item.id;
          return item;
        }) || [];
      setOptions(options);
    }
  };
  const fnAnswer = async () => {
    let response = await collectAnalysis({
      collect_my_id: initData?.id,
      type: type,
    });
    setAnswerData(response.data);
    const rtype = response?.data?.type;
    setSubjectType(rtype);
    setCode(response?.code);
    if (rtype === 1 || rtype === 2 || rtype === 4) {
      const AnswerOptions =
        response?.data?.answer?.map((item) => {
          item.label = item.name;
          item.value = item.id;
          return item;
        }) || [];
      setAnswerOptions(AnswerOptions);
    }
  };
  const getAnswer = (v) => {
    setAnswer(v);
  };
  const collect = (params) => {
    return async () => {
      await clickCollect({ collect_my_id: initData?.id, type: type });
      setIsCollect(params);
    };
  };
  const addData = {
    collect_my_id: initData?.id,
    answer_id: answer,
  };
  const submit = async () => {
    if (code === 404) {
      Dialog.confirm({
        bodyClassName: "collectDetailsBody",
        content: (
          <>
            <div className="subjectTips">已无最新练习题</div>
            <div className="subjectTips">点击确认返回收藏列表</div>
          </>
        ),
        showCloseButton: true,
        onConfirm: () => {
          navigate("/mytrain");
        },
      });
    } else {
      let res = await collectSubmit(addData);
      SetisShowAnswer(true);
      fnAnswer();
    }
  };
  const nextSubject = () => {
    if (answerData.current_num === answerData.total_num || code === 404) {
      Dialog.alert({
        bodyClassName: "collectDetailsBody",
        content: (
          <>
            <div className="subjectTips">已无最新练习题</div>
            <div className="subjectTips">点击确认返回收藏列表</div>
          </>
        ),
        onConfirm: () => {
          navigate("/collection");
        },
      });
    } else {
      SetisShowAnswer(false);
      fn({ type: type });
    }
  };
  return (
    <div className="collectDetails">
      {isShowAnswer === false ? (
        <img className="background" src={Background} alt="" />
      ) : (
        <img className="background" src={BackgroundAnswer} alt="" />
      )}
      {isShowAnswer === false ? (
        <>
          <Problem
            SubjectType={subjectType}
            initData={initData}
            Options={options}
            getAnswer={getAnswer}
          />
          <div className="orderTrainBottom flexBoxBetween">
            <span className="collection">
              {is_collect === 2 ? (
                <>
                  <StarFill onClick={collect(1)} style={{ color: "#2C68FF" }} />
                  <span
                    className="collectionTitle"
                    style={{ color: "#2C68FF" }}
                  >
                    已收藏
                  </span>
                </>
              ) : (
                <>
                  <StarOutline onClick={collect(2)} />
                  <span className="collectionTitle">收藏</span>
                </>
              )}
            </span>
            <span className="subjectTrue">
              <CheckCircleFill />
              <span className="subjectTrueTitle">{initData?.ok_num}</span>
            </span>
            <span className="subjectFalse">
              <CloseCircleFill />
              <span className="subjectFalseTitle">{initData?.error_num}</span>
            </span>
            <span className="submit" onClick={submit}>
              确定
            </span>
          </div>
        </>
      ) : (
        <>
          <ProblemAnswer
            SubjectType={subjectType}
            AnswerOptions={AnswerOptions}
            // Data={data}
            answerData={answerData}
          />
          <div className="orderTrainBottom flexBoxBetween">
            <span className="collection">
              {is_collect === 2 ? (
                <>
                  <StarFill onClick={collect(1)} style={{ color: "#2C68FF" }} />
                  <span
                    className="collectionTitle"
                    style={{ color: "#2C68FF" }}
                  >
                    已收藏
                  </span>
                </>
              ) : (
                <>
                  <StarOutline onClick={collect(2)} />
                  <span className="collectionTitle">收藏</span>
                </>
              )}
            </span>
            <span className="subjectTrue">
              <CheckCircleFill />
              <span className="subjectTrueTitle">{answerData?.ok_num}</span>
            </span>
            <span className="subjectFalse">
              <CloseCircleFill />
              <span className="subjectFalseTitle">{answerData?.error_num}</span>
            </span>
            <span className="submit" onClick={nextSubject}>
              下一题
            </span>
          </div>
        </>
      )}
    </div>
  );
}

export default OrderTrain;
