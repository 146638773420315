import React, { useState, useEffect } from "react";
import Open from "../../../image/myResumeImg/open.png";
import Close from "../../../image/myResumeImg/close.png";
import MyResumeItem from "../../../components/MyResumeItem";
import { infoTableItemData } from "../../../utils/allInfoTableItem";
import { allPickerData } from "../../../utils/allPicker";
import { resume } from "../../../services/index.js";
import { publishApi } from "../../../services/index.js";
import "./index.less";

function MyResume() {
  const [sourse, setSourse] = useState();
  const [isPublish, setIsPublish] = useState();
  useEffect(() => {
    fn();
  }, []);
  const fn = async () => {
    let res = await resume();
    setSourse(res?.data);
    setIsPublish(res?.data?.is_publish);
  };

  const publish = (param) => {
    return async () => {
      await publishApi({ is_publish: param });
      setIsPublish(param);
    };
  };
  const onFinishStation = (values) => {};
  return (
    <div className="myResume">
      <div className="myResumeHead">
        <div className="flexBoxBetween resumePublish">
          <span>简历发布</span>
          {isPublish === 2 ? (
            <img
              style={{
                display: "inline-block",
                width: ".7rem",
                height: ".42rem",
              }}
              onClick={publish(1)}
              src={Open}
              alt=""
            ></img>
          ) : (
            <img
              style={{
                display: "inline-block",
                width: ".7rem",
                height: ".42rem",
              }}
              onClick={publish(2)}
              src={Close}
              alt=""
            ></img>
          )}
        </div>
      </div>
      <div className="flexBoxBetween headImg">
        <div>
          <div className="headTitle">基本信息</div>
          <div className="headTip">相关内容请去‘我的信息’中完善</div>
        </div>
        <div className="imgUploadSign">
          <img
            src={sourse?.avatar || sourse?.headimgurl}
            className="UploadSign"
            alt=""
          />
        </div>
      </div>
      <MyResumeItem
        initData={{ ...sourse }}
        FormItemData={infoTableItemData?.myResumeForm}
        basicColumns={allPickerData.windUpCertificateColumns}
        onFinish={onFinishStation}
      />
    </div>
  );
}

export default MyResume;
