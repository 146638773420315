import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Image, List, Dialog } from "antd-mobile";
import { myInfoStatus } from "../../../services/index";
import myInfo from "../../../image/assistantImg/myInfo.png";
import toTrain from "../../../image/assistantImg/toTrain.png";
import myTrain from "../../../image/assistantImg/myTrain.png";
import goWork from "../../../image/assistantImg/goWork.png";
import award from "../../../image/assistantImg/award.png";
import arrow from "../../../image/assistantImg/arrow.png";
import "./index.less";

function Assistant() {
  const navigate = useNavigate();
  const [vip_info_status, setvVipInfoStatus] = useState();
  const [cultivate_apply_status, setCultivateApplyStatus] = useState();
  useEffect(() => {
    fn();
  }, []);

  const fn = async () => {
    let resp = await myInfoStatus();
    setvVipInfoStatus(resp.data.vip_info_status);
    setCultivateApplyStatus(resp.data.cultivate_apply_status);
  };
  return (
    <div className="assistant">
      <List
        mode="card"
        style={{
          "--border-inner": "none",
          "--border-top": "none",
          "--border-bottom": "none",
          "--align-items": "center",
        }}
      >
        <List.Item
          style={{
            background: "#F5F7FB",
          }}
          prefix={
            <Image
              src={myInfo}
              style={{
                width: ".71rem",
                height: ".63rem",
              }}
              fit="cover"
            />
          }
          arrow={<Image src={arrow} fit="cover" />}
          onClick={() => {
            if (vip_info_status === 1) {
              Dialog.alert({
                content: "你的信息正在审核中，请稍后",
              });
            } else if (vip_info_status === 2) {
              navigate("/myinfodetails");
            } else {
              navigate("/myinfo");
            }
          }}
        >
          <div style={{ marginLeft: ".22rem" }}>我的信息</div>
        </List.Item>
        <div
          style={{
            height: ".2rem",
            background: "#FFFFFF",
          }}
        ></div>

        {vip_info_status === 2 && cultivate_apply_status === 2 ? (
          <>
            <List.Item
              style={{
                background: "#F5F7FB",
              }}
              prefix={
                <Image
                  src={myTrain}
                  style={{
                    width: ".67rem",
                    height: ".71rem",
                  }}
                  fit="cover"
                />
              }
              arrow={<Image src={arrow} fit="cover" />}
              onClick={() => {
                navigate("/mytrain");
              }}
            >
              <div style={{ marginLeft: ".26rem" }}>我的培训</div>
            </List.Item>
            <div
              style={{
                height: ".2rem",
                background: "#FFFFFF",
              }}
            ></div>
          </>
        ) : (
          <>
            <List.Item
              style={
                vip_info_status === 2
                  ? { background: "#F5F7FB" }
                  : { background: "#F5F7FB", opacity: 0.5 }
              }
              prefix={
                <Image
                  src={toTrain}
                  style={{
                    width: ".71rem",
                    height: ".63rem",
                  }}
                  fit="cover"
                />
              }
              arrow={<Image src={arrow} fit="cover" />}
              onClick={() => {
                if (vip_info_status === 2) {
                  if (cultivate_apply_status === 1) {
                    Dialog.alert({
                      content: "你的培训申请正在审核中，请稍后",
                    });
                  } else if (
                    cultivate_apply_status === 0 ||
                    cultivate_apply_status === 3
                  ) {
                    navigate("/applytrain");
                  }
                } else {
                  Dialog.alert({
                    content: "请完善你的信息，审核通过后再来",
                  });
                }
              }}
            >
              <div style={{ marginLeft: ".22rem" }}>我要培训</div>
            </List.Item>
            <div
              style={{
                height: ".2rem",
                background: "#FFFFFF",
              }}
            ></div>
          </>
        )}
        <List.Item
          style={
            vip_info_status === 2
              ? { background: "#F5F7FB" }
              : { background: "#F5F7FB", opacity: 0.5 }
          }
          prefix={
            <Image
              src={goWork}
              style={{
                width: ".72rem",
                height: ".64rem",
              }}
              fit="cover"
            />
          }
          arrow={<Image src={arrow} fit="cover" />}
          onClick={() => {
            if (vip_info_status === 2) {
              navigate("/recruitmarket");
            } else {
              Dialog.alert({
                content: "请完善你的信息，审核通过后再来",
              });
            }
          }}
        >
          <div style={{ marginLeft: ".21rem" }}>我要就业</div>
        </List.Item>
        <div
          style={{
            height: ".2rem",
            background: "#FFFFFF",
          }}
        ></div>
        <List.Item
          style={
            vip_info_status === 2
              ? { background: "#F5F7FB" }
              : { background: "#F5F7FB", opacity: 0.5 }
          }
          prefix={
            <Image
              src={award}
              style={{
                width: ".62rem",
                height: ".67rem",
              }}
              fit="cover"
            />
          }
          arrow={<Image src={arrow} fit="cover" />}
          onClick={() => {
            if (vip_info_status === 2) {
              navigate("/myaward");
            } else {
              Dialog.alert({
                content: "请完善你的信息，审核通过后再来",
              });
            }
          }}
        >
          <div style={{ marginLeft: ".31rem" }}>我的奖补</div>
        </List.Item>
        <div
          style={{
            height: ".2rem",
            background: "#FFFFFF",
          }}
        ></div>
      </List>
    </div>
  );
}

export default Assistant;
