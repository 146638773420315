export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

// export async function mockUpload(file) {
//   await sleep(3000);
//   return {
//     url: URL.createObjectURL(file),
//   };
// }

// 获取当前时间的N年前或者N年后
export function GetYear(year) {
  var time = new Date();
  time?.setFullYear(time?.getFullYear() + year);
  var y = time;
  // var y = time?.getFullYear();
  // var m = time?.getMonth() + 1;
  // var d = time?.getDate();
  // return y + "-" + m + "-" + d;
  return y;
}
