import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Image, List, Dialog } from "antd-mobile";
import { data } from "../../../utils/allList.js";
import { statusPolicy } from "../../../services/index";
import arrow from "../../../image/assistantImg/arrow.png";
import entryAward from "../../../image/myawardImg/entryAward.png";
import stationAward from "../../../image/myawardImg/stationAward.png";
import talentAward from "../../../image/myawardImg/talentAward.png";
import rentPurchaseAward from "../../../image/myawardImg/rentPurchaseAward.png";
import competitionAward from "../../../image/myawardImg/competitionAward.png";
import rankAward from "../../../image/myawardImg/rankAward.png";
import trainAward from "../../../image/myawardImg/trainAward.png";
import awardPolicyEntryIcon from "../../../image/myawardImg/awardPolicyEntryIcon.png";
import "./index.less";

function MyAward() {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [sourse, setSourse] = useState();
  useEffect(() => {
    fn();
  }, []);
  const fn = async () => {
    let res = await statusPolicy();
    setSourse(res?.data?.prize_type);
  };
  const awardPolicy = () => {
    navigate("/awardpolicy");
  };

  const handleClick = (param) => {
    const arrdata = sourse?.find((i) => i.id === param.id);
    if (arrdata?.status === 1) {
      setVisible(true);
    } else if (arrdata?.status === 2 || arrdata?.status === 3) {
      navigate("/applytips", {
        state: {
          type: arrdata?.type,
        },
      });
    } else {
      navigate(param.url);
    }
  };

  return (
    <div className="myAward">
      <div className="awardPolicyEntry">
        <img
          className="awardPolicyEntryIcon"
          src={awardPolicyEntryIcon}
          alt="awardPolicyEntryIcon"
        ></img>
        <span className="awardPolicyEntryWords" onClick={awardPolicy}>
          奖补政策了解
        </span>
      </div>
      <List
        mode="card"
        style={{
          "--border-inner": "none",
          "--border-top": "none",
          "--border-bottom": "none",
          "--align-items": "center",
        }}
      >
        <List.Item
          className="listItem"
          prefix={<Image src={entryAward} className="picture" fit="cover" />}
          arrow={<Image src={arrow} fit="cover" />}
          onClick={() => handleClick({ id: 1, url: "/entryaward" })}
        >
          入职奖补申请
        </List.Item>
        <div className="styleDiv"></div>
        <List.Item
          className="listItem"
          prefix={<Image className="picture" src={stationAward} fit="cover" />}
          arrow={<Image src={arrow} fit="cover" />}
          onClick={() => handleClick({ id: 2, url: "/stationaward" })}
        >
          岗位奖补申请
        </List.Item>
        <div className="styleDiv"></div>
        <List.Item
          className="listItem"
          prefix={<Image className="picture" src={talentAward} fit="cover" />}
          arrow={<Image src={arrow} fit="cover" />}
          onClick={() => handleClick({ id: 3, url: "/talentaward" })}
        >
          人才奖补申请
        </List.Item>
        <div className="styleDiv"></div>
        <List.Item
          className="listItem"
          prefix={
            <Image className="picture" src={rentPurchaseAward} fit="cover" />
          }
          arrow={<Image src={arrow} fit="cover" />}
          onClick={() => handleClick({ id: 4, url: "/rentpurchaseaward" })}
        >
          租购房奖补申请
        </List.Item>
        <div className="styleDiv"></div>
        <List.Item
          className="listItem"
          prefix={
            <Image className="picture" src={competitionAward} fit="cover" />
          }
          arrow={<Image src={arrow} fit="cover" />}
          onClick={() => handleClick({ id: 5, url: "/competitionaward" })}
        >
          竞赛奖励申请
        </List.Item>
        <div className="styleDiv"></div>
        <List.Item
          className="listItem"
          prefix={<Image className="picture" src={rankAward} fit="cover" />}
          arrow={<Image src={arrow} fit="cover" />}
          onClick={() => handleClick({ id: 6, url: "/rankaward" })}
        >
          等级鉴定奖励申请
        </List.Item>
        <div className="styleDiv"></div>
        <List.Item
          className="listItem"
          prefix={<Image className="picture" src={trainAward} fit="cover" />}
          arrow={<Image src={arrow} fit="cover" />}
          onClick={() => handleClick({ id: 7, url: "/trainaward" })}
        >
          培训鉴定奖励申请
        </List.Item>
        <div className="styleDiv"></div>
      </List>
      <Dialog
        visible={visible}
        bodyClassName="publicListBody"
        content="审核中，请你耐心等待"
        confirmText="确认"
        closeOnAction
        onClose={() => {
          setVisible(false);
        }}
        actions={[
          {
            key: "confirm",
            text: "我知道了",
          },
        ]}
      />
    </div>
  );
}

export default MyAward;
