import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Address from "../../../image/workDetailImg/address.png";
import Certificate from "../../../image/workDetailImg/certificate.png";
import Eduction from "../../../image/workDetailImg/eduction.png";
import Experience from "../../../image/workDetailImg/experience.png";
import { Button } from "antd-mobile";
import { jobDetails } from "../../../services/index.js";
import "./index.less";

function WorkDetail() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [sourse, setSourse] = useState();
  const id = state?.id;
  useEffect(() => {
    fn();
  }, []);
  const fn = async () => {
    let res = await jobDetails(id);
    setSourse(res?.data);
  };
  return (
    <div className="workDetail">
      <div className="moduleOne moduleBox">
        <div className="oneRow1 flexBoxBetween">
          <span className="position">{sourse?.title}</span>
          <span className="salary">
            {sourse?.wages_low}~{sourse?.wages_heigh}
          </span>
        </div>
        <div className="oneRow2 flexBoxBetween">
          <span>
            <span className="icon">
              <img src={Address} alt="" />
            </span>
            <span className="iconText jobAddress">{sourse?.job_address}</span>
          </span>
          <span>
            <span className="icon">
              <img src={Experience} alt="" />
            </span>
            <span className="iconText">{sourse?.empiric_name}</span>
          </span>
          <span>
            <span className="iconEdu">
              <img src={Eduction} alt="" />
            </span>
            <span className="iconText">{sourse?.min_education_name}</span>
          </span>
          <span>
            <span className="icon">
              <img src={Certificate} alt="" />
            </span>
            <span className="iconText">{sourse?.cert_name}</span>
          </span>
        </div>
      </div>
      <div className="moduleTwo moduleBox">
        <div className="detailsTitle">薪资详情</div>
        <div className="detailsText">{sourse?.wages_text}</div>
      </div>
      <div className="moduleTwo moduleBox">
        <div className="detailsTitle">岗位详情</div>
        <div className="detailsText">{sourse?.job_text}</div>
      </div>
      {/* <div className="moduleTwo moduleBox">
        <div className="detailsTitle">候选人加分项</div>
        <div className="detailsText">1. 薪资范围5000-9000/月</div>
      </div> */}
      {sourse?.source === 1 ? (
        <div className="moduleThree moduleBox">
          <span className="avator">
            <img
              className="avator"
              src={sourse?.nursing_facility?.img_path}
              alt=""
            />
          </span>
          <span className="addressDetails">
            <div className="addressDetails1">
              {sourse?.nursing_facility?.name}
            </div>
            <div className="addressDetails2">{sourse?.hire_num}</div>
            <div className="addressDetails3">{sourse?.connect_address}</div>
          </span>
        </div>
      ) : (
        <div className="moduleThree moduleBox">
          <span className="avator">
            <img className="avator" src={sourse?.setting_img[0]} alt="" />
          </span>
          <span className="addressDetails">
            <div className="addressDetails1">{sourse?.full_name}</div>
            <div className="addressDetails3">{sourse?.connect_address}</div>
          </span>
        </div>
      )}
      <Button
        block
        className="chatButton"
        onClick={() => {
          navigate("/chat", {
            state: {
              post_id: sourse?.id,
              source: sourse?.source,
            },
          });
        }}
        type="submit"
        color="primary"
      >
        立即沟通
      </Button>
    </div>
  );
}

export default WorkDetail;
