/*
 * axios全局配置
 */
import axios from "axios";
import { Toast } from "antd-mobile";
import store from "../redux/store";
import { deleteUserInfo } from "../redux/actions/login";
import qs from "qs";

const instance = axios.create({
  // baseURL: "http://47.98.160.12:8081", //如果有跨域代理就不用这个，注销
  timeout: 4000,
});

//配置请求拦截器
instance.interceptors.request.use(
  (config) => {
    //从redux中获取之前保存的token
    let token = store.getState().user.token;
    if (token) {
      //如果token存在，就设置到请求头中
      config.headers.Authorization = `Bearer ${token}`;
    }
    // 添加/proxy前缀 用于跨域代理转发
    // config.url = "/assistant" + config.url;
    config.url = "/proxy" + config.url;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//配置响应拦截器
instance.interceptors.response.use((response) => {
  if (response.data.code === 1 || response.data.code === 404) {
    return response.data;
  } else if (response.data.code === 40003) {
    Toast.show(`${response.data.message}，请重新登录`, 2);
    //分发删除用户信息的action
    store.dispatch(deleteUserInfo());
  } else {
    //提示消息
    Toast.show(`${response.data.message}`, 2);
  }
  //中断Promise链
  return new Promise(() => {});
});

/**
 * 发起get请求
 * @param {*} url
 * @param {*} params
 * @returns
 */
export const getRequest = (url = "", data = {}, timeout = 5000) => {
  if (qs.stringify(data)) {
    url += url.includes("?")
      ? "&" + qs.stringify(data)
      : "?" + qs.stringify(data);
  }
  return instance.get(url, { timeout });
};

/**
 * 发起post请求
 * @param {*} url
 * @param {*} data
 * @param {*} timeout
 * @returns
 */
export const postRequest = (url = "", data = {}, timeout = 5000) => {
  return instance.post(url, data, { timeout });
};
