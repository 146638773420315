import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Button } from "antd-mobile";
import Background from "../../../image/orderTrain/background.png";
import Time from "../../../image/mockExamination/time.png";
import { mockTest } from "../../../services/index";
import "./index.less";

function MockExamination() {
  const navigate = useNavigate();
  const [res, setRes] = useState({});
  useEffect(() => {
    fn();
  }, []);
  const fn = async () => {
    let res = await mockTest();
    setRes(res.data);
  };
  return (
    <div className="mockExamination">
      <img className="background" src={Background} alt="" />
      <div className="topicTop">
        <div className="title">模拟考试</div>
        <div
          className="examinationTips"
          dangerouslySetInnerHTML={{ __html: res?.content }}
        ></div>
        <div style={{ padding: "0 .5rem" }}>
          <Button
            block
            className="chatButton"
            onClick={() => {
              navigate("/examination", {
                state: {
                  id: res?.id,
                },
              });
            }}
            type="submit"
            color="primary"
          >
            开始考试
          </Button>
        </div>
      </div>
      <div className="topicBottom">
        <div className="historyTitle">
          <span>
            <img className="time" src={Time} alt="" />
          </span>
          <span>历史成绩</span>
        </div>
        {res?.simulate_my?.map((i) => (
          <div
            className="historyContent"
            onClick={() => {
              navigate("/testdetails", {
                state: {
                  id: i.id,
                },
              });
            }}
            key={i.id}
          >
            <span className="historyScore">{i?.total_score}分</span>
            <span className="historyTime">
              {dayjs.unix(i.create_time).format("YYYY-MM-DD")}
            </span>
            <span className="historyDetail">查看解析&nbsp;&gt;</span>
            <span className="clearfloat"></span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MockExamination;
