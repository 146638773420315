import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PublicApplySuccess from "../../../components/PublicApplySuccess";
import { applySuccess } from "../../../services/index";

function ApplySuccess() {
  const { state } = useLocation();
  const [data, setData] = useState({});
  useEffect(() => {
    fn();
  }, []);
  const fn = async () => {
    let res = await applySuccess(state);
    setData(res.data);
  };
  return (
    <div className="ApplySuccess">
      <PublicApplySuccess data={data} sourse={data} />
    </div>
  );
}

export default ApplySuccess;
