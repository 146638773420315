export const searchTitle = {
  searchPost: {
    title: "搜索职位/公司",
  },
  searchCourse: {
    title: "请输入课程名称/tag查询",
  },
  searchCredential: {
    title: "请输入证书名称查询",
  },
};
