import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Selector, TextArea } from "antd-mobile";
import Background from "../../../image/orderTrain/background.png";
import { useLocation } from "react-router-dom";
import { StarFill, StarOutline } from "antd-mobile-icons";
import {
  mockQuestions,
  doneSubmit,
  testCollect,
  subTest,
  toUpward,
} from "../../../services/index";
import "./index.less";

function Examination() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [is_collect, setIsCollect] = useState(1);
  const [subjectType, setSubjectType] = useState(""); //caseAnalysis briefAnswer
  const [options, setOptions] = useState([]);
  const [data, setData] = useState({});
  const [answer, setAnswer] = useState();
  const [value, setValue] = useState();
  const [doId, setDoId] = useState();
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    fn();
  }, []);
  const fn = async () => {
    let res = await mockQuestions({ simulate_id: state?.id });
    setData(res?.data);
    const stype = res?.data?.type;
    setSubjectType(stype);
    setDoId(res?.data?.simulate_my_id);
    setIsCollect(res?.data.is_collect);
    if (stype === 1 || stype === 2 || stype === 4) {
      const options =
        res?.data?.topic_answer?.map((item) => {
          item.label = item?.prefix?.concat(" ", item?.name);
          item.value = item.id;
          return item;
        }) || [];
      setOptions(options);
    }
    setVisible(true);
  };
  const collect = (params) => {
    return async () => {
      await testCollect({ topic_id: data?.id });
      setIsCollect(params);
    };
  };
  const upQuestin = async () => {
    if (data?.done === 1) {
      Dialog.confirm({
        bodyClassName: "collectDetailsBody",
        content: (
          <>
            <div className="subjectTips">无上一题</div>
          </>
        ),
        showCloseButton: true,
      });
    } else {
      let res = await toUpward({ simulate_id: state?.id });
      const type = res?.data?.type;
      setData(res?.data);
      setSubjectType(type);
      if (type === 1 || type === 2 || type === 4) {
        const options =
          res?.data?.topic_answer?.map((item) => {
            item.label = item?.prefix?.concat(" ", item?.name);
            item.value = item.id;
            return item;
          }) || [];
        setOptions(options);
      }
    }
  };
  const getAnswer = (v) => {
    setAnswer(v);
  };
  const addData = {
    simulate_my_id: doId,
    simulate_id: state?.id,
    answer: answer,
    topic_id: data?.id,
  };
  const nextSubject = async () => {
    if (data.done === data.count) {
      let res = await doneSubmit(addData);
      if (res?.code === 1) {
        Dialog.confirm({
          bodyClassName: "collectDetailsBody",
          content: (
            <>
              <div className="subjectTips">提交试卷吗</div>
              <div className="subjectTips">点击确认提交并返回首页</div>
            </>
          ),
          showCloseButton: true,
          onConfirm: async () => {
            await subTest({ simulate_id: state?.id });
            navigate("/mockexamination");
          },
        });
      }
    } else {
      await doneSubmit(addData);
      setVisible(false);
      setAnswer();
      setValue();
      fn();
      setValue();
    }
  };
  return (
    <div className="examination">
      <img className="background" src={Background} alt="" />
      <div className="problemExam">
        {subjectType === 1 ? (
          <>
            <div className="topic">
              <div className="topicTitle">
                <span className="title">单选题</span>
                <span className="titleValue">
                  <span className="titleCurrent">{data?.done}</span>/
                  {data?.count}
                </span>
              </div>
              <div className="topBackground"></div>
              <div className="subjectTitle">{data?.title}</div>
              <div className="subjectOption">
                <Selector
                  columns={1}
                  style={{
                    "--border-radius": "100px",
                    "--border": "solid transparent 1px",
                    "--checked-border": "solid var(--adm-color-primary) 1px",
                    "--padding": "8px 24px",
                  }}
                  showCheckMark={false}
                  options={options}
                  onChange={(arr, extend) => {
                    getAnswer(arr[0]);
                  }}
                />
              </div>
            </div>
          </>
        ) : subjectType === 2 ? (
          <div className="topic">
            <div className="topicTitle">
              <span className="title">多选题</span>
              <span className="titleValue">
                <span className="titleCurrent">{data?.done}</span>/{data?.count}
              </span>
            </div>
            <div className="topBackground"></div>
            <div className="subjectTitle">{data?.title}</div>
            <div className="subjectOption">
              <Selector
                columns={1}
                showCheckMark={false}
                options={options}
                multiple={true}
                onChange={(arr, extend) => {
                  getAnswer(arr);
                }}
              />
            </div>
          </div>
        ) : subjectType === 4 ? (
          <div className="topic">
            <div className="topicTitle">
              <span className="title">判断题</span>
              <span className="titleValue">
                <span className="titleCurrent">{data?.done}</span>/{data?.count}
              </span>
            </div>
            <div className="topBackground"></div>
            <div className="subjectTitle">{data?.title}</div>
            <div className="subjectjudge">
              <Selector
                showCheckMark={false}
                columns={1}
                options={options}
                onChange={(arr, extend) => {
                  getAnswer(arr[0]);
                }}
              />
            </div>
          </div>
        ) : (
          //案例分析和简答题
          <>
            <div className="areaposition">
              <div className="topicTitlearea">
                {subjectType === 3 ? (
                  <span className="title">简答题</span>
                ) : (
                  <span className="title">案例分析题</span>
                )}
                <span className="titleValue">
                  <span className="titleCurrent">{data?.done}</span>/
                  {data?.count}
                </span>
              </div>
              <div className="topBackground"></div>
            </div>
            <div className="topic lastTopic">
              <div className="subjectTitle">{data?.title}</div>
              <div className="subjectBriefAnswer">
                <div className="briefPicture">
                  <div className="briefContent">{data?.content}</div>
                </div>
                {visible === true ? (
                  <TextArea
                    placeholder="请输入内容"
                    value={value}
                    showCount
                    maxLength={250}
                    autoSize={{ minRows: 10, maxRows: 12 }}
                    onChange={(val) => {
                      setValue(val);
                      getAnswer(val);
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="orderTrainBottom flexBoxBetween">
        <span className="collection">
          {is_collect === 2 ? (
            <>
              <StarFill onClick={collect(1)} style={{ color: "#2C68FF" }} />
              <span className="collectionTitle" style={{ color: "#2C68FF" }}>
                已收藏
              </span>
            </>
          ) : (
            <>
              <StarOutline onClick={collect(2)} />
              <span className="collectionTitle">收藏</span>
            </>
          )}
        </span>
        <span className="itemSubmit lastQuestion" onClick={upQuestin}>
          上一题
        </span>
        <span className="itemSubmit" onClick={nextSubject}>
          下一题
        </span>
      </div>
    </div>
  );
}

export default Examination;
