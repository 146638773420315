import React, { useState } from "react";
import { Form, Picker, DatePicker, Toast, Cascader } from "antd-mobile";
import dayjs from "dayjs";
import { GetYear } from "../../utils/method.js";
import "./index.less";

function PublicPicker(props) {
  const {
    basicColumns,
    trainLevelColumns,
    trainSchoolColumns,
    educationColumns,
    workCityOptions,
    expectPositionColumns,
    salaryRequireColumns,
    entryPositionColumns,
    rentalTypeColumns,
    prizeCertificateColumns,
    item,
  } = props;
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [trainSchoolShow, setTrainSchoolShow] = useState(false);
  const [educationShow, setEducationShow] = useState(false);
  const [workCityShow, setWorkCityShow] = useState(false);
  const [expectPositShow, setExpectPositShow] = useState(false);
  const [salaryRequireShow, setSalaryRequireShow] = useState(false);
  const [entryPositionShow, setEntryPositionShow] = useState(false);
  const [rentalTypeShow, setRentalTypeShow] = useState(false);
  const [prizeCertificateShow, setPrizeCertificateShow] = useState(false);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [pickerVisible1, setPickerVisible1] = useState(false);
  const [value, setValue] = useState();
  const min = GetYear(-50);
  return (
    <div className="publicPicker">
      <span className="baseProStar" style={{ color: "#F93A42" }}>
        {item?.isStarRequired}
      </span>
      <Form.Item
        label={item.itemLabel}
        rules={[{ required: item?.isRequired, message: item?.itemMessage }]}
        trigger={item.itemTrigger}
        onClick={() => {
          if (item?.pickerStatus === "isTimerPicker") {
            setPickerVisible(true);
          } else if (item?.pickerStatus === "isTimerPicker1") {
            setPickerVisible1(true);
          } else if (item?.pickerStatus === "trainLevel") {
            if (trainLevelColumns[0].length === 0) {
              Toast.show({
                content: "请先选择培训学校",
              });
            } else {
              setShow(true);
            }
          } else if (item?.pickerStatus === "trainSchool") {
            if (trainSchoolColumns[0].length === 0) {
              Toast.show({
                content: "暂无培训学校，请稍候申请",
              });
            } else {
              setTrainSchoolShow(true);
            }
          } else if (item?.pickerStatus === "education") {
            setEducationShow(true);
            // props
          } else if (item?.pickerStatus === "workCity") {
            setWorkCityShow(true);
          } else if (item?.pickerStatus === "expectPosition") {
            setExpectPositShow(true);
          } else if (item?.pickerStatus === "salaryRequire") {
            setSalaryRequireShow(true);
          } else if (item?.pickerStatus === "entryPosition") {
            setEntryPositionShow(true);
          } else if (item?.pickerStatus === "rentalType") {
            setRentalTypeShow(true);
          } else if (item?.pickerStatus === "prizeCertificate") {
            setPrizeCertificateShow(true);
          } else {
            setVisible(true);
          }
        }}
      >
        {/* {<span className="pickerValues">{item?.itemName}</span>} */}
        {item?.pickerStatus === "isTimerPicker" ? (
          <>
            <DatePicker
              min={min}
              precision={props.precision}
              visible={pickerVisible}
              onClose={() => {
                setPickerVisible(false);
              }}
              onConfirm={(v) => {
                setValue(v);
                props.getTimer(v);
              }}
            >
              {(value) =>
                value ? (
                  <span className="pickerValues">
                    {dayjs(value).format(props.formatData)}
                  </span>
                ) : (
                  <span className="pickerTips">{item?.itemPlaceHolder}</span>
                )
              }
            </DatePicker>
          </>
        ) : item?.pickerStatus === "isTimerPicker1" ? (
          <>
            <DatePicker
              min={min}
              precision={props.precision1}
              visible={pickerVisible1}
              onClose={() => {
                setPickerVisible1(false);
              }}
              onConfirm={(v) => {
                setValue(v);
                props.getTimer1(v);
              }}
            >
              {(value) =>
                value ? (
                  <span className="pickerValues">
                    {dayjs(value).format(props.formatData1)}
                  </span>
                ) : (
                  <span className="pickerTips">{item?.itemPlaceHolder}</span>
                )
              }
            </DatePicker>
          </>
        ) : item?.pickerStatus === "windUpCertificate" ? (
          <>
            <Picker
              columns={basicColumns}
              visible={visible}
              onClose={() => {
                setVisible(false);
              }}
              value={value}
              onConfirm={(v) => {
                setValue(v);
              }}
            >
              {(value) =>
                value.length > 0 ? (
                  <span className="pickerValues">
                    {value?.map((i) => i?.label)}
                  </span>
                ) : (
                  <span className="pickerTips">{item?.itemPlaceHolder}</span>
                )
              }
            </Picker>
          </>
        ) : item?.pickerStatus === "trainLevel" ? (
          <>
            <Picker
              title="选择培训等级"
              columns={trainLevelColumns}
              visible={show}
              onClose={() => {
                setShow(false);
              }}
              value={value}
              onConfirm={(v) => {
                setValue(v);
                props.getTrainLevel(v);
              }}
            >
              {(value) =>
                value.length > 0 ? (
                  <span className="pickerValues">
                    {value?.map((i) => i?.label)}
                  </span>
                ) : (
                  <span className="pickerTips">{item?.itemPlaceHolder}</span>
                )
              }
            </Picker>
          </>
        ) : item?.pickerStatus === "trainSchool" ? (
          <>
            <Picker
              title="选择培训学校"
              columns={trainSchoolColumns}
              visible={trainSchoolShow}
              onClose={() => {
                setTrainSchoolShow(false);
              }}
              value={value}
              onConfirm={(v) => {
                setValue(v);
                props.getTrainSchool(v);
              }}
            >
              {(value) =>
                value.length > 0 ? (
                  <span className="pickerValues">
                    {value?.map((i) => i?.label)}
                  </span>
                ) : (
                  <span className="pickerTips">{item?.itemPlaceHolder}</span>
                )
              }
            </Picker>
          </>
        ) : item?.pickerStatus === "education" ? (
          <>
            <Picker
              title="选择学历"
              columns={educationColumns}
              visible={educationShow}
              onClose={() => {
                setEducationShow(false);
              }}
              // defaultValue={[51]}
              onConfirm={(v) => {
                // setValue(v);
                props.getEducation(v);
              }}
            >
              {(value) =>
                value?.length > 0 ? (
                  <span className="pickerValues">
                    {value?.map((i) => i?.label)}
                  </span>
                ) : (
                  <span className="pickerTips">{item?.itemPlaceHolder}</span>
                )
              }
            </Picker>
          </>
        ) : item?.pickerStatus === "workCity" ? (
          <>
            <Cascader
              options={workCityOptions}
              // title="选择学历"
              visible={workCityShow}
              onClose={() => {
                setWorkCityShow(false);
              }}
              value={value}
              onConfirm={(v, extend) => {
                props.getWorkCity(extend.items);
                setValue(v);
              }}
            >
              {(value) =>
                value.length > 0 ? (
                  <span className="pickerValues">
                    {value?.map((i) => {
                      return i.label;
                    })}
                  </span>
                ) : (
                  <span className="pickerTips">{item?.itemPlaceHolder}</span>
                )
              }
            </Cascader>
          </>
        ) : item?.pickerStatus === "expectPosition" ? (
          <>
            <Picker
              columns={expectPositionColumns}
              visible={expectPositShow}
              onClose={() => {
                setExpectPositShow(false);
              }}
              value={value}
              onConfirm={(v) => {
                setValue(v);
              }}
            >
              {(value) =>
                value.length > 0 ? (
                  <span className="pickerValues">
                    {value?.map((i) => i?.label)}
                  </span>
                ) : (
                  <span className="pickerTips">{item?.itemPlaceHolder}</span>
                )
              }
            </Picker>
          </>
        ) : item?.pickerStatus === "salaryRequire" ? (
          <>
            <Picker
              columns={salaryRequireColumns}
              visible={salaryRequireShow}
              onClose={() => {
                setSalaryRequireShow(false);
              }}
              value={value}
              onConfirm={(v) => {
                setValue(v);
                props.getSalaryRequire(v);
              }}
            >
              {(value) =>
                value.length > 0 ? (
                  <span className="pickerValues">
                    {value?.map((i) => i?.label)}
                  </span>
                ) : (
                  <span className="pickerTips">{item?.itemPlaceHolder}</span>
                )
              }
            </Picker>
          </>
        ) : item?.pickerStatus === "entryPosition" ? (
          <>
            <Picker
              columns={entryPositionColumns}
              visible={entryPositionShow}
              onClose={() => {
                setEntryPositionShow(false);
              }}
              value={value}
              onConfirm={(v) => {
                setValue(v);
                props.getentryPosition(v);
              }}
            >
              {(value) =>
                value.length > 0 ? (
                  <span className="pickerValues">
                    {value?.map((i) => i?.label)}
                  </span>
                ) : (
                  <span className="pickerTips">{item?.itemPlaceHolder}</span>
                )
              }
            </Picker>
          </>
        ) : item?.pickerStatus === "rentalType" ? (
          <>
            <Picker
              columns={rentalTypeColumns}
              visible={rentalTypeShow}
              onClose={() => {
                setRentalTypeShow(false);
              }}
              value={value}
              onConfirm={(v) => {
                setValue(v);
                props.getRentalType(v);
              }}
            >
              {(value) =>
                value.length > 0 ? (
                  <span className="pickerValues">
                    {value?.map((i) => i?.label)}
                  </span>
                ) : (
                  <span className="pickerTips">{item?.itemPlaceHolder}</span>
                )
              }
            </Picker>
          </>
        ) : item?.pickerStatus === "prizeCertificate" ? (
          <>
            <Picker
              columns={prizeCertificateColumns}
              visible={prizeCertificateShow}
              onClose={() => {
                setPrizeCertificateShow(false);
              }}
              value={value}
              onConfirm={(v) => {
                setValue(v);
              }}
            >
              {(value) =>
                value.length > 0 ? (
                  <span className="pickerValues">
                    {value?.map((i) => i?.label)}
                  </span>
                ) : (
                  <span className="pickerTips">{item?.itemPlaceHolder}</span>
                )
              }
            </Picker>
          </>
        ) : (
          ""
        )}
      </Form.Item>
    </div>
  );
}

export default PublicPicker;
