import React, { useRef, useEffect, useState } from "react";
import { Dialog, List, SwipeAction } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { Image, Empty } from "antd-mobile";
import {
  talentComRec,
  isReadStatus,
  deleteRecord,
} from "../../../services/index";
import "./index.less";

function DemandCommunication() {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [sourse, setSourse] = useState();
  useEffect(() => {
    fn();
  }, []);
  const fn = async (param) => {
    let res = await talentComRec(param);
    setSourse(res?.data);
  };

  const handleClick = (param) => {
    return async () => {
      if (param.is_latest) {
        await isReadStatus(param);
      }
      navigate("/employchat", {
        state: {
          message_id: param.id,
        },
      });
    };
  };
  return (
    <div className="demandCommunication">
      {sourse?.length === 0 ? (
        <Empty
          style={{ padding: ".4rem 0", marginTop: "50%" }}
          imageStyle={{ width: 200 }}
          description="暂无数据"
        />
      ) : (
        <>
          <div className="styleDiv"></div>
          <List
            style={{
              "--border-top": "none",
              "--border-bottom": "none",
              "--border-inner": "none",
              padding: "0",
              border: "none",
            }}
          >
            {sourse?.map((item) => (
              <SwipeAction
                key={item.id}
                ref={ref}
                closeOnAction={false}
                closeOnTouchOutside={false}
                rightActions={[
                  {
                    key: "delete",
                    text: "删除",
                    color: "danger",
                    className: "recruitAction",
                    onClick: async (e) => {
                      e.stopPropagation();
                      await Dialog.confirm({
                        bodyClassName: "recruitBody",
                        content: "确定要删除吗？",
                        onConfirm: async () => {
                          let res = await deleteRecord(item.id);
                          const newData = sourse?.filter((i) => {
                            return i.id !== item.id;
                          });
                          setSourse(newData);
                        },
                      });
                      ref.current?.close();
                    },
                  },
                ]}
              >
                <List.Item>
                  <div>
                    <div
                      className="eneryBox flexBoxBetween"
                      onClick={handleClick({
                        id: item?.id,
                        is_latest: item?.is_latest,
                      })}
                    >
                      <div className="eneryBoxLeft">
                        <Image
                          style={{ borderRadius: "5px" }}
                          src={item?.member?.avatar || item?.member?.headimgurl}
                          fit="fill"
                        />
                        {item.is_latest === true ? (
                          <div className="roll"></div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="eneryBoxRight">
                        <div className="lineOne flexBoxBetween">
                          <span>
                            {item?.member?.nickname || item?.member?.full_name}
                          </span>
                          <span className="lineOneTime">{item.send_time}</span>
                        </div>
                        <div className="lineTwo">
                          <span className="lineTwoSpan">
                            {item?.intention?.expect_jon === 1
                              ? "家庭护理员"
                              : "养老护理员"}
                          </span>
                          <span className="lineTwoSpan">
                            {item?.intention?.wages_name}
                          </span>
                          <span className="lineTwoSpan">
                            {/* {item.intention?.province_name} */}
                            {item.intention?.city_name}
                          </span>
                        </div>
                        <div className="lineThree">
                          {item.is_latest === true ? (
                            <span className="newsTipUnRead">[新消息]</span>
                          ) : (
                            <span className="newsTipRead">[消息]</span>
                          )}
                          <span className="newsTextWarp">
                            <span className="newsText">{item?.content}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </List.Item>
              </SwipeAction>
            ))}
          </List>
        </>
      )}
    </div>
  );
}

export default DemandCommunication;
