import React, { useState, useEffect, useRef } from "react";
import { Input } from "antd-mobile";
import { useLocation } from "react-router-dom";
import BalloonLeft from "../../../components/BalloonLeft";
import BalloonRight from "../../../components/BalloonRight";
import SendOut from "../../../image/chatImg/sendOut.png";
import Refresh from "../../../image/chatImg/refresh.png";
import Head from "../../../image/chatImg/head.png";
import { empChat, talentSendMessage } from "../../../services/index.js";
import "./index.less";

function EmployChat() {
  const { state } = useLocation();
  const messagesEndRef = useRef(null);
  const messagesTopRef = useRef(null);
  const [value, setValue] = useState("");
  const [data, setData] = useState("");
  const [message, setMes] = useState([]);
  useEffect(() => {
    let body = document.body;
    body.style.backgroundColor = "#ffffff";
    fn();
    if (message.length <= 5) {
      scrollToTop();
    } else {
      scrollToBottom();
    }
  }, []);
  window.addEventListener(
    "popstate",
    function (e) {
      let body = document.body;
      body.style.backgroundColor = "#F7FBFF";
    },
    false
  );
  useEffect(() => {
    scrollToBottom();
  }, [message]);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToTop = () => {
    messagesTopRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const fn = async () => {
    let res = await empChat(state);
    setData(res?.data);
    setMes(res?.data?.message);
  };
  const sendOut = async () => {
    if (state.message_id) {
      let res = await talentSendMessage({
        ...state,
        content: value,
      });
      if (res.code === 1) {
        setMes([...message, { content: value, source: "organization" }]);
        setValue("");
      }
    } else {
      let res = await talentSendMessage({
        ...state,
        content: value,
      });
      if (res.code === 1) {
        setMes([...message, { content: value, source: "organization" }]);
        setValue("");
      }
    }
  };
  return (
    <div className="employChat">
      <div className="chatTop">
        <div className="contactInfo flexBoxBetween">
          <span className="telphone">
            联系方式：{data?.intention?.resume?.phone}
          </span>
        </div>
        <div className="businessCard">
          <div className="itemLineOne flexBoxBetween">
            <span className="OneTitle">
              {data?.intention?.expect_jon === 1 ? "家庭护理员" : "养老护理员"}
            </span>
            <span className="OneMoney">{data?.intention?.wages_name}</span>
          </div>
          <div className="itemLineTwo">
            <div className="itemLineTwoLf">
              <span className="TwoSpan">{data?.intention?.province_name}</span>
              <span className="TwoSpan">
                {data?.intention?.resume?.education_name}
              </span>
            </div>
            <div className="itemLineTwoRg">
              <span>
                {data?.intention?.province_name}&nbsp;
                {data?.intention?.city_name}
              </span>
            </div>
          </div>
        </div>
        <div className="tipNews">最新消息，请刷新查看</div>
      </div>
      <div className="messageBoard">
        <div ref={messagesTopRef} />
        {message?.map((i, index) =>
          message[index]?.source === "member" ? (
            <BalloonLeft
              key={index}
              avatar={
                data?.org_member?.avatar || data?.org_member?.headimgurl || Head
              }
              chatContent={message[index]?.content}
            ></BalloonLeft>
          ) : message[index]?.source === "organization" ? (
            <BalloonRight
              key={index}
              avatar={data?.member?.avatar || data?.member?.headimgurl || Head}
              chatContent={message[index]?.content}
            ></BalloonRight>
          ) : (
            ""
          )
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="chatBottom flexBoxBetween">
        <Input
          placeholder="请输入内容"
          value={value}
          onChange={(val) => {
            setValue(val);
          }}
          onEnterPress={sendOut}
        />
        <img className="senoutImg" src={SendOut} alt="" onClick={sendOut} />
        <img
          className="refreshImg"
          src={Refresh}
          alt=""
          onClick={() => {
            fn();
          }}
        />
      </div>
    </div>
  );
}

export default EmployChat;
