import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "antd-mobile";
import moment from "moment";
import InfoTableHeader from "../../../components/InfoTableHeader";
import InfoTableItem from "../../../components/InfoTableItem";
import { infoTableItemData } from "../../../utils/allInfoTableItem";
import { myAwardBaseInfo, trainApi } from "../../../services/index";
import "./index.less";

function TrainAward() {
  const [certificateTime, setCertificateTime] = useState("");
  const navigate = useNavigate();
  const [res, setRes] = useState({});
  const [certification_img, setCert] = useState("");
  useEffect(() => {
    fn();
  }, []);
  const fn = async () => {
    let res = await myAwardBaseInfo();
    setRes(res?.data);
  };
  const getTimer = (v) => {
    setCertificateTime(v);
  };
  const getPicture = (v) => {
    setCert(v.certification_img);
  };
  const certificateTimeValue = moment(certificateTime).format("YYYY-MM-DD");
  const addData = {
    certification_time: certificateTimeValue,
  };
  const onFinishTrain = async (values) => {
    if (addData?.certification_time === "Invalid date") {
      Toast.show(`请选择证书发放日期`, 2);
    } else {
      let res = await trainApi({ ...values, ...addData, certification_img });
      if (res.code === 404) {
        navigate("/applytips", {
          state: {
            type: res.data.type,
          },
        });
      }
    }
  };
  return (
    <div className="trainAward">
      <InfoTableHeader />
      <InfoTableItem
        precision="day"
        formatData="YYYY-MM-DD"
        initData={{ ...res }}
        getTimer={getTimer}
        FormItemData={infoTableItemData?.trainAwardForm}
        onFinish={onFinishTrain}
        getPicture={getPicture}
      />
    </div>
  );
}

export default TrainAward;
