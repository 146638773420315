import React, { useEffect } from "react";
import { Form, Input, Image, Space } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import Add from "../../image/myResumeImg/add.png";
import "./index.less";

function MyResumeItem(props) {
  const { initData } = props;
  const navigate = useNavigate();
  useEffect(() => {
    setInitData();
  });
  const skillfile = initData.skill_name || initData.skill_img;
  const skillfileImg = initData?.skill_img;
  const [form] = Form.useForm();
  const { FormItemData, onFinish } = props;
  const setInitData = () => {
    form.setFieldsValue({ ...initData });
  };
  return (
    <div className="myResumeItem">
      <Form
        form={form}
        initialValues={initData}
        layout="horizontal"
        onFinish={onFinish}
      >
        {FormItemData?.map((item) => (
          <div className="publicFormItem" key={item.id}>
            {item?.itemState === "baseDiv" ? (
              <div className="baseDivBox" />
            ) : item?.itemState === "base" ? (
              <Form.Item
                name={item.itemName}
                label={item.itemLabel}
                rules={[
                  { required: item?.isRequired, message: item?.itemMessage },
                ]}
              >
                <Input placeholder={item.itemPlaceHolder} readOnly />
              </Form.Item>
            ) : item?.itemState === "baseSex" ? (
              <Form.Item
                label={item.itemLabel}
                rules={[
                  { required: item?.isRequired, message: item?.itemMessage },
                ]}
              >
                {initData?.sex === 1 ? (
                  <Input
                    placeholder={item.itemPlaceHolder}
                    value="男"
                    readOnly
                  />
                ) : (
                  <Input
                    placeholder={item.itemPlaceHolder}
                    value="女"
                    readOnly
                  />
                )}
              </Form.Item>
            ) : item?.itemState === "ResumeShow" ? (
              <div className={item?.itemStyle}>
                <div className="baseShowHead">
                  <span style={{ fontWeight: "500" }}>{item.itemLabel}</span>
                  <span className="headTip">相关内容请去‘我的信息’中完善</span>
                </div>
                {(item?.itemType === "workExperience" && !initData.undergo) ||
                (item?.itemType === "skillfile" && !skillfile) ? (
                  <div className="nullTip">暂无数据</div>
                ) : (
                  ""
                )}
                {item?.itemType === "skillfile" && skillfile ? (
                  <div className="healthFileBox">
                    <div>{initData?.skill_name}</div>
                    <Space
                      className="pictureSpace"
                      wrap
                      style={{
                        width: "100%",
                        position: "relative",
                        "--gap": "4px",
                      }}
                    >
                      {skillfileImg?.map((i, index) => (
                        <Image
                          key={index}
                          src={i}
                          style={{ width: "1.3rem", height: "1.3rem" }}
                          fit="fill"
                        />
                      ))}
                    </Space>
                    <hr style={{ borderTop: "solid 1px #eeeeee" }} />
                  </div>
                ) : (
                  ""
                )}
                {item?.itemType === "workExperience" && initData.undergo
                  ? initData.undergo?.map((i) => (
                      <div key={i?.id}>
                        <div style={{ lineHeight: ".8rem" }}>
                          <span
                            style={{
                              fontSize: ".28rem",
                              color: "#686B73",
                              fontWeight: 400,
                            }}
                          >
                            {i.company_name}
                          </span>
                          <div
                            style={{
                              color: "#686B73",
                              float: "right",
                            }}
                          >
                            {i.work_start_time}-{i.work_end_time}
                          </div>
                        </div>
                        <div style={{ lineHeight: ".6rem" }}>
                          {i.office_name}
                        </div>
                        <div
                          style={{
                            color: "#686B73",
                            lineHeight: ".5rem",
                            textAlign: "justify",
                          }}
                        >
                          {i.job_content}
                        </div>
                        <hr style={{ borderTop: "solid 1px #eeeeee" }} />
                      </div>
                    ))
                  : ""}
              </div>
            ) : item?.itemState === "searchWork" ? (
              <div className={item?.itemStyle}>
                <div
                  onClick={() => {
                    navigate(item?.itemJumpRouter);
                  }}
                >
                  <span className="flexBoxBetween">
                    <span style={{ fontWeight: "500" }}>{item.itemLabel}</span>
                    <span className="editAddwrapBox">
                      <img className="imgAdd" src={Add} alt="" />
                      <span className="editTipsText">{item?.itemText}</span>
                    </span>
                  </span>
                </div>
                {item?.itemType === "searchWork" && initData.job_intention
                  ? initData.job_intention?.map((m) => (
                      <div
                        className="searchWorkiInfo"
                        onClick={() =>
                          navigate("/editsearchwork", {
                            state: {
                              id: m.id,
                            },
                          })
                        }
                        key={m.id}
                      >
                        <span className="searchCity">【{m.city_name}】</span>
                        <span className="searchCity">
                          {m.type === 1 ? "【全职】" : "【兼职】"}
                        </span>
                        <span className="searchCity">
                          {m.expect_jon === 1
                            ? "【家庭护理员】"
                            : "【养老护理员】"}
                        </span>
                        <span
                          style={{ color: "#666666", display: "inline-block" }}
                        >
                          {m.wages_name}
                        </span>
                      </div>
                    ))
                  : ""}
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      </Form>
    </div>
  );
}

export default MyResumeItem;
