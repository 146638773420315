import React from "react";
import { SearchBar } from "antd-mobile";
import "./index.less";

function PublicSearchBar(props) {
  const { searchData } = props;
  return (
    <div className="publicSearchBar">
      <SearchBar
        placeholder={searchData}
        onChange={(val) => {
          props.getSearch(val);
        }}
        style={{
          "--border-radius": "100px",
          "--background": "#ffffff",
        }}
      />
    </div>
  );
}

export default PublicSearchBar;
