import { DELETE_USER_INFO, SAVE_USER_INFO } from "../constant"

let username = localStorage.getItem('username')
let token = localStorage.getItem('token')

const initState = {
  username: username || '',
  token: token || '',
  isLogin: username && token ? true : false
}
export default function loginReducer (preState = initState, action) {
  const {type, data} = action
  let newState
  switch (type) {
    case SAVE_USER_INFO:
      newState = {username: data.username, token: data.token, isLogin: true}
      break
      case DELETE_USER_INFO:
        newState = preState
        break
      default:
        newState = preState
        break;
  }
  return newState
}