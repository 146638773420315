import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Image, List, Dialog } from "antd-mobile";
import { needsStatus } from "../../../services/index";
import talentMarket from "../../../image/recruitImg/talentMarket.png";
import talentCommunication from "../../../image/recruitImg/talentCommunication.png";
import demandPublish from "../../../image/recruitImg/demandPublish.png";
import arrow from "../../../image/assistantImg/arrow.png";
import "./index.less";

function Recruit() {
  const navigate = useNavigate();
  const [sourse, setSourse] = useState("");
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    fn();
  }, []);
  const fn = async () => {
    let res = await needsStatus();
    setSourse(res);
  };

  return (
    <div className="recruit">
      <div className="styleRec"></div>
      <List
        mode="card"
        style={{
          "--border-inner": "none",
          "--border-top": "none",
          "--border-bottom": "none",
          "--align-items": "center",
        }}
      >
        <List.Item
          className="listItem"
          prefix={<Image src={talentMarket} className="picture" fit="cover" />}
          arrow={<Image src={arrow} fit="cover" />}
          onClick={() => {
            if (sourse?.code === 1) {
              navigate("/talentmarket");
            } else {
              setVisible(true);
            }
          }}
        >
          人才市场
        </List.Item>
        <div className="styleDiv"></div>
        <List.Item
          className="listItem"
          prefix={
            <Image src={talentCommunication} className="picture" fit="cover" />
          }
          arrow={<Image src={arrow} fit="cover" />}
          onClick={() => {
            if (sourse?.code === 1) {
              navigate("/demandcommunication");
            } else {
              setVisible(true);
            }
          }}
        >
          人才沟通
        </List.Item>
        <div className="styleDiv"></div>
        <List.Item
          className="listItem"
          prefix={<Image src={demandPublish} className="picture" fit="cover" />}
          arrow={<Image src={arrow} fit="cover" />}
          onClick={() => {
            navigate("/demandrelease");
          }}
        >
          需求发布
        </List.Item>
        <div className="styleDiv"></div>
      </List>
      <Dialog
        className="publicListMask"
        visible={visible}
        content={
          <>
            <div className="tips">请您先进行需求发布</div>
            <div className="tips">再进行交流沟通</div>
          </>
        }
        confirmText="确认"
        closeOnAction
        onClose={() => {
          setVisible(false);
        }}
        actions={[
          {
            key: "confirm",
            text: "我知道了",
          },
        ]}
      />
    </div>
  );
}

export default Recruit;
