import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Empty, Image, Dialog, List, SwipeAction } from "antd-mobile";
import PublicSearchBar from "../../../components/PublicSearchBar";
import { searchTitle } from "../../../utils/allSearchBar";
import "./index.less";
import PublicMarket from "../../../components/PublicMarket";
import {
  jobList,
  communicateRecord,
  isReadStatus,
  deleteRecord,
} from "../../../services/index.js";
function RecruitMarket() {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [activeIndex, setActiveIndex] = useState("index1");
  const [sourse, setSourse] = useState([]);
  const [data, setData] = useState([]);
  const [messageData, setMessageData] = useState([]);
  const [page, setPage] = useState(1); //页数
  const [hasMore, setHasMore] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [keyData, setKeyData] = useState([]);

  useEffect(() => {
    fn({ page: 1, pageSize: 10 });
    api();
  }, []);
  const fn = async (param) => {
    let res = await jobList(param);
    setData(res?.data?.data_list);
    setSourse(res?.data?.data_list?.data);
    setHasMore(
      res?.data?.data_list?.current_page <= res?.data?.data_list?.last_page
    );
    setPage(res?.data?.data_list?.current_page);
  };
  const api = async (param) => {
    let res = await communicateRecord(param);
    setMessageData(res?.data);
  };
  const ActiveSpan = (param) => {
    return () => {
      setActiveIndex(param);
      if (param === "index1") {
        fn();
      } else {
        api();
      }
    };
  };
  const getSearch = async (param) => {
    setKeyword(param);
    let res = await jobList({ keyword: param });
    setKeyData(res?.data?.data_list?.data);
  };
  const getSearchMessage = (param) => {
    api({ name: param });
  };
  const handleClick = (param) => {
    return async () => {
      if (param.is_latest) {
        await isReadStatus(param);
      }
      navigate("/chat", {
        state: {
          message_id: param.id,
        },
      });
    };
  };
  const loadMore = async () => {
    let curPage = page + 1;
    setPage(curPage);
    await fn({ page: curPage, pageSize: 10 });
    setHasMore(data?.current_page <= data?.last_page);
    setDataList((val) => [...val, ...sourse]);
  };
  return (
    <div className="recruitMarket">
      <div className="topNavigation flexBoxAround">
        <div className="topNgtLf flexBoxBetween">
          {activeIndex === "index1" ? (
            <>
              <span className="NavigationSpan" onClick={ActiveSpan("index1")}>
                <div className="textActive">招聘岗位</div>
                <div className="bottomLineActive"></div>
              </span>
              <span className="NavigationSpan" onClick={ActiveSpan("index2")}>
                <div className="textDefault">沟通</div>
                <div className="bottomLineDefault"></div>
              </span>
            </>
          ) : (
            <>
              <span className="NavigationSpan" onClick={ActiveSpan("index1")}>
                <div className="textDefault">招聘岗位</div>
                <div className="bottomLineDefault"></div>
              </span>
              <span className="NavigationSpan" onClick={ActiveSpan("index2")}>
                <div className="textActive">沟通</div>
                <div className="bottomLineActive"></div>
              </span>
            </>
          )}
        </div>
        <div
          className="topNgtRg"
          onClick={() => {
            navigate("/myresume");
          }}
        >
          我的在线简历
        </div>
      </div>
      {activeIndex === "index1" ? (
        <>
          <PublicSearchBar
            getSearch={getSearch}
            searchData={searchTitle?.searchPost.title}
          />
          {sourse?.length === 0 && dataList?.length === 0 ? (
            <Empty
              style={{ padding: ".8rem 0", marginTop: "40%" }}
              imageStyle={{ width: 100 }}
              description="暂无数据"
            />
          ) : (
            <>
              {keyword ? (
                <PublicMarket
                  Data={keyData}
                  loadMore={loadMore}
                  hasMore={hasMore}
                  url="workdetail"
                />
              ) : (
                <PublicMarket
                  Data={dataList}
                  loadMore={loadMore}
                  hasMore={hasMore}
                  url="workdetail"
                />
              )}
            </>
          )}
        </>
      ) : (
        <>
          <PublicSearchBar
            getSearch={getSearchMessage}
            searchData={searchTitle?.searchPost.title}
          />
          {messageData?.length === 0 ? (
            <Empty
              style={{ padding: ".8rem 0", marginTop: "40%" }}
              imageStyle={{ width: 100 }}
              description="暂无数据"
            />
          ) : (
            <>
              <List
                style={{
                  "--border-top": "none",
                  "--border-bottom": "none",
                  "--border-inner": "none",
                  padding: "0",
                  border: "none",
                }}
              >
                {messageData?.map((item) => (
                  <SwipeAction
                    key={item.id}
                    ref={ref}
                    closeOnAction={false}
                    closeOnTouchOutside={false}
                    rightActions={[
                      {
                        key: "delete",
                        text: "删除",
                        color: "danger",
                        className: "recruitAction",
                        onClick: async (e) => {
                          e.stopPropagation();
                          await Dialog.confirm({
                            bodyClassName: "recruitBody",
                            content: "确定要删除吗？",
                            onConfirm: async () => {
                              let res = await deleteRecord(item.id);
                              const newData = messageData?.filter((i) => {
                                return i.id !== item.id;
                              });
                              setMessageData(newData);
                            },
                          });
                          ref.current?.close();
                        },
                      },
                    ]}
                  >
                    <List.Item>
                      <div>
                        <div
                          className="eneryBox flexBoxBetween"
                          onClick={handleClick({
                            id: item?.id,
                            is_latest: item?.is_latest,
                          })}
                        >
                          <div className="eneryBoxLeft">
                            <Image
                              style={{ borderRadius: "5px" }}
                              src={
                                item?.org_member?.avatar ||
                                item?.org_member?.headimgurl
                              }
                              fit="fill"
                            />
                            {item.is_latest === true ? (
                              <div className="roll"></div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="eneryBoxRight">
                            <div className="lineOne flexBoxBetween">
                              <span>{item?.org_member?.nickname}</span>
                              <span className="lineOneTime">
                                {item.send_time}
                              </span>
                            </div>
                            <div className="lineTwo">
                              <span className="lineTwoSpan">
                                {item?.position?.title}
                              </span>
                              <span className="lineTwoSpan">
                                {item?.position?.wages_name}
                              </span>
                              <span className="lineTwoSpan">
                                {item.position?.region?.name}
                              </span>
                            </div>
                            <div className="lineThree">
                              {item.is_latest === true ? (
                                <span className="newsTipUnRead">[新消息]</span>
                              ) : (
                                <span className="newsTipRead">[消息]</span>
                              )}
                              <span className="newsTextWarp">
                                <span className="newsText">
                                  {item?.content}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </List.Item>
                  </SwipeAction>
                ))}
              </List>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default RecruitMarket;
