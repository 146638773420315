import React, { useState, useEffect } from "react";
import { Form, Input, TextArea, Space } from "antd-mobile";
import HeadUpload from "../../../image/myInfoImg/headUpload.png";
import { getMyInfoApi } from "../../../services/index";
import "./index.less";

function MyInfoDetails() {
  const [form] = Form.useForm();
  const [vip_info, setVipInfo] = useState({});
  const [buttonsValue, setButtonsValue] = useState(1);
  const [echoIsTimerPicker, setEchoIsTimerPicker] = useState("");
  const [echoEducation, setEchoEducation] = useState("");
  const [undergo, setUndergo] = useState([]);
  const [avatar, setAvatar] = useState("");
  const [healthData, setHealthData] = useState({}); //健康档案
  const [skillData, setSkillData] = useState({}); //技能档案
  useEffect(() => {
    fn();
  }, []);

  const fn = async () => {
    let res = await getMyInfoApi();
    const { data } = res;
    form.setFieldsValue({ ...data?.vip_info });
    setVipInfo({ ...data?.vip_info });
    setButtonsValue(data?.vip_info?.sex);
    setEchoIsTimerPicker(data?.vip_info?.graduate_time);
    setEchoEducation(data?.vip_info?.education_name);
    setUndergo(data?.vip_info?.undergo || []);
    setAvatar(data?.vip_info?.headimgurl);
    setHealthData({
      height: data?.vip_info?.height,
      weight: data?.vip_info?.weight,
      chronic: data?.vip_info?.chronic,
      file_img: data?.vip_info?.file_img,
    });
    setSkillData({
      skill_name: data?.vip_info?.skill_name,
      skill_img: data?.vip_info?.skill_img,
    });
  };

  return (
    <div className="myInfoDetails">
      <div className="headImg">
        <div className="imgUploadSign">
          {avatar ? (
            <img src={avatar} className="UploadSign" alt="" />
          ) : (
            <img src={HeadUpload} className="UploadSign" alt="" />
          )}
        </div>
      </div>
      <div className="myInfoItem">
        <Form
          form={form}
          style={{ "--padding-left": "none" }}
          layout="horizontal"
        >
          <div className="baseDivBoxfirst" />
          <Form.Item
            name="full_name"
            label="姓名"
            initialValues={vip_info?.full_name}
            rules={[{ required: false, message: "姓名不能为空" }]}
          >
            {{ ...vip_info }["full_name"] ? (
              <Input placeholder={{ ...vip_info }["full_name"]} disabled />
            ) : (
              <Input placeholder="暂无数据" />
            )}
          </Form.Item>
          <Form.Item
            name="idcard"
            label="身份证号"
            initialValues={vip_info?.idcard}
            rules={[{ required: false, message: "身份证号不能为空" }]}
          >
            {{ ...vip_info }["idcard"] ? (
              <Input placeholder={{ ...vip_info }["idcard"]} disabled />
            ) : (
              <Input placeholder="暂无数据" />
            )}
          </Form.Item>
          <div className="buttonForm">
            <Form.Item
              style={{
                display: "inline-block",
              }}
              label="性别"
            >
              <div className="twoButton">
                <div className="awardIsEntryButton">
                  <div
                    className={
                      buttonsValue === 1 ? "checkStyle" : "notCheckStyle"
                    }
                  >
                    男
                  </div>
                  <div
                    className={
                      buttonsValue === 2 ? "checkStyle" : "notCheckStyle"
                    }
                  >
                    女
                  </div>
                </div>
              </div>
            </Form.Item>
          </div>
          <Form.Item
            name="age"
            label="年龄"
            initialValues={vip_info?.age}
            rules={[{ required: false, message: "年龄不能为空" }]}
          >
            {{ ...vip_info }["age"] ? (
              <Input placeholder={{ ...vip_info }["age"]} disabled />
            ) : (
              <Input placeholder="暂无数据" />
            )}
          </Form.Item>
          <Form.Item
            label="学历"
            rules={[{ required: false, message: "学历不能为空" }]}
          >
            {echoEducation ? (
              <Input placeholder={echoEducation} disabled />
            ) : (
              <Input placeholder="暂无数据" />
            )}
          </Form.Item>
          <Form.Item
            name="graduate_school"
            label="毕业院校"
            initialValues={vip_info?.graduate_school}
            rules={[{ required: false, message: "毕业院校不能为空" }]}
          >
            {vip_info?.graduate_school ? (
              <Input placeholder={vip_info?.graduate_school} disabled />
            ) : (
              <Input placeholder="暂无数据" />
            )}
          </Form.Item>
          <Form.Item label="毕业时间">
            {echoIsTimerPicker ? (
              <Input placeholder={echoIsTimerPicker} disabled />
            ) : (
              <Input placeholder="暂无数据" />
            )}
          </Form.Item>
          <Form.Item
            name="phone"
            label="联系电话"
            initialValues={vip_info?.phone}
            rules={[{ required: false, message: "联系电话不能为空" }]}
          >
            {{ ...vip_info }["phone"] ? (
              <Input placeholder={{ ...vip_info }["phone"]} disabled />
            ) : (
              <Input placeholder="暂无数据" />
            )}
          </Form.Item>
          <Form.Item
            name="contact_address"
            initialValues={vip_info?.contact_address}
            label="联系地址"
            rules={[{ required: false, message: "联系地址不能为空" }]}
          >
            {vip_info?.contact_address ? (
              <span className="echoData">{vip_info?.contact_address}</span>
            ) : (
              <TextArea
                placeholder="暂无数据"
                autoSize={{ minRows: 1, maxRows: 5 }}
                rows={1}
              />
            )}
          </Form.Item>
          <div className="distance">
            <span>
              <span style={{ fontWeight: "500", color: "#363A44" }}>
                就业经历
              </span>
            </span>
          </div>
          {undergo
            ? undergo?.map((i) => (
                <div key={i?.id}>
                  <div style={{ lineHeight: ".8rem" }}>
                    <span
                      style={{
                        fontSize: ".28rem",
                        color: "#686B73",
                        fontWeight: 400,
                      }}
                    >
                      {i.company_name}
                    </span>
                    <div
                      style={{
                        color: "#686B73",
                        float: "right",
                      }}
                    >
                      {i.work_start_time}-{i.work_end_time}
                    </div>
                  </div>
                  <div style={{ lineHeight: ".6rem" }}>{i.office_name}</div>
                  <div
                    style={{
                      color: "#686B73",
                      lineHeight: ".5rem",
                      textAlign: "justify",
                    }}
                  >
                    {i.job_content}
                  </div>
                  <div
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      width: "100%",
                      marginBottom: ".12rem",
                      marginTop: ".12rem",
                    }}
                  />
                </div>
              ))
            : ""}
          {/* 健康档案 */}
          {healthData?.height ||
          healthData?.weight ||
          healthData?.chronic ||
          healthData?.file_img ? (
            <div className="healthFileBox">
              <div
                className="healthFileBoxTitle"
                style={{ fontWeight: "500", color: "#363A44" }}
              >
                健康档案
              </div>
              <div>
                <span className="healthFileBody">
                  <span>身高：</span>
                  <span>{healthData?.height}cm</span>
                </span>
                <span className="healthFileBody">
                  <span>体重：</span>
                  <span>{healthData?.weight}kg</span>
                </span>
              </div>
              <div>{healthData?.chronic}</div>
              {/* <Space
                className="pictureSpace"
                wrap
                style={{
                  width: "100%",
                  "--gap-vertical": "none",
                  position: "relative",
                  "--gap": "4px",
                }}
              > */}
              {healthData?.file_img?.map((i, index) => (
                <img
                  style={{
                    display: "inline-block",
                    width: "1.3rem",
                    height: "1.3rem",
                    borderRadius: ".1rem",
                  }}
                  key={index}
                  src={i}
                  alt=""
                />
              ))}
              {/* </Space> */}
              <div
                style={{
                  borderBottom: "solid 1px #eeeeee",
                  width: "100%",
                  marginBottom: ".12rem",
                  // height: ".25rem",
                  // padding: "0 .3rem",
                }}
              />
            </div>
          ) : (
            ""
          )}
          {/* 技能档案 */}
          {skillData && Object.keys(skillData).length !== 0 ? (
            <div className="healthFileBox">
              {skillData?.skill_name || skillData?.skill_img ? (
                <div
                  className="healthFileBoxTitle"
                  style={{ fontWeight: "500", color: "#363A44" }}
                >
                  技能档案
                </div>
              ) : (
                ""
              )}
              <div>{skillData?.skill_name}</div>
              {/* <Space
                className="pictureSpace"
                wrap
                style={{ width: "100%", position: "relative", "--gap": "4px" }}
              > */}
              {skillData?.skill_img?.map((i, index) => (
                <img
                  style={{
                    display: "inline-block",
                    width: "1.3rem",
                    height: "1.3rem",
                    borderRadius: ".1rem",
                  }}
                  key={index}
                  src={i}
                  alt=""
                />
              ))}
              {/* </Space> */}
              {/* <hr style={{ borderTop: "solid 1px #eeeeee" }} /> */}
            </div>
          ) : (
            ""
          )}
        </Form>
      </div>
    </div>
  );
}

export default MyInfoDetails;
