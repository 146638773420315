export const twoButtonData = {
  yesOrNoButton: {
    checkValue: 5,
    anotherValue: 6,
    checkValueText: "是",
    anotherValueText: "否",
  },
  seachWorkType: {
    checkValue: 1,
    anotherValue: 2,
    checkValueText: "全职",
    anotherValueText: "兼职",
  },
  sexType: {
    checkValue: 1,
    anotherValue: 2,
    checkValueText: "男",
    anotherValueText: "女",
  },
};
