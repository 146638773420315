import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { wechat } from "../../services/index";
import "./index.less";
import { saveUserInfo } from "../../redux/actions/login";

function Login(props) {
  const location = useLocation();
  const code = new URLSearchParams(location.search).get("code");
  useEffect(() => {
    fn();
  });
  const fn = async () => {
    let res = await wechat({ code: code });
    if (res.code === 1) {
      // 保存状态到redux
      props.saveUser({ token: res.data.token });
      localStorage.setItem("token", res.data.token);
    }
  };

  if (props.user.isLogin) {
    return <Navigate to="/index" />;
  } else {
    return <div className="login"></div>;
  }
}

export default connect((state) => ({ user: state.user }), {
  saveUser: saveUserInfo,
})(Login);
