import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "antd-mobile";
import moment from "moment";
import InfoTableHeader from "../../../components/InfoTableHeader";
import InfoTableItem from "../../../components/InfoTableItem";
import { infoTableItemData } from "../../../utils/allInfoTableItem";
import { allPickerData } from "../../../utils/allPicker";
import { myAwardBaseInfo, stationApi } from "../../../services/index";
import "./index.less";

function StationAward() {
  const navigate = useNavigate();
  const [res, setRes] = useState({});
  const [education, setEducation] = useState("");
  const [certificationTime, setCertificationTime] = useState("");
  const [entryTimeValue, setEntryTimeValue] = useState("");
  const [entryPosition, setEntryPosition] = useState("");
  const [windUpCertificate, setWindUpCertificate] = useState("");
  const [workTime, setWorkTime] = useState("");
  const [diploma, setEdu] = useState("");
  const [certification_img, setCert] = useState("");
  const [contract_img, setCont] = useState("");
  useEffect(() => {
    fn();
  }, []);

  const getEducation = (v) => {
    setEducation(v[0]);
  };
  const getTimer = (v) => {
    setCertificationTime(v);
  };
  let certificationTimeValue = moment(certificationTime).format("YYYY-MM-DD");
  const getTimer1 = (v) => {
    setEntryTimeValue(v);
  };
  let entryTimeVal = moment(entryTimeValue).format("YYYY-MM-DD");
  const getWindUpCertificate = (v) => {
    setWindUpCertificate(v[0]);
  };
  const getWorkTime = (v) => {
    setWorkTime(v[0]);
  };
  const getentryPosition = (v) => {
    setEntryPosition(v[0]);
  };
  const getPicture = (v) => {
    if (Object.keys(v).includes("diploma")) {
      setEdu(v.diploma);
    } else if (Object.keys(v).includes("certification_img")) {
      setCert(v.certification_img);
    } else if (Object.keys(v).includes("contract_img")) {
      setCont(v.contract_img);
    }
  };
  const fn = async () => {
    let res = await myAwardBaseInfo();
    setRes(res?.data);
  };
  const educationColumns =
    res?.data_education?.map((item) => {
      item.label = item.name;
      item.value = item.id;
      return item;
    }) || [];
  const entryPositionColumns =
    res?.data_entry?.map((item) => {
      item.label = item.name;
      item.value = item.id;
      return item;
    }) || [];
  const workTimeColumns =
    res?.data_job_years?.map((item) => {
      item.label = item.name;
      item.value = item.id;
      return item;
    }) || [];
  const windUpCertificateColumns =
    res?.data_contract_years?.map((item) => {
      item.label = item.name;
      item.value = item.id;
      return item;
    }) || [];

  const addData = {
    education_id: education,
    certification_time: certificationTimeValue, //资质证书发放时间
    entry_time: entryTimeVal, //入职时间
    entry_id: entryPosition, //入职岗位
    contract_years_id: windUpCertificate, //合同签订年限
    job_years_id: workTime, //工作年限
  };
  const onFinishStation = async (values) => {
    if (addData?.certification_time === "Invalid date") {
      Toast.show(`请选择资质发放日期`, 2);
    } else if (addData?.entry_time === "Invalid date") {
      Toast.show(`请选择入职时间`, 2);
    } else {
      let res = await stationApi({
        ...values,
        ...addData,
        certification_img,
        diploma,
        contract_img,
      });
      if (res.code === 404) {
        navigate("/applytips", {
          state: {
            type: res.data.type,
          },
        });
      }
    }
  };
  return (
    <div className="stationAward">
      <InfoTableHeader />
      <InfoTableItem
        precision="day"
        formatData="YYYY-MM-DD"
        precision1="day"
        formatData1="YYYY-MM-DD"
        initData={{ ...res }}
        getEducation={getEducation}
        getTimer={getTimer} //资质发放日期
        getTimer1={getTimer1} //入职时间
        FormItemData={infoTableItemData?.stationAwardForm}
        getSalaryRequire={getWorkTime} //工作年限
        salaryRequireColumns={[workTimeColumns]} //工作年限
        getRentalType={getWindUpCertificate} //获取合同签订年限
        rentalTypeColumns={[windUpCertificateColumns]} //合同签订年限
        educationColumns={[educationColumns]}
        entryPositionColumns={[entryPositionColumns]}
        getentryPosition={getentryPosition}
        basicColumns={allPickerData.windUpCertificateColumns}
        trainLevelColumns={allPickerData.trainLevelColumns}
        trainSchoolColumns={allPickerData.trainSchoolColumns}
        workCityOptions={allPickerData.workCityColumns}
        expectPositionColumns={allPickerData.expectPositionColumns}
        onFinish={onFinishStation}
        getPicture={getPicture}
      />
    </div>
  );
}

export default StationAward;
