import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InfoTableHeader from "../../../components/InfoTableHeader";
import InfoTableItem from "../../../components/InfoTableItem";
import { infoTableItemData } from "../../../utils/allInfoTableItem";
import { nursingApi } from "../../../services/index";
import "./index.less";

function EmployApply() {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [img_path, setImg] = useState("");
  const fn = async (params) => {
    let res = await nursingApi(params);
    setData(res);
  };
  const getPicture = (v) => {
    setImg(v?.img_path);
  };
  const onFinishEmployApply = (values) => {
    fn({ ...values, img_path });
    if (data.code === 404) {
      navigate("/applytipstopage", {
        state: {
          type: data.data.type,
        },
      });
    }
  };

  return (
    <div className="employApply">
      <InfoTableHeader />
      <InfoTableItem
        getPicture={getPicture}
        FormItemData={infoTableItemData?.employApplyForm}
        onFinish={onFinishEmployApply}
      />
    </div>
  );
}

export default EmployApply;
