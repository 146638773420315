import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  ImageUploader,
  TextArea,
  Picker,
  Cascader,
  Dialog,
  Toast,
} from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { pictureUpload } from "../../../services/index";
import Upload from "../../../image/formImg/upload.png";
import { needsSubmit, getdemandApi, regionList } from "../../../services/index";
import "./index.less";

function DemandRelease() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [value, setValue] = useState();
  const [educationShow, setEducationShow] = useState(false);
  const [rentalTypeShow, setRentalTypeShow] = useState(false);
  const [entryPositionShow, setEntryPositionShow] = useState(false);
  const [workCityShow, setWorkCityShow] = useState(false);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [picker, setPicker] = useState(false);
  const [res, setRes] = useState({});
  const [startSalaryValue, setStartSalaryValue] = useState("");
  const [endSalaryValue, setEndSalaryValue] = useState("");
  const [education, setEducation] = useState("");
  const [workCity, setWorkCity] = useState([]);
  const [require, setRequire] = useState("");
  const [experience, setExperience] = useState("");
  const [visible, setVisible] = useState("");
  const [region, setRegion] = useState();
  useEffect(() => {
    fn();
    api();
  }, []);
  const fn = async () => {
    let res = await getdemandApi();
    setRes(res?.data);
    const echoMinEdu = res?.data?.recruit?.min_education_id;
    setEducation(echoMinEdu);
    setRequire(res?.data?.recruit?.cert_id);
    setExperience(res?.data?.recruit?.empiric_id);
    setStartSalaryValue(res?.data?.recruit?.wages_low);
    setEndSalaryValue(res?.data?.recruit?.wages_heigh);
    form.setFieldsValue({ ...res?.data?.recruit });
    let arr = res?.data?.recruit?.setting_img?.map((i) => {
      const url = i.split(",")[0];
      return {
        url,
      };
    });
    setFileList(arr);
  };
  const api = async () => {
    let res = await regionList();
    setRegion(res?.data);
  };
  const entryTime = () => {
    setPickerVisible(true);
  };
  const finishTime = () => {
    setPicker(true);
  };
  const getEducation = (v) => {
    setEducation(v[0]);
  };
  const getStartSalary = (v) => {
    setStartSalaryValue(v[0]);
  };
  const getEndSalary = (v) => {
    setEndSalaryValue(v[0]);
  };
  const getRequire = (v) => {
    //证书要求
    setRequire(v[0]);
  };
  // 经验要求
  const getExperience = (v) => {
    setExperience(v[0]);
  };
  // 工作地址
  const getWorkCity = (v) => {
    setWorkCity(v);
  };

  // 省市区选择框数据格式处理
  const address = region?.area?.map((province) => ({
    label: province.name,
    value: province.id,
    children: province?.citys?.map((city) => ({
      value: city.id,
      label: city.name,
      children: city?.areas?.map((area) => ({
        value: area.id,
        label: area.name,
      })),
    })),
  }));
  var arr = new Array(50);
  for (var i = 0; i < arr.length; i++) {
    arr[i] = (i + 1) * 1000;
  }
  const salaryColumns = arr?.map((item) => ({ label: item, value: item }));
  const educationColumns =
    res?.data_education?.map((item) => {
      item.label = item.name;
      item.value = item.id;
      return item;
    }) || [];
  // 证书要求
  const requireColumns =
    res?.data_cert?.map((item) => {
      item.label = item.name;
      item.value = item.id;
      return item;
    }) || [];
  // 经验要求
  const experienceColumns =
    res?.data_empiric?.map((item) => {
      item.label = item.name;
      item.value = item.id;
      return item;
    }) || [];
  const mockUpload = async (files) => {
    const formData = new FormData();
    formData.append("file", files);
    let response = await pictureUpload(formData);
    return {
      url: response?.data?.url,
    };
  };
  const urlValues = fileList
    ?.map((i) => {
      return i?.url;
    })
    .join(",");
  const add = {
    cert_id: require, //证书要求
    min_education_id: education, //最低学历
    empiric_id: experience, //经验要求
    wages_low: startSalaryValue, //最低薪资
    wages_heigh: endSalaryValue, //最高薪资
    province_id: workCity[0]?.value || res?.recruit?.region?.city?.parent_id,
    city_id: workCity[1]?.value || res?.recruit?.city_id,
    // job_address: "",
    region_id: workCity[2]?.value || res?.recruit?.region_id,
    setting_img: urlValues,
  };
  const onFinishStation = async (values) => {
    if (
      add?.setting_img === "" ||
      add?.setting_img === [] ||
      add?.setting_img === undefined
    ) {
      Toast.show(`请上传环境图片`);
    } else if (
      values?.full_name === "" ||
      values?.full_name === null ||
      values?.full_name === undefined
    ) {
      Toast.show(`请输入姓名`);
    } else if (
      values?.idcard === "" ||
      values?.idcard === null ||
      values?.idcard === undefined
    ) {
      Toast.show(`请输入身份证`);
    } else if (
      values?.phone === "" ||
      values?.phone === null ||
      values?.phone === undefined
    ) {
      Toast.show(`请输入联系电话`);
    } else if (
      values?.age === "" ||
      values?.age === null ||
      values?.age === undefined
    ) {
      Toast.show(`请输入年龄`);
    } else if (
      values?.connect_address === "" ||
      values?.connect_address === null ||
      values?.connect_address === undefined
    ) {
      Toast.show(`请输入联系地址`);
    } else if (
      values?.job_address === "" ||
      values?.job_address === null ||
      values?.job_address === undefined
    ) {
      Toast.show(`请输入详细地址`);
    } else if (
      values?.job_text === "" ||
      values?.job_text === null ||
      values?.job_text === undefined
    ) {
      Toast.show(`请输入岗位描述`);
    } else if (
      values?.title === "" ||
      values?.title === null ||
      values?.title === undefined
    ) {
      Toast.show(`请输入岗位名称`);
    } else if (
      add?.wages_heigh === 0 ||
      add?.wages_heigh === null ||
      add?.wages_heigh === undefined
    ) {
      Toast.show(`请输入最高薪资`);
    } else if (
      add?.wages_low === 0 ||
      add?.wages_low === null ||
      add?.wages_low === undefined
    ) {
      Toast.show(`请输入最低薪资`);
    } else if (
      values?.wages_text === "" ||
      values?.wages_text === null ||
      values?.wages_text === undefined
    ) {
      Toast.show(`请您输入薪资描述`);
    } else if (
      add.province_id === null ||
      add.province_id === undefined ||
      add.province_id === ""
    ) {
      Toast.show(`请您选择工作地址`, 2);
    } else if (
      add.city_id === null ||
      add.city_id === undefined ||
      add.city_id === ""
    ) {
      Toast.show(`工作城市需精确到县/区`, 2);
    } else if (
      add.province_id &&
      add.region_id === undefined &&
      add.city_id === undefined
    ) {
      setVisible(true);
      Dialog.confirm({
        bodyClassName: "collectDetailsBody",
        content: "工作城市需精确到县/区",
        showCloseButton: true,
        onConfirm: () => {
          setVisible(false);
        },
      });
    } else if (add.region_id === undefined) {
      setVisible(true);
      Dialog.confirm({
        bodyClassName: "collectDetailsBody",
        content: "工作城市需精确到县/区",
        showCloseButton: true,
        onConfirm: () => {
          setVisible(false);
        },
      });
    } else if (
      add.province_id &&
      add.city_id &&
      add.region_id &&
      add.province_id !== res?.recruit?.region?.city?.parent_id &&
      add.city_id === res?.recruit?.city_id
    ) {
      setVisible(true);
      Dialog.confirm({
        bodyClassName: "collectDetailsBody",
        content: "工作城市需精确到县/区",
        showCloseButton: true,
        onConfirm: () => {
          setVisible(false);
        },
      });
    } else if (
      add.province_id &&
      add.city_id &&
      add.region_id &&
      add.city_id !== res?.recruit?.city_id &&
      add.region_id === res?.recruit?.region_id
    ) {
      setVisible(true);
      Dialog.confirm({
        bodyClassName: "collectDetailsBody",
        content: "工作城市需精确到县/区",
        showCloseButton: true,
        onConfirm: () => {
          setVisible(false);
        },
      });
    } else {
      let res = await needsSubmit({ ...values, ...add });
      if (res?.code === 1) {
        Dialog.alert({
          content: "提交成功返回首页",
          onConfirm: () => {
            navigate("/recruit");
          },
        });
      }
    }
  };
  return (
    <div className="demandRelease">
      <div className="employItem">
        <Form
          form={form}
          layout="horizontal"
          onFinish={onFinishStation}
          style={{ "--padding-left": "none" }}
          footer={
            <Button
              block
              className="submitButton"
              type="submit"
              color="primary"
            >
              提交发布
            </Button>
          }
        >
          <div className="infoTableItemTitle">
            <span className="leftSide" />
            招聘人信息
          </div>
          <Form.Item
            name="full_name"
            label="姓名"
            rules={[{ required: false, message: "姓名为必填项" }]}
          >
            <Input placeholder="请输入姓名" />
          </Form.Item>
          <Form.Item
            name="idcard"
            label="身份证"
            rules={[{ required: false, message: "身份证为必填项" }]}
          >
            <Input placeholder="请输入身份证" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="联系电话"
            rules={[{ required: false, message: "联系电话为必填项" }]}
          >
            <Input placeholder="请输入联系电话" />
          </Form.Item>
          <Form.Item
            name="age"
            label="年龄"
            rules={[{ required: false, message: "年龄为必填项" }]}
          >
            <Input placeholder="请输入年龄" />
          </Form.Item>
          <Form.Item
            name="connect_address"
            label="联系地址"
            rules={[{ required: false, message: "联系地址为必填项" }]}
          >
            <Input placeholder="请输入联系地址" />
          </Form.Item>
          <div className="baseDivBox" />
          <div className="infoTableItemTitle">
            <span className="leftSide" />
            岗位信息
          </div>
          <Form.Item
            name="title"
            label="岗位名称"
            rules={[{ required: false, message: "岗位名称为必填项" }]}
          >
            <Input placeholder="请输入岗位名称" />
          </Form.Item>
          <Form.Item
            label="证书要求"
            trigger="onConfirm"
            onClick={() => {
              setRentalTypeShow(true);
            }}
          >
            <>
              <Picker
                columns={[requireColumns]}
                visible={rentalTypeShow}
                onClose={() => {
                  setRentalTypeShow(false);
                }}
                defaultValue={[require]}
                onConfirm={(v) => {
                  // setValue(v);
                  getRequire(v);
                }}
              >
                {(value) =>
                  (value?.length > 0 && value[0] !== null) ||
                  res?.recruit?.cert_name ? (
                    <span className="pickerValues">
                      {value?.length > 0 && value[0] !== null
                        ? value?.map((i) => i?.label)
                        : res?.recruit?.cert_name}
                    </span>
                  ) : (
                    <span className="pickerTips">请输入证书要求</span>
                  )
                }
              </Picker>
            </>
          </Form.Item>
          <Form.Item
            label="最低学历"
            // rules={[
            //   { required: item?.isRequired, message: item?.itemMessage },
            // ]}
            trigger="onConfirm"
            onClick={() => {
              setEducationShow(true);
            }}
          >
            <>
              <Picker
                title="选择学历"
                columns={[educationColumns]}
                visible={educationShow}
                onClose={() => {
                  setEducationShow(false);
                }}
                defaultValue={[education]}
                onConfirm={(v) => {
                  // setValue(v);
                  getEducation(v);
                }}
              >
                {(value) =>
                  (value?.length > 0 && value[0] !== null) ||
                  res?.recruit?.min_education_name ? (
                    <span className="pickerValues">
                      {value?.length > 0 && value[0] !== null
                        ? value?.map((i) => i?.label)
                        : res?.recruit?.min_education_name}
                    </span>
                  ) : (
                    <span className="pickerTips">请输入最低学历</span>
                  )
                }
              </Picker>
            </>
          </Form.Item>
          <Form.Item
            label="经验要求"
            // rules={[
            //   { required: item?.isRequired, message: item?.itemMessage },
            // ]}
            trigger="onConfirm"
            onClick={() => {
              setEntryPositionShow(true);
            }}
          >
            <>
              <Picker
                columns={[experienceColumns]}
                visible={entryPositionShow}
                onClose={() => {
                  setEntryPositionShow(false);
                }}
                defaultValue={[experience]}
                onConfirm={(v) => {
                  // setValue(v);
                  getExperience(v);
                }}
              >
                {(value) =>
                  (value?.length > 0 && value[0] !== null) ||
                  res?.recruit?.empiric_name ? (
                    <span className="pickerValues">
                      {value?.length > 0 && value[0] !== null
                        ? value?.map((i) => i?.label)
                        : res?.recruit?.empiric_name}
                    </span>
                  ) : (
                    <span className="pickerTips">请输入经验要求</span>
                  )
                }
              </Picker>
            </>
          </Form.Item>
          <Form.Item
            name="job_text"
            label="岗位描述"
            // rules={[
            //   { required: item?.isRequired, message: item?.itemMessage },
            // ]}
          >
            <TextArea placeholder="请输入岗位描述" />
          </Form.Item>
          <>
            {/* <span
              className="baseProStar"
              style={{
                color: "#F93A42",
                position: "relative",
                left: ".32rem",
              }}
            >
              *
            </span> */}
            <Form.Item
              style={{ display: "inline-block", width: "100%" }}
              label="薪资范围"
              rules={[{ required: true, message: "薪资范围不能为空" }]}
            >
              <Picker
                columns={[salaryColumns]}
                visible={pickerVisible}
                onClose={() => {
                  setPickerVisible(false);
                }}
                defaultValue={[startSalaryValue]}
                // value={value}
                onConfirm={(v) => {
                  // setValue(v);
                  getStartSalary(v);
                }}
              >
                {(value) =>
                  (value?.length > 0 && value[0] !== null) ||
                  res?.recruit?.wages_low ? (
                    <span className="pickerValues" onClick={entryTime}>
                      {value?.length > 0 && value[0] !== null
                        ? value?.map((i) => i?.label)
                        : res?.recruit?.wages_low}
                    </span>
                  ) : (
                    <span
                      className="pickerTips"
                      onClick={entryTime}
                      style={{ color: "#cccccc" }}
                    >
                      最低薪资
                    </span>
                  )
                }
              </Picker>
              <span className="infoPro">&nbsp;~&nbsp;</span>
              <Picker
                columns={[salaryColumns]}
                visible={picker}
                onClose={() => {
                  setPicker(false);
                }}
                defaultValue={[endSalaryValue]}
                // value={value}
                onConfirm={(v) => {
                  // setValue(v);
                  getEndSalary(v);
                }}
              >
                {(value) =>
                  (value?.length > 0 && value[0] !== null) ||
                  res?.recruit?.wages_heigh ? (
                    <span className="pickerValues" onClick={finishTime}>
                      {value?.length > 0 && value[0] !== null
                        ? value?.map((i) => i?.label)
                        : res?.recruit?.wages_heigh}
                    </span>
                  ) : (
                    <span
                      className="pickerTips"
                      onClick={finishTime}
                      style={{ color: "#cccccc" }}
                    >
                      最高薪资
                    </span>
                  )
                }
              </Picker>
            </Form.Item>
          </>
          <Form.Item name="wages_text" label="薪资描述">
            <TextArea placeholder="请输入薪资描述" />
          </Form.Item>
          <div className="baseDivBox" />
          <div className="infoTableItemTitle">
            <span className="leftSide" />
            岗位信息
          </div>
          <Form.Item
            // name={item.itemName}
            // label={item.itemLabel}
            rules={[{ required: true, message: "环境照片必填" }]}
          >
            <div className="uploadTop">
              {/* <span style={{ color: "#F93A42" }}>*</span> */}
              <span className="uploadTitle">环境照片</span>
              <span className="uploadTips">最多可上传4张图片</span>
            </div>
            <div className="uploadWrap">
              <ImageUploader
                style={{ "--cell-size": "1.2rem" }}
                value={fileList}
                maxCount={4}
                onChange={setFileList}
                upload={mockUpload}
                onDelete={() => {
                  return Dialog.confirm({
                    content: "是否确认删除",
                  });
                }}
              >
                <div
                  style={{
                    width: "1.2rem",
                    height: "1.2rem",
                    // borderRadius: 40,
                    backgroundColor: "#f5f5f5",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#999999",
                  }}
                >
                  <img
                    style={{ width: "1.2rem", height: "1.2rem" }}
                    alt=""
                    src={Upload}
                  />
                </div>
              </ImageUploader>
            </div>
          </Form.Item>
          <Form.Item
            label="工作地址"
            rules={[{ required: false, message: "工作地址为必填" }]}
            trigger="onConfirm"
            onClick={() => {
              setWorkCityShow(true);
            }}
          >
            <>
              <Cascader
                options={address}
                // title="选择学历"
                visible={workCityShow}
                onClose={() => {
                  setWorkCityShow(false);
                }}
                value={value}
                onConfirm={(v, extend) => {
                  getWorkCity(extend.items);
                  setValue(v);
                }}
              >
                {(value) =>
                  (value?.length > 0 && value[0] !== null) ||
                  res?.recruit?.region?.parent_id ? (
                    <span className="pickerValues">
                      {value?.length > 0 && value[0] !== null ? (
                        value?.map((i) => i?.label)
                      ) : (
                        <>
                          {res?.recruit?.region?.city?.province?.name}
                          {res?.recruit?.region?.city?.name}
                          {res?.recruit?.region?.name}
                        </>
                      )}
                    </span>
                  ) : (
                    <span className="pickerTips">请输入工作地址</span>
                  )
                }
              </Cascader>
            </>
          </Form.Item>
          <Form.Item
            name="job_address"
            label="详细地址"
            className="jobAddress"
            style={{ "--border-inner": "none" }}
            rules={[{ required: false, message: "详细地址不能为空" }]}
          >
            <Input placeholder="请输入详细地址" />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default DemandRelease;
