import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import QuestionType from "../../../components/QuestionType";
import { collectListApi } from "../../../services/index";
import "./index.less";

function Collection() {
  const navigate = useNavigate();
  useEffect(() => {
    fn();
  }, []);
  const [isAllCollection, setIsAllCollection] = useState(0);
  const [res, setRes] = useState();
  const fn = async (params = { type: 1, pageSize: 5 }) => {
    let res = await collectListApi(params);
    setRes(res);
  };
  const handleType = (param) => {
    navigate("/collectdetails", {
      state: {
        type: param,
      },
    });
  };
  const handleSpan = (param) => {
    return async () => {
      setIsAllCollection(param);
      await fn({ index: param });
    };
  };
  return (
    <div className="collection">
      <div className="collectionType">
        <span
          onClick={handleSpan(0)}
          className={
            isAllCollection === 0 ? "activeCollection" : "activeDefault"
          }
        >
          今日收藏({res?.data?.today_num})
        </span>
        <span
          onClick={handleSpan(1)}
          className={
            isAllCollection === 1 ? "activeCollection" : "activeDefault"
          }
        >
          全部收藏({res?.data?.total_num})
        </span>
      </div>
      <QuestionType handleType={handleType} Number={res?.data?.list} />
    </div>
  );
}

export default Collection;
