import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Image, InfiniteScroll } from "antd-mobile";
import { peopleMarket } from "../../../services/index";
import "./index.less";

function TalentMarket() {
  const [sourse, setSourse] = useState([]);
  const [data, setData] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1); //页数
  const navigate = useNavigate();
  useEffect(() => {
    fn({ page: 1, pageSize: 10 });
  }, []);
  const fn = async (param) => {
    let res = await peopleMarket(param);
    setData(res?.data);
    setSourse(res?.data?.data);
    setHasMore(res?.data?.current_page <= res?.data?.last_page);
  };
  const loadMore = async () => {
    let curPage = page + 1;
    setPage(curPage);
    await fn({ page: curPage, pageSize: 10 });
    setHasMore(data?.current_page <= data?.last_page);
    setDataList((val) => [...val, ...sourse]);
  };
  return (
    <div className="talentMarket">
      <div>
        {dataList?.map((i) => (
          <div
            key={i.id}
            className="businessCard flexBoxBetween"
            onClick={() => {
              navigate("/talentresume", {
                state: {
                  id: i?.resume?.id,
                },
              });
            }}
          >
            <div className="businessCardLf">
              <Image
                src={i?.resume?.avatar || i?.resume?.headimgurl}
                style={{ width: ".8rem", height: ".8rem" }}
                fit="fill"
              />
            </div>
            <div className="businessCardRg">
              <div className="itemLineOne flexBoxBetween">
                <span className="OneTitle">{i?.resume?.full_name}</span>
                <span className="OneMoney">{i?.wages_name}</span>
              </div>
              <div className="itemLineTwo flexBoxBetween">
                <div className="itemLineTwoLf">
                  <span className="TwoSpan">家庭护理员</span>
                  <span className="TwoSpan">
                    {i?.type === 1 ? "全职" : "兼职"}
                  </span>
                </div>
                <div className="itemLineTwoRg">
                  <span>{i?.resume?.contact_address}</span>
                </div>
              </div>
              <div className="itemLineThree">
                <span className="ThreeSpan1">
                  {i?.resume?.sex === 1 ? "男" : "女"}
                </span>
                <span className="ThreeSpan1">{i?.resume?.age}岁</span>
                {/*a9经验,字段未返回 */}
                {/* <span className="ThreeSpan1">{i.a9}</span> */}
                <span className="ThreeSpan2">{i?.resume?.education_name}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
    </div>
  );
}

export default TalentMarket;
