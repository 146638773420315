const test = [
  {
    id: "educationInfoForm" + 3,
    itemState: "baseImg",
    isRequired: true,
    itemName: "education",
    itemLabel: "图片",
    itemPlaceHolder: "请输入图片",
    itemMessage: "图片不能为空",
    isClickPicker: true,
    isStarRequired: "*",
    itemImgTitle: "最多可上传4张图片",
  },
];
const baseInfo = [
  {
    id: "baseInfo" + 0,
    itemState: "lowBase",
    itemTitle: "基本信息",
  },
  {
    id: "baseInfo" + 1,
    itemState: "base",
    isRequired: true,
    itemName: "full_name",
    itemLabel: "姓名",
    itemPlaceHolder: "请输入姓名",
    itemMessage: "姓名不能为空",
  },
  {
    id: "baseInfo" + 2,
    itemState: "base",
    isRequired: true,
    itemName: "idcard",
    itemLabel: "身份证号",
    itemPlaceHolder: "请输入身份证号",
    itemMessage: "身份证号不能为空",
  },
  {
    id: "baseInfo" + 3,
    itemState: "base",
    isRequired: true,
    itemName: "phone",
    itemLabel: "联系电话",
    itemPlaceHolder: "请输入联系电话",
    itemMessage: "联系电话不能为空",
  },
];
const educationInfo = [
  {
    id: "educationInfo" + 0,
    itemState: "lowBase",
    itemTitle: "学历信息",
  },
  {
    id: "educationInfo" + 1,
    itemState: "baseClick",
    isRequired: true,
    itemName: "education_id",
    itemLabel: "学历",
    itemPlaceHolder: "请输入学历",
    itemMessage: "学历不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "education",
    isStarRequired: "*",
  },
  {
    id: "educationInfo" + 2,
    itemState: "base",
    isRequired: true,
    itemName: "graduate_school",
    itemLabel: "毕业院校",
    itemPlaceHolder: "请输入毕业院校",
    itemMessage: "毕业院校不能为空",
  },
  {
    id: "educationInfo" + 3,
    itemState: "baseImg",
    isRequired: true,
    itemName: "education",
    itemLabel: "毕业证书",
    itemPlaceHolder: "请输入毕业证书",
    itemMessage: "毕业证书不能为空",
    isStarRequired: "*",
    itemImgTitle: "最多可上传4张图片",
  },
];
const educationInfo2 = [
  {
    id: "educationInfo" + 0,
    itemState: "lowBase",
    itemTitle: "学历信息",
  },
  {
    id: "educationInfo" + 1,
    itemState: "baseClick",
    isRequired: true,
    itemName: "education_id",
    itemLabel: "学历",
    itemPlaceHolder: "请输入学历",
    itemMessage: "学历不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "education",
    isStarRequired: "*",
  },
  {
    id: "educationInfo" + 2,
    itemState: "base",
    isRequired: true,
    itemName: "finish_school",
    itemLabel: "毕业院校",
    itemPlaceHolder: "请输入毕业院校",
    itemMessage: "毕业院校不能为空",
  },
  {
    id: "educationInfo" + 3,
    itemState: "baseImg",
    isRequired: true,
    itemName: "diploma",
    itemLabel: "毕业证书",
    itemPlaceHolder: "请输入毕业证书",
    itemMessage: "毕业证书不能为空",
    isStarRequired: "*",
    itemImgTitle: "最多可上传4张图片",
  },
];

const qualificationInfo2 = [
  {
    id: "qualificationInfo" + 0,
    itemState: "lowBase",
    itemTitle: "资质信息",
  },
  {
    id: "qualificationInfo" + 1,
    itemState: "base",
    isRequired: true,
    itemName: "certification",
    itemLabel: "资质证书",
    itemPlaceHolder: "请输入资质证书",
    itemMessage: "资质证书不能为空",
  },
  {
    id: "qualificationInfo" + 2,
    itemState: "baseClick",
    isRequired: true,
    itemName: "certification_time",
    itemLabel: "资质发放日期",
    itemPlaceHolder: "请输入资质发放日期",
    itemMessage: "资质发放日期不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "isTimerPicker",
    isStarRequired: "*",
  },
  {
    id: "qualificationInfo" + 3,
    itemState: "baseImg",
    isRequired: true,
    itemName: "certification_img",
    itemLabel: "资质证明",
    itemPlaceHolder: "请输入资质证明",
    itemMessage: "资质证明不能为空",
    isStarRequired: "*",
    itemImgTitle: "最多可上传4张图片",
  },
];
const entryCompanyInfo = [
  {
    id: "entryCompanyInfo" + 0,
    itemState: "lowBase",
    itemTitle: "入职信息",
  },
  {
    id: "entryCompanyInfo" + 1,
    itemState: "base",
    isRequired: true,
    itemName: "entry_company",
    itemLabel: "入职公司",
    itemPlaceHolder: "请输入入职公司",
    itemMessage: "入职公司不能为空",
  },
  {
    id: "entryCompanyInfo" + 2,
    itemState: "baseClick",
    isRequired: true,
    itemName: "entry_time",
    itemLabel: "入职时间",
    itemPlaceHolder: "请输入入职时间",
    itemMessage: "入职时间不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "isTimerPicker1",
    isStarRequired: "*",
  },
  {
    id: "entryCompanyInfo" + 3,
    itemState: "baseClick",
    isRequired: true,
    itemName: "entry_id",
    itemLabel: "入职岗位",
    itemPlaceHolder: "请输入入职岗位",
    itemMessage: "入职岗位不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "entryPosition",
    isStarRequired: "*",
  },
  {
    id: "entryCompanyInfo" + 4,
    itemState: "base",
    isRequired: true,
    itemName: "company_phone",
    itemLabel: "公司联系电话",
    itemPlaceHolder: "请输入公司联系电话",
    itemMessage: "公司联系电话不能为空",
  },
  {
    id: "entryCompanyInfo" + 5,
    itemState: "baseClick",
    isRequired: true,
    itemName: "contract_years_id",
    itemLabel: "合同签订年限",
    itemPlaceHolder: "请输入合同签订年限",
    itemMessage: "合同签订年限不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "rentalType",
    isStarRequired: "*",
  },
  {
    id: "entryCompanyInfo" + 6,
    itemState: "baseImg",
    isRequired: true,
    itemName: "contract_img",
    itemLabel: "劳动合同/协议",
    itemPlaceHolder: "请输入劳动合同/协议",
    itemMessage: "劳动合同/协议不能为空",
    isStarRequired: "*",
    itemImgTitle: "最多可上传4张图片",
  },
];
const workInfo = [
  {
    id: "workInfo" + 0,
    itemState: "lowBase",
    itemTitle: "工作信息",
  },
  {
    id: "workInfo" + 1,
    itemState: "baseClick",
    isRequired: true,
    itemName: "job_years_id",
    itemLabel: "工作年限",
    itemPlaceHolder: "请选择工作年限",
    itemMessage: "工作年限不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "salaryRequire",
    isStarRequired: "*",
  },
];
const applyTypeInfo = [
  {
    id: "applyTypeInfo" + 0,
    itemState: "lowBase",
    itemTitle: "申请类型信息",
  },
  {
    id: "applyTypeInfo" + 1,
    itemState: "baseClick",
    isRequired: true,
    itemName: "apply_type_id",
    itemLabel: "申请类型",
    itemPlaceHolder: "请选择租房类型",
    itemMessage: "租房类型不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "rentalType",
    isStarRequired: "*",
  },
];
const rentInfo = [
  {
    id: "rentInfo" + 0,
    itemState: "lowBase",
    itemTitle: "租房信息",
  },
  {
    id: "rentInfo" + 1,
    itemState: "baseClick",
    isRequired: true,
    itemName: "detailed_address",
    itemLabel: "地址",
    itemPlaceHolder: "请选择地址",
    itemMessage: "地址不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "workCity",
    isStarRequired: "*",
  },
  {
    id: "rentInfo" + 2,
    itemState: "baseClick",
    isRequired: true,
    itemName: "start_time",
    itemLabel: "租赁时间起",
    itemPlaceHolder: "请选择开始时间",
    itemMessage: "开始时间不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "isTimerPicker",
    isStarRequired: "*",
  },
  {
    id: "rentInfo" + 3,
    itemState: "baseClick",
    isRequired: true,
    itemName: "end_time",
    itemLabel: "租赁时间结束",
    itemPlaceHolder: "请选择结束时间",
    itemMessage: "结束时间不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "isTimerPicker1",
    isStarRequired: "*",
  },
];
const competitionInfo = [
  {
    id: "competitionInfo" + 0,
    itemState: "lowBase",
    itemTitle: "竞赛信息",
  },
  {
    id: "competitionInfo" + 1,
    itemState: "base",
    isRequired: true,
    itemName: "item_name",
    itemLabel: "竞赛完整项目名",
    itemPlaceHolder: "请输入项目名",
    itemMessage: "项目名不能为空",
  },
  {
    id: "competitionInfo" + 2,
    itemState: "base",
    isRequired: true,
    itemName: "win_awards",
    itemLabel: "获得奖项",
    itemPlaceHolder: "请输入获得奖项",
    itemMessage: "获得奖项不能为空",
  },
];

const prizeInfo2 = [
  {
    id: "qualificationInfo" + 0,
    itemState: "lowBase",
    itemTitle: "奖项信息",
  },
  {
    id: "qualificationInfo" + 1,
    itemState: "base",
    isRequired: true,
    itemName: "certification",
    itemLabel: "奖项证书",
    itemPlaceHolder: "请输入奖项证书",
    itemMessage: "奖项证书不能为空",
    itemTrigger: "onConfirm",
    // pickerStatus: "prizeCertificate",
  },
  {
    id: "qualificationInfo" + 2,
    itemState: "baseClick",
    isRequired: true,
    itemName: "certification_time",
    itemLabel: "奖项发放日期",
    itemPlaceHolder: "请输入奖项发放日期",
    itemMessage: "奖项发放日期不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "isTimerPicker",
    isStarRequired: "*",
  },
  {
    id: "qualificationInfo" + 3,
    itemState: "baseImg",
    isRequired: true,
    itemName: "certification_img",
    itemLabel: "奖项证明",
    itemPlaceHolder: "请输入奖项证明",
    itemMessage: "奖项证明不能为空",
    isStarRequired: "*",
    itemImgTitle: "最多可上传4张图片",
  },
];
const trainInfo = [
  {
    id: "competitionInfo" + 0,
    itemState: "lowBase",
    itemTitle: "培训信息",
  },
  {
    id: "competitionInfo" + 1,
    itemState: "base",
    isRequired: true,
    itemName: "item_name",
    itemLabel: "培训机构全称",
    itemPlaceHolder: "请输入培训机构名",
    itemMessage: "培训机构名不能为空",
  },
  {
    id: "competitionInfo" + 2,
    itemState: "base",
    isRequired: true,
    itemName: "win_awards",
    itemLabel: "培训课程全称",
    itemPlaceHolder: "请输入培训课程",
    itemMessage: "培训课程不能为空",
  },
];
const certficateInfo = [
  {
    id: "certficateInfo" + 0,
    itemState: "lowBase",
    itemTitle: "证书信息",
  },
  {
    id: "certficateInfo" + 1,
    itemState: "base",
    isRequired: true,
    itemName: "certification",
    itemLabel: "培训结业证书",
    itemPlaceHolder: "请输入培训结业证书",
    itemMessage: "培训结业证书不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "windUpCertificate",
  },
  {
    id: "certficateInfo" + 2,
    itemState: "baseClick",
    isRequired: true,
    itemName: "certification_time",
    itemLabel: "证书发放日期",
    itemPlaceHolder: "请输入证书发放日期",
    itemMessage: "证书发放日期不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "isTimerPicker",
    isStarRequired: "*",
  },
  {
    id: "certficateInfo" + 3,
    itemState: "baseImg",
    isRequired: true,
    itemName: "certification_img",
    itemLabel: "证书证明",
    itemPlaceHolder: "请输证书证明",
    itemMessage: "证书证明不能为空",
    isStarRequired: "*",
    itemImgTitle: "最多可上传4张图片",
  },
];
const employApplyInfo = [
  {
    id: "employApplyInfo" + 0,
    itemState: "lowBase",
    itemTitle: "基本信息",
  },
  {
    id: "employApplyInfo" + 1,
    itemState: "base",
    isRequired: true,
    itemName: "name",
    itemLabel: "单位名称",
    itemPlaceHolder: "请输入单位名称",
    itemMessage: "单位名称不能为空",
  },
  {
    id: "employApplyInfo" + 2,
    itemState: "base",
    isRequired: true,
    itemName: "register_funds",
    itemLabel: "注册资金",
    itemPlaceHolder: "请输入注册资金(万元)",
    itemMessage: "注册资金不能为空",
  },
  {
    id: "employApplyInfo" + 3,
    itemState: "base",
    isRequired: true,
    itemName: "register_address",
    itemLabel: "注册地址",
    itemPlaceHolder: "请输入注册地址",
    itemMessage: "注册地址不能为空",
  },
  {
    id: "employApplyInfo" + 4,
    itemState: "base",
    isRequired: true,
    itemName: "company_address",
    itemLabel: "经营地址",
    itemPlaceHolder: "请输入经营地址",
    itemMessage: "经营地址不能为空",
  },
  {
    id: "employApplyInfo" + 5,
    itemState: "base",
    isRequired: true,
    itemName: "run_scope",
    itemLabel: "经营范围",
    itemPlaceHolder: "请输入经营范围",
    itemMessage: "经营范围不能为空",
  },
  {
    id: "employApplyInfo" + 6,
    itemState: "base",
    isRequired: true,
    itemName: "legal_deputy",
    itemLabel: "法定代表人",
    itemPlaceHolder: "请输入法定代表人",
    itemMessage: "法定代表人不能为空",
  },
  {
    id: "employApplyInfo" + 7,
    itemState: "base",
    isRequired: true,
    itemName: "phone",
    itemLabel: "联系方式",
    itemPlaceHolder: "请输入联系方式",
    itemMessage: "联系方式不能为空",
  },
  {
    id: "employApplyInfo" + 8,
    itemState: "base",
    isRequired: true,
    itemName: "license_number",
    itemLabel: "许可证号",
    itemPlaceHolder: "请输入许可证号",
    itemMessage: "许可证号不能为空",
  },
  {
    id: "employApplyInfo" + 9,
    itemState: "base",
    isRequired: true,
    itemName: "issuance_time",
    itemLabel: "发证时间",
    itemPlaceHolder: "请输入发证时间",
    itemMessage: "发证时间不能为空",
  },
  {
    id: "employApplyInfo" + 10,
    itemState: "base",
    isRequired: true,
    itemName: "valid_time",
    itemLabel: "有效期",
    itemPlaceHolder: "请输入有效期",
    itemMessage: "有效期不能为空",
  },
  {
    id: "employApplyInfo" + 11,
    itemState: "lowBase",
    itemTitle: "机构信息",
  },
  {
    id: "employApplyInfo" + 12,
    itemState: "base",
    isRequired: true,
    itemName: "service_type",
    itemLabel: "服务类别",
    itemPlaceHolder: "请输入服务类别",
    itemMessage: "服务类别不能为空",
  },
  {
    id: "employApplyInfo" + 13,
    itemState: "base",
    isRequired: true,
    itemName: "acreage",
    itemLabel: "机构面积",
    itemPlaceHolder: "请输入面积(平方米)",
    itemMessage: "机构面积不能为空",
  },
  {
    id: "employApplyInfo" + 14,
    itemState: "base",
    isRequired: true,
    itemName: "consume",
    itemLabel: "消费水平",
    itemPlaceHolder: "请输入消费水平",
    itemMessage: "消费水平不能为空",
  },
  {
    id: "employApplyInfo" + 15,
    itemState: "base",
    isRequired: true,
    itemName: "describe",
    itemLabel: "机构描述",
    itemPlaceHolder: "请输入机构描述",
    itemMessage: "机构描述不能为空",
  },
  {
    id: "employApplyInfo" + 16,
    itemState: "baseImg",
    isRequired: true,
    itemName: "img_path",
    itemLabel: "机构图片",
    itemPlaceHolder: "请输入机构图片",
    itemMessage: "机构图片不能为空",
    isStarRequired: "*",
    itemImgTitle: "最多可上传4张图片",
  },
];
const studentsApplyInfo = [
  {
    id: "studentsApplyInfo" + 0,
    itemState: "base",
    isRequired: true,
    itemName: "name",
    itemLabel: "单位名称",
    itemPlaceHolder: "请输入单位名称",
    itemMessage: "单位名称不能为空",
  },
  {
    id: "studentsApplyInfo" + 1,
    itemState: "base",
    isRequired: true,
    itemName: "register_funds",
    itemLabel: "注册资金",
    itemPlaceHolder: "请输入注册资金(万元)",
    itemMessage: "注册资金不能为空",
  },
  {
    id: "studentsApplyInfo" + 2,
    itemState: "base",
    isRequired: true,
    itemName: "register_address",
    itemLabel: "注册地址",
    itemPlaceHolder: "请输入注册地址",
    itemMessage: "注册地址不能为空",
  },
  {
    id: "studentsApplyInfo" + 3,
    itemState: "base",
    isRequired: true,
    itemName: "company_address",
    itemLabel: "经营地址",
    itemPlaceHolder: "请输入经营地址",
    itemMessage: "经营地址不能为空",
  },
  {
    id: "studentsApplyInfo" + 4,
    itemState: "base",
    isRequired: true,
    itemName: "run_scope",
    itemLabel: "经营范围",
    itemPlaceHolder: "请输入经营范围",
    itemMessage: "经营范围不能为空",
  },
  {
    id: "studentsApplyInfo" + 5,
    itemState: "base",
    isRequired: true,
    itemName: "legal_deputy",
    itemLabel: "法定代表人",
    itemPlaceHolder: "请输入法定代表人",
    itemMessage: "法定代表人不能为空",
  },
  {
    id: "studentsApplyInfo" + 6,
    itemState: "base",
    isRequired: true,
    itemName: "phone",
    itemLabel: "联系方式",
    itemPlaceHolder: "请输入联系方式",
    itemMessage: "联系方式不能为空",
  },
  {
    id: "studentsApplyInfo" + 7,
    itemState: "base",
    isRequired: true,
    itemName: "license_number",
    itemLabel: "许可证号",
    itemPlaceHolder: "请输入许可证号",
    itemMessage: "许可证号不能为空",
  },
  {
    id: "studentsApplyInfo" + 8,
    itemState: "base",
    isRequired: true,
    itemName: "issuance_time",
    itemLabel: "发证时间",
    itemPlaceHolder: "请输入发证时间",
    itemMessage: "发证时间不能为空",
  },
  {
    id: "studentsApplyInfo" + 9,
    itemState: "base",
    isRequired: true,
    itemName: "valid_time",
    itemLabel: "有效期",
    itemPlaceHolder: "请输入有效期",
    itemMessage: "有效期不能为空",
  },
  {
    id: "studentsApplyInfo" + 10,
    itemState: "base",
    isRequired: true,
    itemName: "service_type",
    itemLabel: "培训类别",
    itemPlaceHolder: "请输入培训类别",
    itemMessage: "培训类别不能为空",
  },
  {
    id: "studentsApplyInfo" + 11,
    itemState: "base",
    isRequired: true,
    itemName: "acreage",
    itemLabel: "机构面积",
    itemPlaceHolder: "请输入面积(平方米)",
    itemMessage: "机构面积不能为空",
  },
];
const applyTrainInfo = [
  {
    id: "applyTrainInfo" + 0,
    itemState: "base",
    isRequired: true,
    itemName: "full_name",
    itemLabel: "姓名",
    itemPlaceHolder: "请输入姓名",
    itemMessage: "姓名不能为空",
  },
  {
    id: "applyTrainInfo" + 1,
    itemState: "base",
    isRequired: true,
    itemName: "age",
    itemLabel: "年龄",
    itemPlaceHolder: "请输入年龄",
    itemMessage: "年龄不能为空",
  },
  {
    id: "applyTrainInfo" + 2,
    itemState: "baseClickButtonSex",
    isRequired: true,
    // itemName: "sex",
    itemLabel: "性别",
    // itemPlaceHolder: "请输入性别",
    itemMessage: "性别不能为空",
  },
  {
    id: "applyTrainInfo" + 3,
    itemState: "base",
    isRequired: true,
    itemName: "phone",
    itemLabel: "联系电话",
    itemPlaceHolder: "请输入联系电话",
    itemMessage: "联系电话不能为空",
  },
  {
    id: "applyTrainInfo" + 4,
    itemState: "base",
    isRequired: true,
    itemName: "family_address",
    itemLabel: "家庭地址",
    itemPlaceHolder: "请输入家庭地址",
    itemMessage: "家庭地址不能为空",
  },
  {
    id: "applyTrainInfo" + 5,
    itemState: "baseClick",
    // isRequired: true,
    itemName: "cultivate_id",
    itemLabel: "培训学校",
    itemPlaceHolder: "请输入培训学校",
    itemMessage: "培训学校不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "trainSchool",
    isStarRequired: "*",
  },
  {
    id: "applyTrainInfo" + 6,
    itemState: "baseClick",
    // isRequired: true,
    itemName: "grade",
    itemLabel: "培训等级",
    itemPlaceHolder: "请输入培训等级",
    itemMessage: "培训等级不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "trainLevel",
    isStarRequired: "*",
  },
  {
    id: "applyTrainInfo" + 7,
    itemState: "base",
    isRequired: false,
    itemName: "certificate",
    itemLabel: "已有证书情况",
    itemPlaceHolder: "请输入已有证书情况",
    itemMessage: "已有证书情况不能为空",
  },
];
const recruiterInfo = [
  {
    id: "recruiterInfo" + 0,
    itemState: "lowBase",
    itemTitle: "招聘人信息",
  },
  {
    id: "recruiterInfo" + 1,
    itemState: "base",
    isRequired: false,
    itemName: "full_name",
    itemLabel: "姓名",
    itemPlaceHolder: "请输入姓名",
  },
  {
    id: "recruiterInfo" + 2,
    itemState: "base",
    isRequired: false,
    itemName: "idcard",
    itemLabel: "身份证",
    itemPlaceHolder: "请输入身份证",
  },
  {
    id: "recruiterInfo" + 3,
    itemState: "base",
    isRequired: false,
    itemName: "phone",
    itemLabel: "联系电话",
    itemPlaceHolder: "请输入联系电话",
  },
  {
    id: "recruiterInfo" + 4,
    itemState: "base",
    isRequired: false,
    itemName: "age",
    itemLabel: "年龄",
    itemPlaceHolder: "请输入年龄",
  },
  {
    id: "recruiterInfo" + 5,
    itemState: "base",
    isRequired: false,
    itemName: "connect_address",
    itemLabel: "联系地址",
    itemPlaceHolder: "请输入联系地址",
  },
  {
    id: "recruiterInfo" + 6,
    itemState: "baseDiv",
  },
];
const stationInfo = [
  {
    id: "stationInfo" + 0,
    itemState: "lowBase",
    itemTitle: "岗位信息",
  },
  {
    id: "stationInfo" + 1,
    itemState: "base",
    isRequired: false,
    itemName: "title",
    itemLabel: "岗位名称",
    itemPlaceHolder: "请输入岗位名称",
  },
  {
    id: "stationInfo" + 2,
    itemState: "cerReq",
    itemName: "cert_id",
    itemLabel: "证书要求",
    itemPlaceHolder: "请输入证书要求",
    itemTrigger: "onConfirm",
    // pickerStatus: "rentalType",
  },
  {
    id: "stationInfo" + 3,
    itemState: "minEdu",
    itemName: "min_education_id",
    itemLabel: "最低学历",
    itemPlaceHolder: "请输入最低学历",
    itemTrigger: "onConfirm",
    // pickerStatus: "education",
  },
  {
    id: "stationInfo" + 4,
    itemState: "expReq",
    itemName: "empiric_id",
    itemLabel: "经验要求",
    itemPlaceHolder: "请输入经验要求",
    itemTrigger: "onConfirm",
    pickerStatus: "entryPosition",
  },
  {
    id: "stationInfo" + 5,
    itemState: "baseTextArea",
    itemName: "job_text",
    itemLabel: "岗位描述",
    itemPlaceHolder: "请输入岗位描述",
  },
  {
    id: "stationInfo" + 6,
    // itemState: "baseClick",
    itemState: "twoSalary",
    itemName: "s6",
    itemLabel: "薪资范围",
    itemPlaceHolder: "请选择薪资范围",
    itemTrigger: "onConfirm",
    isRequired: true,
    entryPlaceHolder: "最低薪资", //wage_low
    finishPlaceHolder: "最高薪资", //wage_heigh
    isStarRequired: "*",
    itemMessage: "在职时间不能为空",
    pickerStatus: "expectPosition",
  },
  {
    id: "stationInfo" + 7,
    itemState: "baseTextArea",
    itemName: "wages_text",
    itemLabel: "薪资描述",
    itemPlaceHolder: "请输入薪资描述",
  },
  {
    id: "stationInfo" + 8,
    itemState: "baseDiv",
  },
];
const workEnvironmentInfo = [
  {
    id: "workEnvironmentInfo" + 0,
    itemState: "lowBase",
    itemTitle: "岗位信息",
  },
  {
    id: "workEnvironmentInfo" + 1,
    itemState: "baseImg",
    isRequired: true,
    itemName: "setting_img",
    itemLabel: "环境照片",
    isClickPicker: true,
    itemImgTitle: "最多可上传4张图片",
  },
  {
    id: "workEnvironmentInfo" + 2,
    itemState: "workCity",
    isRequired: false,
    itemName: "region_id",
    itemLabel: "工作地址",
    itemPlaceHolder: "请输入工作地址",
    itemTrigger: "onConfirm",
  },
  {
    id: "workEnvironmentInfo" + 3,
    itemState: "base",
    isRequired: false,
    itemName: "job_address",
    itemLabel: "详细地址",
    itemPlaceHolder: "请输入详细地址",
  },
];
const editGoWorkInfo = [
  {
    id: "editGoWorkInfo" + 1,
    itemState: "base",
    isRequired: true,
    itemName: "company_name",
    itemLabel: "工作单位",
    itemPlaceHolder: "请输入工作单位",
    itemMessage: "工作单位不能为空",
  },
  {
    id: "editGoWorkInfo" + 2,
    itemState: "base",
    itemName: "trade_name",
    itemLabel: "所在行业",
    itemPlaceHolder: "请输入所在行业",
  },
  {
    id: "editGoWorkInfo" + 3,
    itemState: "base",
    isRequired: true,
    itemName: "office_name",
    itemLabel: "职位名称",
    itemPlaceHolder: "请输入职位名称",
    itemMessage: "职位名称不能为空",
  },
  {
    id: "editGoWorkInfo" + 4,
    itemState: "basePro",
    isRequired: true,
    itemName: "e4",
    itemLabel: "在职时间",
    itemPlaceHolder: "入职时间",
    entryPlaceHolder: "入职时间",
    finishPlaceHolder: "离职时间",
    isStarRequired: "*",
    itemMessage: "在职时间不能为空",
  },
  {
    id: "editGoWorkInfo" + 5,
    itemState: "baseTextArea",
    itemName: "job_content",
    itemLabel: "工作内容",
    itemPlaceHolder: "请输入工作内容",
  },
];
const editHealthInfo = [
  {
    id: "editHealthInfo" + 0,
    itemState: "base",
    isRequired: false,
    itemName: "height",
    itemLabel: "身高",
    itemPlaceHolder: "请输入身高",
  },
  {
    id: "editHealthInfo" + 1,
    itemState: "base",
    isRequired: false,
    itemName: "weight",
    itemLabel: "体重",
    itemPlaceHolder: "请输入体重",
  },
  {
    id: "editHealthInfo" + 2,
    itemState: "base",
    isRequired: true,
    itemName: "chronic",
    itemLabel: "慢性病情况",
    itemPlaceHolder: "请输入慢性病情况",
    itemMessage: "慢性病情况不能为空",
  },
  {
    id: "editHealthInfo" + 3,
    itemState: "baseImg",
    isRequired: true,
    itemName: "file_img",
    itemLabel: "上传健康检查文件",
    isClickPicker: true,
    itemImgTitle: "最多可上传4张图片",
  },
];
const editSkillFileInfo = [
  {
    id: "editSkillFileInfo" + 2,
    itemState: "base",
    isRequired: true,
    itemName: "skill_name",
    itemLabel: "技能档案",
    itemPlaceHolder: "请输入技能档案",
  },
  {
    id: "editSkillFileInfo" + 3,
    itemState: "baseImg",
    isRequired: true,
    itemName: "skill_img",
    itemLabel: "上传技能证书图片",
    isClickPicker: true,
    itemImgTitle: "最多可上传4张图片",
  },
];
const editSearchWorkInfo = [
  {
    id: "editSearchWorkInfo" + 0,
    itemState: "baseClickButtonSex",
    isRequired: false,
    // itemName: "a2",
    itemLabel: "求职类型",
    // itemPlaceHolder: "请输入求职类型",
    itemMessage: "求职类型不能为空",
  },
  {
    id: "editSearchWorkInfo" + 1,
    itemState: "baseClick",
    isRequired: false,
    itemName: "s1",
    itemLabel: "工作城市",
    itemPlaceHolder: "请输入工作城市",
    itemMessage: "工作城市不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "workCity",
  },
  {
    id: "editSearchWorkInfo" + 2,
    itemState: "baseClick",
    isRequired: false,
    itemName: "s2",
    itemLabel: "期望职位",
    itemPlaceHolder: "请输入期望职位",
    itemMessage: "期望职位不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "education",
  },
  {
    id: "editSearchWorkInfo" + 3,
    itemState: "baseClick",
    isRequired: false,
    itemName: "s3",
    itemLabel: "薪资要求",
    itemPlaceHolder: "请输入薪资要求",
    itemMessage: "薪资要求不能为空",
    itemTrigger: "onConfirm",
    pickerStatus: "salaryRequire",
  },
];
const myInfo = [
  {
    id: "myInfo" + 0,
    itemState: "baseDiv",
  },
  {
    id: "myInfo" + 1,
    itemState: "base",
    isRequired: true,
    itemName: "full_name",
    itemLabel: "姓名",
    itemPlaceHolder: "请输入姓名",
    itemMessage: "姓名不能为空",
  },
  {
    id: "myInfo" + 2,
    itemState: "base",
    isRequired: true,
    itemName: "idcard",
    itemLabel: "身份证号",
    itemPlaceHolder: "请输入身份证号",
    itemMessage: "身份证号不能为空",
  },
  {
    id: "myInfo" + 3,
    itemState: "baseClickButtonSex",
    isRequired: true,
    itemName: "sex",
    itemLabel: "性别",
    // itemPlaceHolder: "请输入性别",
    itemMessage: "性别不能为空",
  },
  {
    id: "myInfo" + 4,
    itemState: "base",
    isRequired: true,
    itemName: "age",
    itemLabel: "年龄",
    itemPlaceHolder: "请输入年龄",
    itemMessage: "年龄不能为空",
  },
  {
    id: "myInfo" + 5,
    itemState: "baseClickEducation",
    isRequired: true,
    itemName: "education_id",
    itemLabel: "学历",
    itemPlaceHolder: "请输入学历",
    itemMessage: "学历不能为空",
    pickerStatus: "education",
  },
  {
    id: "myInfo" + 6,
    itemState: "base",
    isRequired: true,
    itemName: "graduate_school",
    itemLabel: "毕业院校",
    itemPlaceHolder: "请输入毕业院校",
    itemMessage: "毕业院校不能为空",
  },
  {
    id: "myInfo" + 7,
    itemState: "baseClickSpecial",
    isRequired: true,
    itemName: "graduate_time",
    itemLabel: "毕业时间",
    itemPlaceHolder: "请输入毕业时间",
    itemMessage: "毕业时间不能为空",
    pickerStatus: "isTimerPicker",
  },
  {
    id: "myInfo" + 8,
    itemState: "base",
    isRequired: true,
    itemName: "phone",
    itemLabel: "联系电话",
    itemPlaceHolder: "请输入联系电话",
    itemMessage: "联系电话不能为空",
  },
  {
    id: "myInfo" + 9,
    itemState: "myInfoTextarea",
    isRequired: true,
    itemName: "contact_address",
    itemLabel: "联系地址",
    itemPlaceHolder: "请输入联系地址",
    itemMessage: "联系地址不能为空",
  },
  {
    id: "myInfo" + 10,
    itemState: "addEdit",
    itemType: "workExperience",
    isRequired: true,
    itemName: "m10",
    itemLabel: "就业经历",
    itemText: "新增就业经历",
    // itemPlaceHolder: "请输入就业经历",
    itemMessage: "就业经历不能为空",
    isStarRequired: "*",
    itemJumpRouter: "/editgoworkexperience",
  },
  {
    id: "myInfo" + 11,
    itemState: "addEdit",
    itemType: "healthExperience",
    itemStyle: "distance",
    isRequired: true,
    itemName: "m10",
    itemLabel: "健康档案",
    itemText: "新增健康档案",
    // itemPlaceHolder: "请输入健康档案",
    itemMessage: "健康档案不能为空",
    isStarRequired: "*",
    itemJumpRouter: "/edithealthrecords",
  },
  {
    id: "myInfo" + 12,
    itemState: "addEdit",
    itemType: "skillfile",
    itemStyle: "distance",
    isRequired: true,
    itemName: "m10",
    itemLabel: "技能档案",
    itemText: "新增技能档案",
    // itemPlaceHolder: "请输入技能档案",
    itemMessage: "技能档案不能为空",
    isStarRequired: "*",
    itemJumpRouter: "/editskillfile",
  },
  //  模拟数据11111111111
  // {
  //   id: "myInfo" + 110,
  //   itemState: "showedit",
  //   itemType: "workExperience",
  //   isRequired: true,
  //   itemName: "m10",
  //   itemLabel: "就业经历",
  //   itemText: "新增就业经历",
  //   // itemPlaceHolder: "请输入就业经历",
  //   itemMessage: "就业经历不能为空",
  //   isStarRequired: "*",
  //   itemJumpRouter: "/editgoworkexperience",
  // },
  // {
  //   id: "myInfo" + 111,
  //   itemState: "showedit",
  //   itemType: "healthExperience",
  //   itemStyle: "distance",
  //   isRequired: true,
  //   itemName: "m10",
  //   itemLabel: "健康档案",
  //   itemText: "新增健康档案",
  //   // itemPlaceHolder: "请输入健康档案",
  //   itemMessage: "健康档案不能为空",
  //   isStarRequired: "*",
  //   itemJumpRouter: "/edithealthrecords",
  // },
  // {
  //   id: "myInfo" + 112,
  //   itemState: "showedit",
  //   itemType: "skillfile",
  //   itemStyle: "distance",
  //   isRequired: true,
  //   itemName: "m10",
  //   itemLabel: "技能档案",
  //   itemText: "新增技能档案",
  //   // itemPlaceHolder: "请输入技能档案",
  //   itemMessage: "技能档案不能为空",
  //   isStarRequired: "*",
  //   itemJumpRouter: "/editskillfile",
  // },
];
const myResume = [
  // {
  //   id: "myResume" + 0,
  //   itemState: "baseDiv",
  // },
  {
    id: "myResume" + 1,
    itemState: "base",
    // isRequired: true,
    itemName: "full_name",
    itemLabel: "姓名",
    itemPlaceHolder: "暂无数据",
    itemMessage: "姓名不能为空",
  },
  {
    id: "myResume" + 2,
    itemState: "base",
    // isRequired: true,
    itemName: "idcard",
    itemLabel: "身份证号",
    itemPlaceHolder: "暂无数据",
    itemMessage: "身份证号不能为空",
  },
  {
    id: "myResume" + 3,
    itemState: "baseSex",
    // isRequired: true,
    itemName: "sex",
    itemLabel: "性别",
    itemPlaceHolder: "暂无数据",
    itemMessage: "性别不能为空",
  },
  {
    id: "myResume" + 4,
    itemState: "base",
    // isRequired: true,
    itemName: "age",
    itemLabel: "年龄",
    itemPlaceHolder: "暂无数据",
    itemMessage: "年龄不能为空",
  },
  {
    id: "myResume" + 5,
    itemState: "base",
    // isRequired: true,
    itemName: "education_name",
    itemLabel: "学历",
    itemPlaceHolder: "暂无数据",
    itemMessage: "学历不能为空",
  },
  {
    id: "myResume" + 6,
    itemState: "base",
    // isRequired: true,
    itemName: "graduate_school",
    itemLabel: "毕业院校",
    itemPlaceHolder: "暂无数据",
    itemMessage: "毕业院校不能为空",
  },
  {
    id: "myResume" + 7,
    itemState: "base",
    // isRequired: true,
    itemName: "graduate_time",
    itemLabel: "毕业时间",
    itemPlaceHolder: "暂无数据",
    itemMessage: "毕业时间不能为空",
  },
  {
    id: "myResume" + 8,
    itemState: "base",
    // isRequired: true,
    itemName: "phone",
    itemLabel: "联系电话",
    itemPlaceHolder: "暂无数据",
    itemMessage: "联系电话不能为空",
  },
  // {
  //   id: "myResume" + 9,
  //   itemState: "myResumeTextarea",
  //   // isRequired: true,
  //   itemName: "m9",
  //   itemLabel: "联系地址",
  //   itemPlaceHolder: "暂无数据",
  //   itemMessage: "联系地址不能为空",
  // },
  {
    id: "myResume" + 10,
    itemState: "ResumeShow",
    itemType: "workExperience",
    itemStyle: "distance",
    // isRequired: true,
    itemName: "m10",
    itemLabel: "就业经历",
    itemText: "新增就业经历",
    // itemPlaceHolder: "请输入就业经历",
    itemMessage: "就业经历不能为空",
    isStarRequired: "*",
    itemJumpRouter: "/editgoworkexperience",
  },
  // {
  //   id: "myResume" + 11,
  //   itemState: "ResumeShow",
  //   itemType: "healthExperience",
  //   itemStyle: "distance",
  //   // isRequired: true,
  //   itemName: "m10",
  //   itemLabel: "健康档案",
  //   itemText: "新增健康档案",
  //   // itemPlaceHolder: "请输入健康档案",
  //   itemMessage: "健康档案不能为空",
  //   isStarRequired: "*",
  //   itemJumpRouter: "/edithealthrecords",
  // },
  {
    id: "myResume" + 12,
    itemState: "ResumeShow",
    itemType: "skillfile",
    itemStyle: "distance",
    // isRequired: true,
    itemName: "m10",
    itemLabel: "技能档案",
    itemText: "新增技能档案",
    // itemPlaceHolder: "请输入技能档案",
    itemMessage: "技能档案不能为空",
    isStarRequired: "*",
    itemJumpRouter: "/editskillfile",
  },
  {
    id: "myResume" + 13,
    itemState: "searchWork",
    itemType: "searchWork",
    itemStyle: "distance",
    // isRequired: true,
    itemName: "m10",
    itemLabel: "求职意向",
    itemText: "新增求职意向",
    // itemPlaceHolder: "请输入技能档案",
    itemMessage: "技能档案不能为空",
    isStarRequired: "*",
    itemJumpRouter: "/editsearchwork",
  },
  //  模拟数据11111111111
  // {
  //   id: "myResume" + 110,
  //   itemState: "showedit",
  //   itemType: "workExperience",
  //   isRequired: true,
  //   itemName: "m10",
  //   itemLabel: "就业经历",
  //   itemText: "新增就业经历",
  //   // itemPlaceHolder: "请输入就业经历",
  //   itemMessage: "就业经历不能为空",
  //   isStarRequired: "*",
  //   itemJumpRouter: "/editgoworkexperience",
  // },
  // {
  //   id: "myResume" + 111,
  //   itemState: "showedit",
  //   itemType: "healthExperience",
  //   itemStyle: "distance",
  //   isRequired: true,
  //   itemName: "m10",
  //   itemLabel: "健康档案",
  //   itemText: "新增健康档案",
  //   // itemPlaceHolder: "请输入健康档案",
  //   itemMessage: "健康档案不能为空",
  //   isStarRequired: "*",
  //   itemJumpRouter: "/edithealthrecords",
  // },
  // {
  //   id: "myResume" + 112,
  //   itemState: "showedit",
  //   itemType: "skillfile",
  //   itemStyle: "distance",
  //   isRequired: true,
  //   itemName: "m10",
  //   itemLabel: "技能档案",
  //   itemText: "新增技能档案",
  //   // itemPlaceHolder: "请输入技能档案",
  //   itemMessage: "技能档案不能为空",
  //   isStarRequired: "*",
  //   itemJumpRouter: "/editskillfile",
  // },
];

export const infoTableItemData = {
  // 测试数据
  educationInfoForm: test,
  // 入职奖补
  entryAwardForm: baseInfo
    .concat(educationInfo2)
    .concat(qualificationInfo2)
    .concat(entryCompanyInfo)
    .concat(workInfo),
  // 岗位奖补
  stationAwardForm: baseInfo
    .concat(educationInfo2)
    .concat(qualificationInfo2)
    .concat(entryCompanyInfo)
    .concat(workInfo),
  // 人才奖补
  talentAwardForm: baseInfo
    .concat(educationInfo2)
    .concat(entryCompanyInfo)
    .concat(workInfo),
  // 租购房
  rentPurchaseAwardForm: applyTypeInfo.concat(baseInfo).concat(rentInfo),
  // 竞赛奖励
  competitionAwardForm: competitionInfo.concat(baseInfo).concat(prizeInfo2),
  // 等级鉴定
  rankawardForm: baseInfo
    .concat(educationInfo)
    .concat(qualificationInfo2)
    .concat(entryCompanyInfo)
    .concat(workInfo),
  // c测试
  infoTableItemData: baseInfo,
  // 培训鉴定
  trainAwardForm: trainInfo.concat(baseInfo).concat(certficateInfo),
  // 招聘申请
  employApplyForm: employApplyInfo,
  // 招生申请
  studentsApplyForm: studentsApplyInfo,
  // 培训申请
  applyTrainForm: applyTrainInfo,
  // 需求发布
  demandReleaseForm: recruiterInfo
    .concat(stationInfo)
    .concat(workEnvironmentInfo),
  // 编辑就业经历
  editGoWorkExperienceForm: editGoWorkInfo,
  // 编辑健康档案
  editHealthRecordsForm: editHealthInfo,
  // 编辑技能档案
  editSkillFileForm: editSkillFileInfo,
  // 编辑求职期望
  editSearchWorkForm: editSearchWorkInfo,
  // 我的信息myInfoForm
  myInfoForm: myInfo,
  // 我的信息myResumeForm
  myResumeForm: myResume,
};
