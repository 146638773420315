import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Modal, ImageUploader, Dialog } from "antd-mobile";
import { enrollDetailsApi, download } from "../../../services/index";
import "./index.less";

function AdmissionTicket() {
  const { state } = useLocation();
  const [sourse, setSourse] = useState();
  const [visible, setVisible] = useState(false);
  const [fileList, setFileList] = useState();
  useEffect(() => {
    api();
    showImage();
  }, []);
  const id = state?.id;
  const api = async () => {
    let res = await enrollDetailsApi(id);
    setSourse(res?.data);
  };

  const showImage = async () => {
    let res = await download(id);
    if (res.code === 1) {
      setFileList([
        {
          url: res?.data?.compose_img,
        },
      ]);
    }
  };

  return (
    <div className="admissionTicket">
      <div className="ticketBox">
        <div className="ticketInner">
          <span className="ticketTitle">姓名：</span>
          <span className="ticketConent">{sourse?.full_name}</span>
        </div>
        <div className="ticketInner">
          <span className="ticketTitle">培训学校：</span>
          <span className="ticketConent">{sourse?.cultivate_name}</span>
        </div>
        <div className="ticketInner">
          <span className="ticketTitle">考生号：</span>
          <span className="ticketConent">{sourse?.card_number}</span>
        </div>
        <div className="ticketInner">
          <span className="ticketTitle">考场：</span>
          <span className="ticketConent">{sourse?.place_name}</span>
        </div>
        <div className="ticketInner">
          <span className="ticketTitle">座号：</span>
          <span className="ticketConent">{sourse?.seat_num}</span>
        </div>
        <div className="ticketInner">
          <span className="ticketTitle">考试时间：</span>
          <span className="ticketConent">
            {sourse?.user_subject?.map((i) => (
              <span key={i?.id} className="ticketConentinner">
                {i?.day_time_name}&nbsp;
                {i?.time_name}&nbsp;
                {i?.start_time_name}~{i?.end_time_name}
                <br />
                {i?.subject_type_name}
              </span>
            ))}
          </span>
        </div>
        <div className="ticketInner">
          <span className="ticketTitle">注意事项：</span>
          <span className="ticketConentTips ticketConent">{sourse?.note}</span>
        </div>
      </div>
      <div className="ticketBottom flexBoxCenter">
        <span className="downloadTicket">下载准考证</span>
        <div
          className={
            sourse?.is_up_score === 2 ? "overlayContent" : "overlaynone"
          }
        >
          <ImageUploader value={fileList} deletable={false} />
        </div>
        {sourse?.is_up_score === 2 ? (
          <span
            className="scoreQuery"
            onClick={() => {
              setVisible(true);
            }}
          >
            成绩查询
          </span>
        ) : (
          ""
        )}
      </div>
      <Modal
        visible={visible}
        content={
          <>
            {sourse?.user_subject?.map((i) => (
              <div key={i?.id} className="subject">
                <span className="subjectTitle">{i?.subject_type_name}：</span>
                <span className="subjectScore">{i?.score}</span>
                <span className="subjectUnit">分</span>
              </div>
            ))}
          </>
        }
        onConfirm={async () => {
          setVisible(false);
        }}
        closeOnAction
        onClose={() => {
          setVisible(false);
        }}
        actions={[
          {
            key: "confirm",
            text: "确定",
          },
        ]}
      />
    </div>
  );
}

export default AdmissionTicket;
