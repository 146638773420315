import React from "react";
import "./index.less";

function InfoTableHeader() {
  return (
    <div className="infoTableHeader">
      <div className="infoTableHeaderTitle">申请信息表</div>
      <div className="infoRemind">
        <span className="infoRemindAsterisk">*</span>
        <span className="infoRemindContent">
          信息正确性会对申请结果产生影响，请认真填写
        </span>
      </div>
    </div>
  );
}

export default InfoTableHeader;
