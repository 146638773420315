import React, { useState, useEffect } from "react";
import { Dialog } from "antd-mobile";
import { useNavigate } from "react-router-dom";
// import TwoButton from "../../../components/TwoButton";
import { twoButtonData } from "../../../utils/allTwoButton";
import InfoTableHeader from "../../../components/InfoTableHeader";
import InfoTableItem from "../../../components/InfoTableItem";
import { infoTableItemData } from "../../../utils/allInfoTableItem";
import { trainApplyApi, myTrainStatus } from "../../../services/index";
import "./index.less";

function ApplyTrain() {
  const navigate = useNavigate();
  const [schoolId, setSchoolId] = useState();
  const [gradeId, setGradeId] = useState();
  const [trainSchoolColumns, setTrainSchoolColumns] = useState([]);
  const [trainLevelColumns, setTrainLevelColumns] = useState([]);
  useEffect(() => {
    fn();
  }, []);
  const fn = async () => {
    let res = await myTrainStatus();
    var newCultivate = res.data.cultivate.map((item) => {
      item.label = item.name;
      item.value = item.id;
      return item;
    });
    setTrainSchoolColumns(newCultivate);
  };

  const getTrainSchool = (v) => {
    setSchoolId(v[0]);
    var temp = trainSchoolColumns.find((item) => {
      return item?.value === v[0];
    });

    temp?.grade?.map((item) => {
      item.label = item?.grade_name;
      item.value = item?.grade;
      return item;
    });
    setTrainLevelColumns(temp?.grade);
  };
  const getTrainLevel = (v) => {
    setGradeId(v[0]);
  };

  const buttonValue = twoButtonData?.sexType;
  const [buttonsValue, setButtonsValue] = useState(1);
  const getGetCheck = (paramsValue) => {
    setButtonsValue(paramsValue);
  };
  const addData = {
    sex: buttonsValue,
    cultivate_id: schoolId,
    grade: gradeId,
  };
  const onFinishApplyTrain = async (values) => {
    let res = await trainApplyApi({ ...values, ...addData });
    if (res.code === 1) {
      Dialog.alert({
        content: "提交成功返回首页",
        onConfirm: () => {
          navigate("/assistant");
        },
      });
    }
  };
  return (
    <>
      <div className="applyTrain">
        <InfoTableHeader />
        <InfoTableItem
          FormItemData={infoTableItemData?.applyTrainForm}
          trainLevelColumns={[trainLevelColumns]}
          trainSchoolColumns={[trainSchoolColumns]}
          getGetCheck={getGetCheck}
          twoButtonValues={buttonValue}
          getTrainSchool={getTrainSchool}
          getTrainLevel={getTrainLevel}
          buttonsValue={buttonsValue}
          // defaultValue={buttonValue.checkValue}
          onFinish={onFinishApplyTrain}
        />
      </div>
    </>
  );
}

export default ApplyTrain;
