import React, { useState, useEffect } from "react";
import {
  Toast,
  Form,
  Input,
  Button,
  TextArea,
  Dialog,
  ImageUploader,
  Space,
  Picker,
  DatePicker,
} from "antd-mobile";
import { DeleteOutline } from "antd-mobile-icons";
import moment from "moment";
import dayjs from "dayjs";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import TwoButton from "../../../components/TwoButton";
import Upload from "../../../image/formImg/upload.png";
import Add from "../../../image/myInfoImg/add.png";
import HeadUpload from "../../../image/myInfoImg/headUpload.png";
import { twoButtonData } from "../../../utils/allTwoButton";
import { GetYear } from "../../../utils/method.js";
import {
  getMyInfoApi,
  infoSubmit,
  pictureUpload,
  pictureUploadBase,
} from "../../../services/index";
import "./index.less";

function MyInfo() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [res, setRes] = useState({});
  const [vip_info, setVipInfo] = useState({});
  const [buttonsValue, setButtonsValue] = useState(1);
  const [echoIsTimerPicker, setEchoIsTimerPicker] = useState("");
  const [echoEducation, setEchoEducation] = useState("");
  const [educationValue, setEducationValue] = useState("");
  const [timerValue, setTimerValue] = useState("");
  const [undergo, setUndergo] = useState([]);
  const [avatar, setAvatar] = useState("");
  const [educationShow, setEducationShow] = useState(false); //教育下拉
  const [pickerVisible, setPickerVisible] = useState(false); //毕业时间
  const [expDialogShow, setExpDialogShow] = useState(false);
  const [fileList, setFileList] = useState([]); //健康档案图片
  const [healthDialogShow, setHealthDialogShow] = useState(false);
  const [fileListSkill, setFileListSkill] = useState([]); //技能档案图片
  const [skillDialogShow, setSkillDialogShow] = useState(false);
  const [expDialogEditShow, setExpDialogEditShow] = useState(false); //编辑就业经历
  const [workData, setWorkData] = useState([]); //工作经历数据
  const [healthData, setHealthData] = useState({}); //健康档案
  const [skillData, setSkillData] = useState({}); //技能档案
  const [workId, setWorkId] = useState();
  useEffect(() => {
    fn();
  }, []);

  const now = new Date();
  const min = GetYear(-50);
  const fn = async () => {
    let res = await getMyInfoApi();
    const { data } = res;
    form.setFieldsValue({ ...data?.vip_info, phone: "", contact_address: "" });
    setRes(data);
    setVipInfo({ ...data?.vip_info });
    setButtonsValue(data?.vip_info?.sex);
    setEchoIsTimerPicker(data?.vip_info?.graduate_time);
    setEchoEducation(data?.vip_info?.education_name);
    setUndergo(data?.vip_info?.undergo || []);
    setAvatar(data?.vip_info?.headimgurl);
    setHealthData({
      height: data?.vip_info?.height,
      weight: data?.vip_info?.weight,
      chronic: data?.vip_info?.chronic,
      file_img: data?.vip_info?.file_img,
    });
    setSkillData({
      skill_name: data?.vip_info?.skill_name,
      skill_img: data?.vip_info?.skill_img,
    });
  };
  const educationColumns = res?.data_education ?? [];
  const buttonValue = twoButtonData?.sexType;
  const newEducationColumns = educationColumns?.map((item) => {
    item.label = item.name;
    item.value = item.id;
    return item;
  });
  // 关闭弹窗
  const onSubmitCalce = () => {
    setExpDialogShow(false);
    setHealthDialogShow(false);
    setSkillDialogShow(false);
    setExpDialogEditShow(false);
  };

  // 点击工作经历每一项
  const editWorkExp = async (param, e) => {
    setWorkId(param);
    e.stopPropagation(); //阻止事件冒泡
    var undered = undergo?.find((item) => {
      return item?.id === param;
    });
    if (undered) {
      form.setFieldsValue({ ...undered });
    }
    setExpDialogEditShow(true);
  };
  // 编辑工作经历
  const onSubmitExperienceEdit = (param) => {
    const values = form.getFieldsValue();
    if (!values.company_name) {
      Toast.show({
        content: "请输入工作单位",
      });
      return;
    } else if (!values.office_name) {
      Toast.show({
        content: "请输入职位名称",
      });
      return;
    } else if (!values.work_start_time) {
      Toast.show({
        content: "请输入开始时间",
      });
      return;
    } else if (!values.work_end_time) {
      Toast.show({
        content: "请输入结束时间",
      });
      return;
    } else {
      setExpDialogEditShow(false);
      let arr = {
        id: workId,
        company_name: values?.company_name,
        office_name: values?.office_name,
        trade_name: values?.trade_name,
        work_start_time: values?.work_start_time,
        work_end_time: values?.work_end_time,
        job_content: values?.job_content,
      };
      const newUndergo = undergo.map((item) => {
        if (item.id === arr.id) {
          item = arr;
        }
        return item;
      });
      setUndergo(newUndergo);
      // setWorkData(arr);
      // if (undergo === undefined) {
      //   setUndergo(arr);
      // } else {
      //   undergo?.unshift(arr[0]);
      //   setUndergo(undergo);
      // }
    }
  };
  const getGetCheck = (paramsValue) => {
    setButtonsValue(paramsValue);
  };
  const getEducation = (v) => {
    var Corresponding = newEducationColumns?.find((item) => {
      return item.value === v[0];
    });
    setEducationValue(Corresponding.id);
  };
  var echoEducationId = newEducationColumns?.find((item) => {
    return item.name === echoEducation;
  });
  //1.通过useRef创建一个ref对象
  const inputEl = React.useRef(null);
  const upload = () => {
    //3.通过inputEl.current属性就可以获取到绑定的input  dom元素了
    const files = inputEl.current.files;
    let file = files[0];
    let read = new FileReader();
    read.readAsDataURL(file);
    read.onload = async (e) => {
      let response = await pictureUploadBase({
        file: e.currentTarget.result,
      });
      setAvatar(response?.data?.url);
    };
  };
  const mockUpload = async (files) => {
    //健康档案图片上传
    const formData = new FormData();
    formData.append("file", files);
    let response = await pictureUpload(formData);
    return {
      url: response?.data?.url,
    };
  };
  const deleteWorkExperience = (param, e) => {
    e.stopPropagation(); //阻止事件冒泡
    let arr = undergo.filter((i) => {
      return i.id !== param;
    });
    setUndergo(arr);
  };

  const myInfoFormData = {
    headimgurl: avatar || res?.vip_info?.headimgurl,
    sex: buttonsValue,
    education_id: educationValue || echoEducationId?.id,
    graduate_time: parseInt(timerValue) || echoIsTimerPicker,
    undergo: undergo,
  };
  // 提交信息
  const onFinishMyInfo = async () => {
    const values = form.getFieldsValue();
    if (myInfoFormData?.graduate_time === "Invalid date") {
      Toast.show(`请选择毕业时间`, 2);
    } else {
      let res = await infoSubmit({
        ...values,
        ...myInfoFormData,
        ...healthData,
        ...skillData,
        undergo: undergo,
      });
      if (res.code === 1) {
        Dialog.alert({
          content: "提交成功返回首页",
          onConfirm: () => {
            navigate("/assistant");
          },
        });
      }
    }
  };
  // 提交工作经历
  const onSubmitExperience = () => {
    const values = form1.getFieldsValue();
    if (!values.company_name) {
      Toast.show({
        content: "请输入工作单位",
      });
      return;
    } else if (!values.office_name) {
      Toast.show({
        content: "请输入职位名称",
      });
      return;
    }
    // else if (!values.trade_name) {
    //   Toast.show({
    //     content: "请输入所在行业",
    //   });
    //   return;
    // }
    else if (!values.work_start_time) {
      Toast.show({
        content: "请输入开始时间",
      });
      return;
    } else if (!values.work_end_time) {
      Toast.show({
        content: "请输入结束时间",
      });
      return;
    }
    // else if (!values.job_content) {
    //   Toast.show({
    //     content: "请输入工作内容",
    //   });
    // }
    else {
      setExpDialogShow(false);
      let id = nanoid();
      let arr = [
        {
          id: id,
          company_name: values?.company_name,
          office_name: values?.office_name,
          trade_name: values?.trade_name,
          work_start_time: values?.work_start_time,
          work_end_time: values?.work_end_time,
          job_content: values?.job_content,
        },
      ];
      setWorkData(arr);
      if (undergo === undefined) {
        setUndergo(arr);
      } else {
        undergo?.unshift(arr[0]);
        setUndergo(undergo);
      }
    }
  };
  // 提交技能档案
  const onSubmitSkill = () => {
    const values = form.getFieldsValue();
    if (!values.skill_name) {
      Toast.show({
        content: "请输入技能名称",
      });
      return;
    } else {
      let listArr = fileListSkill?.map((item) => Object?.values(item));
      setSkillDialogShow(false);
      setSkillData({
        skill_name: values?.skill_name,
        skill_img: listArr,
      });
    }
  };
  // 提交健康档案
  const onSubmitHealth = () => {
    const values = form.getFieldsValue();
    if (!values.chronic) {
      Toast.show({
        content: "请输入慢性病情况",
      });
      return;
    } else {
      let listArr = fileList?.map((item) => Object?.values(item));
      setHealthDialogShow(false);
      setHealthData({
        height: values?.height,
        weight: values?.weight,
        chronic: values?.chronic,
        file_img: listArr,
      });
    }
  };
  return (
    <div className="myInfo">
      <div className="headImg">
        <div className="imgUploadSign">
          {avatar ? (
            <img src={avatar} className="UploadSign" alt="" />
          ) : (
            <img src={HeadUpload} className="UploadSign" alt="" />
          )}
          <input
            type="file"
            // 2.通过ref绑定dom或者组件
            ref={inputEl}
            onChange={upload}
            className="UploadSignInput"
            style={{ width: "1.6rem", height: "1.6rem" }}
            accept="image/*"
          />
        </div>
        {avatar ? (
          <div className="imgUploadText">更换头像</div>
        ) : (
          <div className="imgUploadText">上传头像</div>
        )}
      </div>
      <div className="myInfoItem">
        <Form
          form={form}
          layout="horizontal"
          footer={
            <Button
              block
              className="submitButton"
              onClick={onFinishMyInfo}
              type="submit"
              color="primary"
            >
              提交申请
            </Button>
          }
        >
          <div className="baseDivBoxfirst" />
          <Form.Item
            name="full_name"
            label="姓名"
            initialValues={vip_info?.full_name}
            rules={[{ required: false, message: "姓名不能为空" }]}
          >
            {{ ...vip_info }["full_name"] ? (
              <Input placeholder={{ ...vip_info }["full_name"]} disabled />
            ) : (
              <Input placeholder="请输入姓名" />
            )}
          </Form.Item>
          <Form.Item
            name="idcard"
            label="身份证号"
            initialValues={vip_info?.idcard}
            rules={[{ required: false, message: "身份证号不能为空" }]}
          >
            {{ ...vip_info }["idcard"] ? (
              <Input placeholder={{ ...vip_info }["idcard"]} disabled />
            ) : (
              <Input placeholder="请输入身份证号" />
            )}
          </Form.Item>
          <div className="buttonForm">
            {/* <span
              style={{
                color: "#ff3141",
                marginLeft: "-.09rem",
                position: "relative",
                left: ".32rem",
              }}
            >
              *
            </span> */}
            <Form.Item
              style={{
                display: "inline-block",
              }}
              // name={item.itemName}
              label="性别"
            >
              <TwoButton
                twoButton={buttonValue}
                buttonsValue={buttonsValue}
                getCheck={getGetCheck}
              />
            </Form.Item>
          </div>
          <Form.Item
            name="age"
            label="年龄"
            initialValues={vip_info?.age}
            rules={[{ required: false, message: "年龄不能为空" }]}
          >
            <Input placeholder="请输入年龄" />
            {/* {{ ...vip_info }["age"] ? (
              <Input placeholder={{ ...vip_info }["age"]} disabled />
            ) : (
              <Input placeholder="请输入年龄" />
            )} */}
          </Form.Item>
          <Form.Item
            // name={item.itemName} //回显值和name绑定不同，不能绑定name
            label="学历"
            rules={[{ required: false, message: "学历不能为空" }]}
            // trigger={item.itemTrigger}
            onClick={() => {
              setEducationShow(true);
            }}
          >
            <Picker
              title="选择学历"
              columns={[newEducationColumns]}
              visible={educationShow}
              onClose={() => {
                setEducationShow(false);
              }}
              // defaultValue={[51]}
              onConfirm={(v) => {
                // setValue(v);
                getEducation(v);
              }}
            >
              {(value) =>
                value?.length > 0 ? (
                  <span className="pickerValues">
                    {value?.map((i) => i?.label)}
                  </span>
                ) : echoEducation ? (
                  <span className="pickerValues">{echoEducation}</span>
                ) : (
                  <span className="pickerTips">请输入学历</span>
                )
              }
            </Picker>
          </Form.Item>
          <Form.Item
            name="graduate_school"
            label="毕业院校"
            initialValues={vip_info?.graduate_school}
            rules={[{ required: false, message: "毕业院校不能为空" }]}
          >
            <Input
              placeholder="请输入毕业院校"
              // defaultValue={vip_info?.graduate_school}
            />
          </Form.Item>

          <Form.Item
            // name={item.itemName} //回显值和name绑定不同，不能绑定name
            label="毕业时间"
            // trigger={item.itemTrigger}
            onClick={() => {
              setPickerVisible(true);
            }}
          >
            <DatePicker
              min={min}
              max={now}
              precision="year"
              visible={pickerVisible}
              onClose={() => {
                setPickerVisible(false);
              }}
              onConfirm={(v) => {
                setTimerValue(moment(v).format("YYYY"));
              }}
            >
              {(value) =>
                value ? (
                  <span className="pickerValues">
                    {dayjs(value).format("YYYY")}
                  </span>
                ) : echoIsTimerPicker ? (
                  <span className="pickerValues">{echoIsTimerPicker}</span>
                ) : (
                  <span className="pickerTips">请输入毕业时间</span>
                )
              }
            </DatePicker>
          </Form.Item>
          <Form.Item
            name="phone"
            label="联系电话"
            // initialValues={vip_info?.phone}
            rules={[{ required: false, message: "联系电话不能为空" }]}
          >
            <Input placeholder="请输入联系电话" />
          </Form.Item>
          {/* <Form.Item
            name="phone"
            label="联系电话"
            initialValues={vip_info?.phone}
            rules={[{ required: false, message: "联系电话不能为空" }]}
          >
            {{ ...vip_info }["phone"] ? (
              <Input placeholder={{ ...vip_info }["phone"]} disabled />
            ) : (
              <Input placeholder="请输入联系电话" />
            )}
          </Form.Item> */}
          <Form.Item
            name="contact_address"
            label="联系地址"
            // initialValues={vip_info?.phone}
            rules={[{ required: false, message: "联系地址不能为空" }]}
          >
            <TextArea
              placeholder="请输入联系地址"
              autoSize={{ minRows: 1, maxRows: 5 }}
              rows={1}
            />
          </Form.Item>
          {/* <Form.Item
            name="contact_address"
            initialValues={vip_info?.contact_address}
            label="联系地址"
            rules={[{ required: false, message: "联系地址不能为空" }]}
          >
            {vip_info?.contact_address ? (
              <span className="echoData">{vip_info?.contact_address}</span>
            ) : (
              <TextArea
                placeholder="请输入联系地址"
                autoSize={{ minRows: 1, maxRows: 5 }}
                rows={1}
              />
            )}
          </Form.Item> */}
          <div className="distance">
            {/* <span className="baseProStar" style={{ color: "#F93A42" }}>
              *
            </span> */}
            <span
              className="flexBoxBetween"
              onClick={(e) => {
                e.stopPropagation(); //阻止事件冒泡
                setExpDialogShow(true);
                form1.resetFields();
              }}
            >
              <span style={{ fontWeight: "500" }}>就业经历</span>
              <span className="editAddwrapBox">
                <img className="imgAdd" src={Add} alt="" />
                <span className="editTipsText">新增就业经历</span>
              </span>
            </span>
            {undergo.length === 0 ? (
              <div
                style={{
                  borderBottom: "solid 1px #eeeeee",
                  width: "100%",
                  height: ".25rem",
                  padding: "0 .3rem",
                }}
              />
            ) : (
              ""
            )}
          </div>

          {undergo
            ? undergo?.map((i) => (
                <div
                  key={i?.id}
                  onClick={(e) => editWorkExp(i.id, e)}
                  className="healthFileBox"
                  // style={{ marginLeft: ".2rem" }}
                >
                  <div style={{ lineHeight: ".2rem" }}>
                    <span
                      style={{
                        fontSize: ".28rem",
                        color: "#686B73",
                        fontWeight: 400,
                      }}
                    >
                      {i.company_name}
                    </span>
                    <div
                      style={{
                        color: "#686B73",
                        float: "right",
                      }}
                    >
                      {i.work_start_time}-{i.work_end_time}&nbsp;&gt;
                    </div>
                  </div>
                  <div style={{ lineHeight: ".6rem" }}>{i.office_name}</div>
                  <div
                    style={{
                      color: "#686B73",
                      lineHeight: ".5rem",
                      textAlign: "justify",
                    }}
                  >
                    {i.job_content}
                  </div>
                  <div className="flexBoxBetween">
                    <div></div>
                    <div
                      onClick={(e) => deleteWorkExperience(i?.id, e)}
                      style={{
                        lineHeight: ".3rem",
                        color: "#808080",
                        textAlign: "center",
                        width: "1rem",
                      }}
                    >
                      <DeleteOutline />
                      删除
                    </div>
                  </div>
                  <hr
                    style={{
                      borderTop: "solid 1px #eeeeee",
                    }}
                  />
                </div>
              ))
            : ""}
          {healthData?.height ||
          healthData?.weight ||
          healthData?.chronic ||
          healthData?.file_img ? (
            ""
          ) : (
            <div
              className="distance"
              onClick={() => {
                setHealthDialogShow(true);
              }}
            >
              {/* <span className="baseProStar" style={{ color: "#F93A42" }}>
                *
              </span> */}
              <span className="flexBoxBetween">
                <span style={{ fontWeight: "500" }}>健康档案</span>
                <span className="editAddwrapBox">
                  <img className="imgAdd" src={Add} alt="" />
                  <span className="editTipsText">新增健康档案</span>
                </span>
              </span>
              {JSON.stringify(healthData) === "{}" ? (
                <div
                  style={{
                    borderBottom: "solid 1px #eeeeee",
                    width: "100%",
                    height: ".25rem",
                    padding: "0 .3rem",
                  }}
                />
              ) : (
                ""
              )}
            </div>
          )}
          {/* 健康档案 */}
          {healthData?.height ||
          healthData?.weight ||
          healthData?.chronic ||
          healthData?.file_img ? (
            <div
              onClick={() => {
                let arr = healthData?.file_img?.map((i) => {
                  const url = i?.split(",")[0];
                  return {
                    url,
                  };
                });
                setFileList(arr);
                form.setFieldsValue({ ...healthData });
                setHealthDialogShow(true);
              }}
              className="healthFileBox"
              // style={{ marginLeft: ".2rem" }}
            >
              <div
                className="healthFileBoxTitle"
                style={{ fontWeight: "500", color: "#363A44" }}
              >
                健康档案
              </div>
              <div>
                <span className="healthFileBody">
                  <span>身高：</span>
                  <span>{healthData?.height}cm</span>
                </span>
                <span className="healthFileBody">
                  <span>体重：</span>
                  <span>{healthData?.weight}kg</span>
                </span>
              </div>
              <div>{healthData?.chronic}</div>
              {healthData?.file_img?.length !== 0 ? (
                <Space
                  className="pictureSpace"
                  wrap
                  style={{
                    width: "100%",
                    position: "relative",
                    "--gap": "4px",
                  }}
                >
                  {healthData?.file_img?.map((i, index) => (
                    <img
                      style={{
                        display: "inline-block",
                        width: "1.3rem",
                        height: "1.3rem",
                        borderRadius: ".1rem",
                      }}
                      key={index}
                      src={i}
                      alt=""
                    />
                  ))}
                  <span
                    style={{
                      display: "inline-block",
                      position: "absolute",
                      fontSize: ".48rem",
                      right: ".1rem",
                      top: ".75rem",
                      color: "#cccccc",
                    }}
                  >
                    {healthData?.file_img?.length < 4 ? ">" : ""}
                  </span>
                </Space>
              ) : (
                ""
              )}

              <hr style={{ borderTop: "solid 1px #eeeeee" }} />
            </div>
          ) : (
            ""
          )}
          {/* 薪增技能档案 */}
          {skillData?.skill_name || skillData?.skill_img ? (
            ""
          ) : (
            <div
              className="distance"
              onClick={() => {
                setSkillDialogShow(true);
              }}
            >
              {/* <span className="baseProStar" style={{ color: "#F93A42" }}>
                *
              </span> */}
              <span className="flexBoxBetween">
                <span style={{ fontWeight: "500" }}>技能档案</span>
                <span className="editAddwrapBox">
                  <img className="imgAdd" src={Add} alt="" />
                  <span className="editTipsText">新增技能档案</span>
                </span>
              </span>
            </div>
          )}
          {/* 技能档案 */}
          {skillData && Object.keys(skillData).length !== 0 ? (
            <div
              onClick={() => {
                let arr = skillData?.skill_img?.map((i) => {
                  const url = i?.split(",")[0];
                  return {
                    url,
                  };
                });
                setFileListSkill(arr);
                form.setFieldsValue({ ...skillData });
                setSkillDialogShow(true);
              }}
              className="healthFileBox"
              // style={{ marginLeft: ".2rem" }}
            >
              {skillData?.skill_name || skillData?.skill_img ? (
                <div
                  className="healthFileBoxTitle"
                  style={{ fontWeight: "500", color: "#363A44" }}
                >
                  技能档案
                </div>
              ) : (
                ""
              )}
              <div>{skillData?.skill_name}</div>
              {skillData?.skill_img?.length !== 0 ? (
                <Space
                  className="pictureSpace"
                  wrap
                  style={{
                    width: "100%",
                    position: "relative",
                    "--gap": "4px",
                  }}
                >
                  {skillData?.skill_img?.map((i, index) => (
                    <img
                      style={{
                        display: "inline-block",
                        width: "1.3rem",
                        height: "1.3rem",
                        borderRadius: ".1rem",
                      }}
                      key={index}
                      src={i}
                      alt=""
                    />
                  ))}
                  <span
                    style={{
                      display: "inline-block",
                      fontSize: ".48rem",
                      position: "absolute",
                      right: ".1rem",
                      top: ".75rem",
                      color: "#cccccc",
                    }}
                  >
                    {skillData?.skill_img?.length < 4 ? ">" : ""}
                  </span>
                </Space>
              ) : (
                ""
              )}
              {/* <hr style={{ borderTop: "solid 1px #eeeeee" }} /> */}
            </div>
          ) : (
            ""
          )}
        </Form>
      </div>
      {/* 技能档案弹窗 */}
      <Dialog
        visible={skillDialogShow}
        closeOnMaskClick={true}
        content={
          <div className="editHealthRecords">
            <Form
              form={form}
              style={{
                "--border-top": "none !important",
                "--border-bottom": "none !important",
              }}
              initialValues={{ ...skillData }}
              layout="horizontal"
              footer={
                <>
                  <Button
                    block
                    type="submit"
                    className="DialogButtom"
                    onClick={onSubmitSkill}
                    color="primary"
                    size="middle"
                  >
                    提交
                  </Button>
                  <Button
                    block
                    className="DialogButtom"
                    type="submit"
                    onClick={onSubmitCalce}
                    size="middle"
                  >
                    取消
                  </Button>
                </>
              }
            >
              <Form.Item
                name="skill_name"
                label="技能档案"
                rules={[
                  {
                    required: true,
                    message: "技能档案必填",
                  },
                ]}
              >
                <Input placeholder="请输入技能档案" />
              </Form.Item>
              <Form.Item
              // name={item.itemName}
              // label={item.itemLabel}
              >
                <div className="uploadTop">
                  <span style={{ color: "#F93A42" }}></span>
                  <span className="uploadTitle">上传技能证书图片</span>
                  <span className="uploadTips">最多可上传4张图片</span>
                </div>
                <div className="uploadWrap">
                  <ImageUploader
                    style={{ "--cell-size": "1.4rem" }}
                    value={fileListSkill}
                    maxCount={4}
                    onChange={setFileListSkill}
                    upload={mockUpload}
                  >
                    <div
                      style={{
                        width: "1.4rem",
                        height: "1.4rem",
                        // borderRadius: 40,
                        backgroundColor: "#f5f5f5",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#999999",
                      }}
                    >
                      <img
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                        }}
                        alt=""
                        src={Upload}
                      />
                    </div>
                  </ImageUploader>
                </div>
              </Form.Item>
            </Form>
          </div>
        }
      />
      {/* 健康档案弹窗 */}
      <Dialog
        visible={healthDialogShow}
        closeOnMaskClick={true}
        content={
          <div className="editHealthRecords">
            <Form
              form={form}
              initialValues={{ ...healthData }}
              layout="horizontal"
              style={{
                "--border-top": "none !important",
                "--border-bottom": "none !important",
              }}
              footer={
                <>
                  <Button
                    className="DialogButtom"
                    block
                    type="submit"
                    onClick={onSubmitHealth}
                    color="primary"
                    size="middle"
                  >
                    提交
                  </Button>
                  <Button
                    block
                    className="DialogButtom"
                    type="submit"
                    onClick={onSubmitCalce}
                    size="middle"
                  >
                    取消
                  </Button>
                </>
              }
            >
              <Form.Item
                name="height"
                label="身高"
                rules={[
                  {
                    required: false,
                    message: "身高必填",
                  },
                ]}
              >
                <Input
                  value={healthData?.height}
                  placeholder="请输入身高"
                  initialValues={healthData?.height}
                />
              </Form.Item>
              <Form.Item
                name="weight"
                label="体重"
                rules={[
                  {
                    required: false,
                    message: "体重必填",
                  },
                ]}
              >
                <Input placeholder="请输入体重" />
              </Form.Item>
              <Form.Item
                name="chronic"
                className="chronic"
                label="慢性病情况"
                rules={[
                  {
                    required: true,
                    message: "请输入慢性病情况",
                  },
                ]}
              >
                <Input placeholder="请输入慢性病情况" />
              </Form.Item>
              <Form.Item>
                <div className="uploadTop">
                  <span style={{ color: "#F93A42" }}></span>
                  <span className="uploadTitle">上传健康检查文件</span>
                  <span className="uploadTips">最多可上传4张图片</span>
                </div>
                <div className="uploadWrap">
                  <ImageUploader
                    style={{ "--cell-size": "1.4rem" }}
                    value={fileList}
                    maxCount={4}
                    onChange={setFileList}
                    upload={mockUpload}
                  >
                    <div
                      style={{
                        width: "1.4rem",
                        height: "1.4rem",
                        // borderRadius: 40,
                        backgroundColor: "#f5f5f5",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#999999",
                      }}
                    >
                      <img
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                        }}
                        alt=""
                        src={Upload}
                      />
                    </div>
                  </ImageUploader>
                </div>
              </Form.Item>
            </Form>
          </div>
        }
      />
      {/* 就业经历新增 */}
      <Dialog
        visible={expDialogShow}
        closeOnMaskClick={true}
        content={
          <div className="editGoWorkExperience">
            <Form
              form={form1}
              style={{
                "--border-top": "none !important",
                "--border-bottom": "none !important",
              }}
              // initialValues={{ ...workData }}
              layout="horizontal"
              footer={
                <>
                  <Button
                    block
                    className="DialogButtom"
                    type="submit"
                    onClick={onSubmitExperience}
                    color="primary"
                    size="middle"
                  >
                    提交
                  </Button>
                  <Button
                    block
                    className="DialogButtom"
                    type="submit"
                    onClick={onSubmitCalce}
                    size="middle"
                  >
                    取消
                  </Button>
                </>
              }
            >
              <Form.Item
                name="company_name"
                label="工作单位"
                rules={[{ required: true, message: "请输入工作单位" }]}
              >
                <Input placeholder="请输入工作单位" />
              </Form.Item>
              <Form.Item
                name="trade_name"
                label="所在行业"
                rules={[{ required: false, message: "请输入所在行业" }]}
              >
                <Input placeholder="请输入所在行业" />
              </Form.Item>
              <Form.Item
                name="office_name"
                label="职位名称"
                rules={[{ required: true, message: "职位名称不能为空" }]}
              >
                <Input placeholder="请输入职位名称" />
              </Form.Item>
              <Form.Item
                name="work_start_time"
                className="work_start_time"
                label="在职开始时间"
                rules={[{ required: true, message: "请输入开始时间" }]}
              >
                <Input placeholder="请输入开始时间" />
              </Form.Item>
              <Form.Item
                name="work_end_time"
                className="work_end_time"
                label="在职结束时间"
                rules={[{ required: true, message: "请输入结束时间" }]}
              >
                <Input placeholder="请输入结束时间" />
              </Form.Item>
              <Form.Item
                name="job_content"
                className="job_content"
                label="工作内容"
                rules={[{ required: false, message: "请输入工作内容" }]}
              >
                {/* <Input placeholder="请输入工作内容" /> */}
                <TextArea
                  style={{ color: "#333333 !important" }}
                  placeholder="请输入工作内容"
                  autoSize={{ minRows: 5, maxRows: 30 }}
                  rows="5"
                />
              </Form.Item>
            </Form>
          </div>
        }
      />
      {/* 就业经历编辑 */}
      <Dialog
        visible={expDialogEditShow}
        closeOnMaskClick={true}
        content={
          <div className="editGoWorkExperience">
            <Form
              form={form}
              style={{
                "--border-top": "none !important",
                "--border-bottom": "none !important",
              }}
              // initialValues={{ ...workData }}
              layout="horizontal"
              footer={
                <>
                  <Button
                    block
                    className="DialogButtom"
                    type="submit"
                    onClick={onSubmitExperienceEdit}
                    color="primary"
                    size="middle"
                  >
                    提交
                  </Button>
                  <Button
                    block
                    className="DialogButtom"
                    type="submit"
                    onClick={onSubmitCalce}
                    size="middle"
                  >
                    取消
                  </Button>
                </>
              }
            >
              <Form.Item
                name="company_name"
                label="工作单位"
                rules={[{ required: true, message: "请输入工作单位" }]}
              >
                <Input placeholder="请输入工作单位" />
              </Form.Item>
              <Form.Item
                name="trade_name"
                label="所在行业"
                rules={[{ required: false, message: "请输入所在行业" }]}
              >
                <Input placeholder="请输入所在行业" />
              </Form.Item>
              <Form.Item
                name="office_name"
                label="职位名称"
                rules={[{ required: true, message: "职位名称不能为空" }]}
              >
                <Input placeholder="请输入职位名称" />
              </Form.Item>
              <Form.Item
                name="work_start_time"
                className="work_start_time"
                label="在职开始时间"
                rules={[{ required: true, message: "请输入开始时间" }]}
              >
                <Input placeholder="请输入开始时间" />
              </Form.Item>
              <Form.Item
                name="work_end_time"
                className="work_end_time"
                label="在职结束时间"
                rules={[{ required: true, message: "请输入结束时间" }]}
              >
                <Input placeholder="请输入结束时间" />
              </Form.Item>
              <Form.Item
                name="job_content"
                className="job_content"
                label="工作内容"
                rules={[{ required: false, message: "请输入工作内容" }]}
              >
                {/* <Input placeholder="请输入工作内容" /> */}
                <TextArea
                  style={{ color: "#333333 !important" }}
                  placeholder="请输入工作内容"
                  autoSize={{ minRows: 5, maxRows: 30 }}
                  rows="5"
                />
              </Form.Item>
            </Form>
          </div>
        }
      />
    </div>
  );
}

export default MyInfo;
