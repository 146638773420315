import React from "react";
import "./index.less";

function BalloonRight(props) {
  const { chatContent, avatar } = props;
  return (
    <div className="balloonRightWrap">
      <div className="balloonRight">{chatContent}</div>
      <img className="avatarRight" src={avatar} alt="" />
    </div>
  );
}

export default BalloonRight;
