import React, { useState, useEffect } from "react";
import PublicSearchBar from "../../../components/PublicSearchBar";
import PublicTips from "../../../components/PublicTips";
import { Dialog, Toast } from "antd-mobile";
import TestAppointList from "../../../components/TestAppointList";
import { searchTitle } from "../../../utils/allSearchBar";
import { examAppoint, tipsApi } from "../../../services/index";
import { enrollApi } from "../../../services/index";
import "./index.less";

function TestAppoint() {
  const [res, setRes] = useState({});
  const [tip, setTip] = useState({});
  const [dataList, setDataList] = useState([]);
  const [sourse, setSourse] = useState({});
  const [data, setData] = useState({});
  const [page, setPage] = useState(1); //页数
  const [hasMore, setHasMore] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [keyData, setKeyData] = useState([]);

  useEffect(() => {
    fn({ page: 1, pageSize: 10 });
    tips();
  }, []);
  const fn = async (params) => {
    let res = await examAppoint(params);
    setRes(res);
    setData(res?.data?.data_list);
    setSourse(res?.data?.data_list?.data);
    setHasMore(
      res?.data?.data_list?.current_page <= res?.data?.data_list?.last_page
    );
    setPage(res?.data?.data_list?.current_page);
  };
  const getSearch = async (param) => {
    setKeyword(param);
    let res = await examAppoint({ keyword: param });
    setKeyData(res?.data?.data_list?.data);
  };
  const tips = async () => {
    let res2 = await tipsApi({ type: 14 }); //sourse?.data?.type
    setTip(res2?.data);
  };
  const appoint = (params) => {
    Dialog.confirm({
      bodyClassName: "appointModal",
      content: <div className="subjectTips">确定报名该项考试吗？</div>,
      onConfirm: async () => {
        let res = await enrollApi(params);
        if (res?.code === 1) {
          const oldItem = dataList?.find((i) => {
            return i.id === params;
          });
          const newItem = { ...oldItem, status: 2 };
          const newArr = dataList.map((item) => {
            return item.id === newItem.id ? newItem : item;
          });
          setDataList(newArr);
          Toast.show({
            icon: "success",
            content: "报名成功",
          });
        }
      },
    });
  };

  const loadMore = async () => {
    let curPage = page + 1;
    setPage(curPage);
    await fn({ page: curPage, pageSize: 10 });
    setHasMore(data?.current_page <= data?.last_page);
    setDataList((val) => [...val, ...sourse]);
  };

  return (
    <div className="testAppoint">
      {res?.data?.type ? (
        <PublicTips data={tip} />
      ) : (
        <>
          <PublicSearchBar
            getSearch={getSearch}
            searchData={searchTitle?.searchCredential.title}
          />
          {keyword ? (
            <TestAppointList
              loadMore={loadMore}
              hasMore={hasMore}
              sourse={keyData}
              appoint={appoint}
            />
          ) : (
            <TestAppointList
              loadMore={loadMore}
              hasMore={hasMore}
              sourse={dataList}
              appoint={appoint}
            />
          )}
        </>
      )}
    </div>
  );
}

export default TestAppoint;
