import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Dialog, Button, Form, Cascader, Picker } from "antd-mobile";
import TwoButton from "../../../../components/TwoButton";
import { twoButtonData } from "../../../../utils/allTwoButton";
import { allPickerData } from "../../../../utils/allPicker";
import { getJob, regionList, jobSubmit } from "../../../../services/index.js";
import "./index.less";

function EditSearchWork() {
  const [form] = Form.useForm();
  const { state } = useLocation();
  const [visible, setVisible] = useState(true);
  const [educationShow, setEducationShow] = useState(false);
  const [workCityShow, setWorkCityShow] = useState(false);
  const [salaryRequireShow, setSalaryRequireShow] = useState(false);
  const [sourse, setSourse] = useState();
  const [buttonsValue, setButtonsValue] = useState(1);
  const [workCity, setWorkCity] = useState([]);
  const [expectJob, setExpectJob] = useState();
  const [salaryReq, setSalaryReq] = useState();
  const [address, setAddress] = useState([]);
  const buttonValue = twoButtonData?.seachWorkType;
  useEffect(() => {
    api();
    fn();
  }, []);

  // 获取求职意向
  const fn = async () => {
    let res = await getJob(state);
    setSourse(res?.data);
  };
  // 获取地区列表
  const api = async () => {
    let res = await regionList();
    // 省市区选择框数据格式处理
    const address = res?.data?.area?.map((province) => ({
      label: province.name,
      value: province.id,
      children: province?.citys?.map((city) => ({
        value: city.id,
        label: city.name,
      })),
    }));
    setAddress(address);
  };
  const salaryRequireColumns =
    sourse?.data_wages?.map((i) => ({
      label: i?.name,
      value: i?.id,
    })) || [];

  const getGetCheck = (paramsValue) => {
    setButtonsValue(paramsValue);
  };
  // 薪资要求
  const getSalaryRequire = (v) => {
    setSalaryReq(v[0]);
  };
  // 工作城市
  const getWorkCity = (v) => {
    setWorkCity(v);
  };
  //期望职位
  const getEducation = (v) => {
    setExpectJob(v[0]);
  };
  const addData = {
    id: state?.id,
    type: buttonsValue,
    province_id: workCity[0]?.value || sourse?.job_intention?.province_id,
    city_id: workCity[1]?.value || sourse?.job_intention?.city_id,
    expect_jon: expectJob || sourse?.job_intention?.expect_jon,
    wages_id: salaryReq || sourse?.job_intention?.wages_id,
  };
  const onFinishStation = async () => {
    if (addData.province_id && addData.city_id === undefined) {
      setVisible(true);
      Dialog.confirm({
        bodyClassName: "collectDetailsBody",
        content: "工作城市需精确到市",
        showCloseButton: true,
        onConfirm: () => {
          setVisible(false);
        },
      });
    } else if (
      addData.province_id &&
      addData.city_id &&
      addData.province_id !== sourse?.job_intention?.province_id &&
      addData.city_id === sourse?.job_intention?.city_id
    ) {
      setVisible(true);
      Dialog.confirm({
        bodyClassName: "collectDetailsBody",
        content: "工作城市需精确到市",
        showCloseButton: true,
        onConfirm: () => {
          setVisible(false);
        },
      });
    } else {
      let res = await jobSubmit(addData);
      if (res?.code === 1) {
        window.history.back();
      }
    }
  };
  return (
    <div className="editSearchWork">
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinishStation}
        footer={
          <Button block className="submitButton" type="submit" color="primary">
            提交申请
          </Button>
        }
      >
        <div className="buttonForm">
          <Form.Item
            style={{ display: "inline-block", width: "100%" }}
            // name={item.itemName}
            label="求职类型"
          >
            <TwoButton
              twoButton={buttonValue}
              buttonsValue={buttonsValue}
              getCheck={getGetCheck}
            />
          </Form.Item>
        </div>
        <Form.Item
          label="工作城市"
          trigger="onConfirm"
          onClick={() => {
            setWorkCityShow(true);
          }}
        >
          <Cascader
            options={address}
            // title="选择学历"
            visible={workCityShow}
            onClose={() => {
              setWorkCityShow(false);
            }}
            onConfirm={(v, extend) => {
              getWorkCity(extend.items);
            }}
          >
            {(value) =>
              (value?.length > 0 && value[0] !== null) ||
              sourse?.job_intention?.city_id ? (
                <span className="pickerValues">
                  {value?.length > 0 && value[0] !== null ? (
                    value?.map((i) => i?.label)
                  ) : (
                    <>
                      {sourse?.job_intention?.province_name}
                      {sourse?.job_intention?.city_name}
                    </>
                  )}
                </span>
              ) : (
                <span className="pickerTips">请输入工作城市</span>
              )
            }
          </Cascader>
        </Form.Item>
        <Form.Item
          // name={item.itemName} //回显值和name绑定不同，不能绑定name
          label="期望职位"
          trigger="onConfirm"
          onClick={() => {
            setEducationShow(true);
          }}
        >
          <Picker
            title="期望职位"
            columns={allPickerData.expectPositionColumns}
            visible={educationShow}
            onClose={() => {
              setEducationShow(false);
            }}
            onConfirm={(v) => {
              getEducation(v);
            }}
          >
            {(value) =>
              (value?.length > 0 && value[0] !== null) ||
              sourse?.job_intention?.expect_jon ? (
                <span className="pickerValues">
                  {value?.length > 0 && value[0] !== null
                    ? value?.map((i) => i?.label)
                    : sourse?.job_intention?.expect_jon === 1
                    ? "家庭护理员"
                    : "养老护理员"}
                </span>
              ) : (
                <span className="pickerTips">请输入期望职位</span>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          style={{ "--border-inner": "none" }}
          label="薪资要求"
          trigger="onConfirm"
          onClick={() => {
            setSalaryRequireShow(true);
          }}
        >
          <Picker
            title="薪资要求"
            columns={[salaryRequireColumns]}
            visible={salaryRequireShow}
            onClose={() => {
              setSalaryRequireShow(false);
            }}
            onConfirm={(v) => {
              getSalaryRequire(v);
            }}
          >
            {(value) =>
              (value?.length > 0 && value[0] !== null) ||
              sourse?.job_intention?.wages_name ? (
                <span className="pickerValues">
                  {value?.length > 0 && value[0] !== null
                    ? value?.map((i) => i?.label)
                    : sourse?.job_intention?.wages_name}
                </span>
              ) : (
                <span className="pickerTips">请输入薪资要求</span>
              )
            }
          </Picker>
        </Form.Item>
      </Form>
    </div>
  );
}

export default EditSearchWork;
