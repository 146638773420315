import React from "react";
import { InfiniteScroll } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import "./index.less";

function TestAppointList(props) {
  const navigate = useNavigate();
  const { sourse, appoint, loadMore, hasMore } = props;
  return (
    <div className="testAppointList">
      {sourse?.map((i) => (
        <div className="testAppointListItem" key={i.id}>
          <div className="testTitle">{i.title}</div>
          <div className="testTime">
            考试时间：{i.start_time_name}~{i.end_time_name}
          </div>
          <div className="endTime">预约截止：{i.reserve_end_time_name}</div>
          {i.status === 1 ? (
            <div className="signUp" onClick={() => appoint(i?.id)}>
              我要报名
            </div>
          ) : i.status === 2 ? (
            <div
              className="signUp"
              onClick={() =>
                navigate("/admissionticket", {
                  state: {
                    id: i.id,
                  },
                })
              }
            >
              查看
            </div>
          ) : i.status === 3 ? (
            <div className="end">已截至</div>
          ) : (
            ""
          )}
        </div>
      ))}
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
    </div>
  );
}

export default TestAppointList;
