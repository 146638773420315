import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PublicApplySuccess from "../../../components/PublicApplySuccess";
import { tipsApi } from "../../../services/index";

function ApplyTipsToPage() {
  const { state } = useLocation();
  const [data, setData] = useState({});
  useEffect(() => {
    fn();
  }, []);
  const fn = async () => {
    let res = await tipsApi(state);
    setData(res.data);
  };
  return (
    <div className="ApplyTips">
      <PublicApplySuccess data={data} />
    </div>
  );
}

export default ApplyTipsToPage;
