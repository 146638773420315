import React from "react";
import { useNavigate } from "react-router-dom";
import { InfiniteScroll } from "antd-mobile";
import "./index.less";

function PublicMarket(props) {
  const navigate = useNavigate();
  const { Data, loadMore, hasMore } = props;

  return (
    <div className="publicMarket">
      <div className="recruitMarketList">
        {Data?.map((i) => (
          <div
            className="itemBox"
            key={i?.id}
            onClick={() => {
              navigate(props.url, {
                state: {
                  id: i?.id,
                },
              });
            }}
          >
            <div className="itemTopNull"></div>
            <div className="itemLineOne flexBoxBetween">
              <span className="OneTitle">{i?.title}</span>
              <span className="OneMoney">
                {i?.wages_low}~{i?.wages_heigh}
              </span>
            </div>
            <div className="itemLineTwo">
              <span className="TwoSpan">{i.city_name}</span>
              <span className="TwoSpan">{i?.empiric_name}</span>
              <span className="TwoSpan">{i?.min_education_name}</span>
            </div>
            <div className="itemLineThree">
              <span className="ThreeSpan1">{i?.yanglao?.name}</span>
              {i?.yanglao?.name && i?.yanglao?.people_num ? (
                <span className="ThreeSpanline"></span>
              ) : (
                ""
              )}

              <span className="ThreeSpan2">{i?.yanglao?.people_num}</span>
            </div>

            <div className="itemLinefour">
              <span>
                {i?.city_name}&nbsp;{i?.area_name}
              </span>
            </div>
          </div>
        ))}
      </div>
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
    </div>
  );
}

export default PublicMarket;
