import React from "react";
import { useNavigate } from "react-router-dom";
import { Image, List } from "antd-mobile";
import assistant from "../../image/indexImage/assistant.png";
import recruit from "../../image/indexImage/recruit.png";
import employ from "../../image/indexImage/employ.png";
import students from "../../image/indexImage/students.png";
import indexArrow from "../../image/indexImage/indexArrow.png";
import home from "../../image/indexImage/home.png";
import one from "../../image/indexImage/one.png";
import "./index.less";

import { data } from "../../utils/allList.js";

function Index() {
  const navigate = useNavigate();
  return (
    <div className="index" style={{ height: document.body.clientHeight }}>
      <img className="topBackground" src={home} alt="" />
      <div className="publicList">
        <List
          mode="card"
          style={{
            "--border-inner": "none",
            "--border-top": "none",
            "--border-bottom": "none",
            "--align-items": "center",
          }}
        >
          <List.Item
            prefix={<Image src={assistant} className="avatorImg" fit="cover" />}
            arrow={<Image src={indexArrow} fit="cover" />}
            description={<div>点击前往</div>}
            onClick={() => {
              navigate("/assistant");
            }}
          >
            我是护理员
            {/* <img className="itembackground" src={one} alt="" /> */}
          </List.Item>
          <List.Item
            prefix={<Image src={recruit} className="avatorImg" fit="cover" />}
            arrow={<Image src={indexArrow} fit="cover" />}
            description={<div>点击前往</div>}
            onClick={() => {
              navigate("/employ");
            }}
          >
            我要招聘
          </List.Item>
          <List.Item
            prefix={<Image src={employ} className="avatorImg" fit="cover" />}
            arrow={<Image src={indexArrow} fit="cover" />}
            description={<div>点击前往</div>}
            onClick={() => {
              navigate("/recruit");
            }}
          >
            我要雇人
          </List.Item>
          <List.Item
            prefix={<Image src={students} className="avatorImg" fit="cover" />}
            arrow={<Image src={indexArrow} fit="cover" />}
            description={<div>点击前往</div>}
            onClick={() => {
              navigate("/students");
            }}
          >
            我要招生
          </List.Item>
        </List>
        {/* <div className="copymarkWarp"> */}
        <div className="copymark">
          <div>
            <div className="trademark">版本: v2.0.6</div>
            <div className="trademark">支持: YiY-tech.cn</div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default Index;
