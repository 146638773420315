import React, { useEffect, useState } from "react";
import { statusPolicy } from "../../../services/index";
import "./index.less";

function AwardPolicy() {
  const [data, setData] = useState();
  useEffect(() => {
    fn();
  }, []);
  const fn = async () => {
    let res = await statusPolicy();
    setData(res?.data);
  };
  return (
    <div
      className="awardPolicy"
      dangerouslySetInnerHTML={{ __html: data?.prize_policy }}
    ></div>
  );
}

export default AwardPolicy;
