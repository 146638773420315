import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Dialog } from "antd-mobile";
import BackgroundAnswer from "../../../image/orderTrain/backgroundAnswer.png";
import TestProblemAnswer from "../../../components/TestProblemAnswer";
import {
  TestDetail, //获取考试详情
  testCollect,
} from "../../../services/index";

import {
  StarFill,
  StarOutline,
  CheckCircleFill,
  CloseCircleFill,
} from "antd-mobile-icons";
import "./index.less";

function TestDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [is_collect, setIsCollect] = useState(1);
  // const [isShowAnswer, SetisShowAnswer] = useState(false);
  const [subjectType, setSubjectType] = useState(1); //caseAnalysis briefAnswer
  const [answer, setAnswer] = useState();
  const [answerData, setAnswerData] = useState();
  const [AnswerOptions, setAnswerOptions] = useState([]);
  const [code, setCode] = useState();
  const id = state?.id;
  useEffect(() => {
    fnAnswer();
  }, []);

  const fnAnswer = async () => {
    let response = await TestDetail({ simulate_my_id: id });
    setAnswerData(response.data);
    const rtype = response?.data?.type;
    setSubjectType(rtype);
    setCode(response?.code);
    setIsCollect(response.data.is_collect);
    if (rtype === 1 || rtype === 2 || rtype === 4) {
      const AnswerOptions =
        response?.data?.topic_answer?.map((item) => {
          item.label = item?.prefix?.concat(" ", item?.name);
          item.value = item.id;
          return item;
        }) || [];
      setAnswerOptions(AnswerOptions);
    }
  };
  const collect = (params) => {
    return async () => {
      await testCollect({ topic_id: answerData?.topic_id });
      setIsCollect(params);
    };
  };
  const nextSubject = () => {
    if (answerData?.done === answerData.count || code === 404) {
      Dialog.confirm({
        bodyClassName: "collectDetailsBody",
        content: (
          <>
            <div className="subjectTips">已是最后一题</div>
            <div className="subjectTips">点击确认返回首页</div>
          </>
        ),
        onConfirm: () => {
          navigate("/mockexamination");
        },
      });
    } else {
      fnAnswer();
    }
  };
  return (
    <div className="testDetails">
      <img className="background" src={BackgroundAnswer} alt="" />
      <>
        <TestProblemAnswer
          SubjectType={subjectType}
          AnswerOptions={AnswerOptions}
          // Data={data}
          answerData={answerData}
        />
        <div className="orderTrainBottom flexBoxBetween">
          <span className="collection">
            {is_collect === 2 ? (
              <>
                <StarFill onClick={collect(1)} style={{ color: "#2C68FF" }} />
                <span className="collectionTitle" style={{ color: "#2C68FF" }}>
                  已收藏
                </span>
              </>
            ) : (
              <>
                <StarOutline onClick={collect(2)} />
                <span className="collectionTitle">收藏</span>
              </>
            )}
          </span>
          <span className="subjectTrue">
            <CheckCircleFill />
            <span className="subjectTrueTitle">{answerData?.correct_num}</span>
          </span>
          <span className="subjectFalse">
            <CloseCircleFill />
            <span className="subjectFalseTitle">{answerData?.error_num}</span>
          </span>
          <span className="submit" onClick={nextSubject}>
            下一题
          </span>
        </div>
      </>
    </div>
  );
}

export default TestDetails;
