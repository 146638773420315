/**
 * 该文件用于汇总所有的reducer为一个总的reducer。
 */

//引入combineReducers，用于汇总多个reducer
import {combineReducers} from "redux";
//引入为Login组件服务的reducer
import login from './login'
//引入为Admin组件（菜单）服务的reducer
// import admin from './admin'
//引入为Product组件服务的reducer
// import product from './product'
// //引入为Category组件服务的reducer
// import category from './category'
//汇总所有的reducer，变为一个总的reducer
export default combineReducers({
  user:login,
  // admin
  // product,
  // category
})
