import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Image, Button } from "antd-mobile";
import Telphone from "../../../image/talentResumeImg/telphone.png";
import { resumeDetails } from "../../../services/index";
import "./index.less";

function TalentResume() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const id = state?.id;
  const [res, setRes] = useState({});
  const [intention, setIntention] = useState([]);
  useEffect(() => {
    fn();
  }, []);
  const fn = async () => {
    let res = await resumeDetails(id);
    setRes(res?.data);
    setIntention(res?.data?.job_intention);
  };

  // var end = intention[intention?.length - 1];
  var end = intention[intention?.length - 1];
  return (
    <div className="talentResume">
      <div className="talentResumeLineOne flexBoxBetween">
        <div className="lineOneLf">
          {/* <img></img> */}
          <Image
            src={res?.avatar || res?.headimgurl}
            style={{ width: "1.3rem", height: "1.3rem", borderRadius: "10px" }}
            fit="fill"
          />
        </div>
        <div className="lineOneRg">
          <div className="name">{res?.full_name}</div>
          <div className="condition">
            <span className="sex">{res?.sex === 1 ? "男" : "女"}</span>
            <span className="age">{res?.age}岁</span>
            <span className="education">{res?.education_name}</span>
          </div>
          <div className="telphoneDetails">
            <span className="telImg">
              <img style={{ width: ".26rem" }} src={Telphone} alt="" />
            </span>
            {res?.phone}
          </div>
          <div></div>
        </div>
      </div>
      <div className="educationExperience">
        <div className="title">教育经历</div>
        <div className="details flexBoxBetween">
          <span className="company">{res?.graduate_school}</span>
          <span className="time">{res?.graduate_time}</span>
        </div>
      </div>
      <div className="workExperience">
        <div className="title">就业经历</div>
        {res?.undergo?.map((i) => (
          <div className="details" key={i?.id}>
            <div className="flexBoxBetween">
              <span className="company">{i?.company_name}</span>
              <span className="time">
                {i?.work_start_time}-{i?.work_end_time}
              </span>
            </div>
            <div className="position">{i?.office_name}</div>
            <div className="contant">{i?.job_content}</div>
          </div>
        ))}
      </div>
      <div className="skillsfile">
        <div className="title">技能档案</div>
        <div className="contant">{res?.skill_name}</div>
        <div className="avaterWrap">
          {res?.skill_img?.map((i, index) => (
            <img key={index} className="avater" src={i} alt="" />
          ))}
        </div>
      </div>
      <div className="searchWork">
        <div className="title">求职意向</div>
        {res?.job_intention?.map((i) => (
          <div key={i?.id} className="workCommand">
            <span>【{i?.province_name}】</span>
            <span>【{i?.city_name}】</span>
            <span>{i?.expect_jon === 1 ? "家庭护理员" : "养老护理员"}</span>
            <span>{i?.wages_name}</span>
          </div>
        ))}
      </div>
      <Button
        block
        className="chatButton"
        onClick={() => {
          navigate("/employchat", {
            state: {
              job_id: end?.id,
            },
          });
        }}
        type="submit"
        color="primary"
      >
        立即沟通
      </Button>
    </div>
  );
}

export default TalentResume;
