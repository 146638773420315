import React, { useState } from "react";
import { ImageUploader, Dialog } from "antd-mobile";
import { pictureUpload } from "../../services/index";
import Upload from "../../image/formImg/upload.png";

const CustomUploadButton = (props) => {
  const [fileList, setFileList] = useState([]);
  const { params } = props;
  const mockUpload = async (files) => {
    const formData = new FormData();
    formData.append("file", files);
    let response = await pictureUpload(formData);
    return {
      url: response?.data?.url,
    };
  };
  const urlValues = fileList
    .map((i) => {
      return i.url;
    })
    .join(",");
  const subData = { [params]: urlValues };
  props?.getPicture(subData);
  return (
    <ImageUploader
      style={{ "--cell-size": "1.2rem" }}
      value={fileList}
      maxCount={4}
      onChange={setFileList}
      upload={mockUpload}
      onDelete={() => {
        return Dialog.confirm({
          content: "是否确认删除",
        });
      }}
    >
      <div
        style={{
          width: "1.2rem",
          height: "1.2rem",
          backgroundColor: "#f5f5f5",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#999999",
        }}
      >
        <img
          style={{ width: "1.2rem", height: "1.2rem" }}
          alt=""
          src={Upload}
        />
      </div>
    </ImageUploader>
  );
};

export default CustomUploadButton;
