import React from "react";
import { Routes, Route } from "react-router-dom";
import Index from "./views/Index";
import Login from "./views/Login";
import Assistant from "./views/Index/Assistant";
import Employ from "./views/Index/Employ";
import Recruit from "./views/Index/Recruit";
import Students from "./views/Index/Students";
import MyAward from "./views/Index/MyAward";
import EntryAward from "./views/Index/EntryAward";
import StationAward from "./views/Index/StationAward";
import TalentAward from "./views/Index/TalentAward";
import RentPurchaseAward from "./views/Index/RentPurchaseAward";
import CompetitionAward from "./views/Index/CompetitionAward";
import RankAward from "./views/Index/RankAward";
import TrainAward from "./views/Index/TrainAward";
import StudentsApply from "./views/Index/StudentsApply";
import EmployApply from "./views/Index/EmployApply";
import ApplyTrain from "./views/Index/ApplyTrain";
import DemandRelease from "./views/Index/DemandRelease";
import EditSearchWork from "./views/Index/edit/EditSearchWork";
import MyInfo from "./views/Index/MyInfo";
import MyResume from "./views/Index/MyResume";
import RecruitMarket from "./views/Index/RecruitMarket";
import WorkDetail from "./views/Index/WorkDetail";
import Chat from "./views/Index/Chat";
import TalentMarket from "./views/Index/TalentMarket";
import TalentResume from "./views/Index/TalentResume";
import MyTrain from "./views/Index/MyTrain";
import Examination from "./views/Index/Examination";
import MockExamination from "./views/Index/MockExamination";
import Collection from "./views/Index/Collection";
import RecordCourse from "./views/Index/RecordCourse";
import TestAppoint from "./views/Index/TestAppoint";
import AdmissionTicket from "./views/Index/AdmissionTicket";
import ApplySuccess from "./views/Index/ApplySuccess";
import ApplyTips from "./views/Index/ApplyTips";
import ApplyTipsToPage from "./views/Index/ApplyTipsToPage";
import AwardPolicy from "./views/Index/AwardPolicy";
import CollectDetails from "./views/Index/CollectDetails";
import TestDetails from "./views/Index/TestDetails";
import DemandCommunication from "./views/Index/DemandCommunication";
import EmployChat from "./views/Index/EmployChat";
import MyInfoDetails from "./views/Index/MyInfoDetails";
import OrderTest from "./views/Index/OrderTest";

import "./app.less";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" exact to="/login" element={<Login />} />
        <Route path="/index" element={<Index />} />
        <Route path="/assistant" element={<Assistant />} />
        <Route path="/employ" element={<Employ />} />
        <Route path="/recruit" element={<Recruit />} />
        <Route path="/students" element={<Students />} />
        <Route path="/myaward" element={<MyAward />} />
        <Route path="/entryaward" element={<EntryAward />} />
        <Route path="/stationaward" element={<StationAward />} />
        <Route path="/talentaward" element={<TalentAward />} />
        <Route path="/rentpurchaseaward" element={<RentPurchaseAward />} />
        <Route path="/competitionaward" element={<CompetitionAward />} />
        <Route path="/rankaward" element={<RankAward />} />
        <Route path="/trainaward" element={<TrainAward />} />
        <Route path="/studentsapply" element={<StudentsApply />} />
        <Route path="/employapply" element={<EmployApply />} />
        <Route path="/applytrain" element={<ApplyTrain />} />
        <Route path="/demandrelease" element={<DemandRelease />} />
        <Route path="/editsearchwork" element={<EditSearchWork />} />
        <Route path="/myinfo" element={<MyInfo />} />
        <Route path="/myresume" element={<MyResume />} />
        <Route path="/recruitmarket" element={<RecruitMarket />} />
        <Route path="/recruitmarket/workdetail" element={<WorkDetail />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/talentmarket" element={<TalentMarket />} />
        <Route path="/talentresume" element={<TalentResume />} />
        <Route path="/mytrain" element={<MyTrain />} />
        <Route path="/examination" element={<Examination />} />
        <Route path="/mockexamination" element={<MockExamination />} />
        <Route path="/collection" element={<Collection />} />
        <Route path="/recordCourse" element={<RecordCourse />} />
        <Route path="/testAppoint" element={<TestAppoint />} />
        <Route path="/admissionticket" element={<AdmissionTicket />} />
        <Route path="/applysuccess" element={<ApplySuccess />} />
        <Route path="/applytips" element={<ApplyTips />} />
        <Route path="/applytipstopage" element={<ApplyTipsToPage />} />
        <Route path="/awardpolicy" element={<AwardPolicy />} />
        <Route path="/collectdetails" element={<CollectDetails />} />
        <Route path="/testdetails" element={<TestDetails />} />
        <Route path="/DemandCommunication" element={<DemandCommunication />} />
        <Route path="/employchat" element={<EmployChat />} />
        <Route path="/myinfodetails" element={<MyInfoDetails />} />
        <Route path="/ordertest" element={<OrderTest />} />
      </Routes>
    </div>
  );
}

export default App;
