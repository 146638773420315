import React, { useState } from "react";
import { Selector, TextArea } from "antd-mobile";
import "./index.less";

function Problem(props) {
  const { SubjectType, Options, initData } = props;
  const [value, setValue] = useState("");
  return (
    <div className="problem">
      {SubjectType === 1 ? (
        <>
          <div className="topic">
            <div className="topicTitle">
              <span className="title">{initData?.type_name}</span>
              <span className="titleValue">
                <span className="titleCurrent">{initData?.current_num}</span>/
                {initData?.total_num}
              </span>
            </div>
            <div className="topBackground"></div>
            <div className="subjectTitle">{initData?.title}</div>
            <div className="subjectOption">
              <Selector
                columns={1}
                style={{
                  "--border-radius": "100px",
                  "--border": "solid transparent 1px",
                  "--checked-border": "solid var(--adm-color-primary) 1px",
                  "--padding": "8px 24px",
                }}
                showCheckMark={false}
                options={Options}
                onChange={(arr, extend) => {
                  props.getAnswer(arr.join(","));
                }}
              />
            </div>
          </div>
        </>
      ) : SubjectType === 2 ? (
        <div className="topic">
          <div className="topicTitle">
            <span className="title">{initData?.type_name}</span>
            <span className="titleValue">
              <span className="titleCurrent">{initData?.current_num}</span>/
              {initData?.total_num}
            </span>
          </div>
          <div className="topBackground"></div>
          <div className="subjectTitle">{initData?.title}</div>
          <div className="subjectOption">
            <Selector
              showCheckMark={false}
              columns={1}
              options={Options}
              multiple={true}
              onChange={(arr, extend) => {
                props.getAnswer(arr.join(","));
              }}
            />
          </div>
        </div>
      ) : SubjectType === 4 ? (
        <div className="topic">
          <div className="topicTitle">
            <span className="title">{initData?.type_name}</span>
            <span className="titleValue">
              <span className="titleCurrent">{initData?.current_num}</span>/
              {initData?.total_num}
            </span>
          </div>
          <div className="topBackground"></div>
          <div className="subjectTitle">{initData?.title}</div>
          <div className="subjectjudge">
            <Selector
              showCheckMark={false}
              columns={1}
              options={Options}
              onChange={(arr, extend) => {
                props.getAnswer(arr.join(","));
              }}
            />
          </div>
        </div>
      ) : (
        //案例分析和简答题
        <>
          <div className="areaposition">
            <div className="topicTitlearea">
              <span className="title">{initData?.type_name}</span>
              <span className="titleValue">
                <span className="titleCurrent">{initData?.current_num}</span>/
                {initData?.total_num}
              </span>
            </div>
            <div className="topBackground"></div>
          </div>
          <div className="topic lastTopic">
            <div className="subjectTitle">{initData?.title}</div>
            <div className="subjectBriefAnswer">
              <div className="briefPicture">
                <div
                  className="briefContent"
                  dangerouslySetInnerHTML={{ __html: initData?.content }}
                ></div>
                {/* <div className="briefContent">{initData?.content}</div> */}
                {/* <img
                style={{ width: "6.3rem" }}
                className="briefPictureContent"
                src={mookPicture}
                alt=""
              /> */}
              </div>
              <TextArea
                showCount
                maxLength={250}
                placeholder="请输入内容"
                value={value}
                autoSize={{ minRows: 10, maxRows: 12 }}
                onChange={(val) => {
                  setValue(val);
                  props.getAnswer(val);
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Problem;
