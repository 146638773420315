import assistant from "../image/indexImage/assistant.png";
import recruit from "../image/indexImage/recruit.png";
import employ from "../image/indexImage/employ.png";
import students from "../image/indexImage/students.png";
import myInfo from "../image/assistantImg/myInfo.png";
import toTrain from "../image/assistantImg/toTrain.png";
import myTrain from "../image/assistantImg/myTrain.png";
import goWork from "../image/assistantImg/goWork.png";
import award from "../image/assistantImg/award.png";
import applyMoveInto from "../image/employImg/applyMoveInto.png";
import talentMarket from "../image/recruitImg/talentMarket.png";
import talentCommunication from "../image/recruitImg/talentCommunication.png";
import demandPublish from "../image/recruitImg/demandPublish.png";
import indexArrow from "../image/indexImage/indexArrow.png";
import arrow from "../image/assistantImg/arrow.png";
import entryAward from "../image/myawardImg/entryAward.png";
import stationAward from "../image/myawardImg/stationAward.png";
import talentAward from "../image/myawardImg/talentAward.png";
import rentPurchaseAward from "../image/myawardImg/rentPurchaseAward.png";
import competitionAward from "../image/myawardImg/competitionAward.png";
import rankAward from "../image/myawardImg/rankAward.png";
import trainAward from "../image/myawardImg/trainAward.png";
import recordCourse from "../image/myTrainImg/recordCourse.png";
import collection from "../image/myTrainImg/collection.png";
import subscribe from "../image/myTrainImg/subscribe.png";
export const data = {
  indexList: [
    {
      id: "indexList" + 0,
      avatar: assistant,
      avatarWidth: ".72rem",
      avatarHeight: ".72rem",
      title: "我是护理员",
      titleFontsize: ".36rem",
      description: "点击前往",
      descriptionFontsize: ".24rem",
      // "listItemOutermostBackground":"#7979da",
      listItemOutermostHeight: "1.7rem",
      // sideDecorateStyles: "#FF5F8C",
      isdisabled: "",
      itemMarginTop: ".3rem",
      // itemSpacingbackground: "#F7FBFF",
      itemArrow: indexArrow,
      itemJumpRouter: "/assistant",
    },
    {
      id: "indexList" + 1,
      avatar: recruit,
      avatarWidth: ".72rem",
      avatarHeight: ".72rem",
      title: "我要招聘",
      titleFontsize: ".36rem",
      description: "点击前往",
      descriptionFontsize: ".24rem",
      listItemOutermostHeight: "1.7rem",
      // sideDecorateStyles: "#34E4AA",
      // itemMarginTop: ".3rem",
      // itemSpacingbackground: "#F7FBFF",
      itemArrow: indexArrow,
      itemJumpRouter: "/employ",
    },
    {
      id: "indexList" + 2,
      avatar: employ,
      avatarWidth: ".72rem",
      avatarHeight: ".72rem",
      title: "我要雇人",
      titleFontsize: ".36rem",
      description: "点击前往",
      descriptionFontsize: ".24rem",
      listItemOutermostHeight: "1.7rem",
      // sideDecorateStyles: "#9586EB",
      isdisabled: "",
      itemMarginTop: ".3rem",
      // itemSpacingbackground: "#F7FBFF",
      itemArrow: indexArrow,
      itemJumpRouter: "/recruit",
    },
    {
      id: "indexList" + 3,
      avatar: students,
      avatarWidth: ".72rem",
      avatarHeight: ".72rem",
      title: "我要招生",
      titleFontsize: ".36rem",
      description: "点击前往",
      descriptionFontsize: ".24rem",
      listItemOutermostHeight: "1.7rem",
      // sideDecorateStyles: "#FFC166",
      // itemMarginTop: ".3rem",
      // itemSpacingbackground: "#F7FBFF",
      itemArrow: indexArrow,
      itemJumpRouter: "/students",
    },
  ],
  assistantInfoFailList: [
    {
      id: "assistantInfoFailList" + 0,
      avatar: myInfo,
      avatarWidth: ".71rem",
      avatarHeight: ".63rem",
      title: "我的信息",
      titleFontsize: ".34rem",
      listItemOutermostBackground: "#F5F7FB",
      listItemOutermostHeight: "1.4rem",
      sideDecorateStyles: "#F5F7FB",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#FFFFFF",
      itemArrow: arrow,
      itemJumpRouter: "/myinfo",
    },
    {
      id: "assistantInfoFailList" + 1,
      avatar: toTrain,
      avatarWidth: ".7rem",
      avatarHeight: ".6rem",
      title: "我要培训",
      titleFontsize: ".34rem",
      listItemOutermostBackground: "#F5F7FB",
      listItemOutermostHeight: "1.4rem",
      sideDecorateStyles: "#F5F7FB",
      isdisabled: "true",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#FFFFFF",
      itemArrow: arrow,
      itemJumpRouter: "/applytrain",
    },
    {
      id: "assistantInfoFailList" + 2,
      avatar: goWork,
      avatarWidth: ".72rem",
      avatarHeight: ".64rem",
      title: "我要就业",
      titleFontsize: ".34rem",
      listItemOutermostBackground: "#F5F7FB",
      listItemOutermostHeight: "1.4rem",
      sideDecorateStyles: "#F5F7FB",
      isdisabled: "true",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#FFFFFF",
      itemArrow: arrow,
    },
    {
      id: "assistantInfoFailList" + 3,
      avatar: award,
      avatarWidth: ".62rem",
      avatarHeight: ".67rem",
      title: "我的奖补",
      titleFontsize: ".34rem",
      listItemOutermostBackground: "#F5F7FB",
      listItemOutermostHeight: "1.4rem",
      sideDecorateStyles: "#F5F7FB",
      isdisabled: "true",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#FFFFFF",
      itemArrow: arrow,
    },
  ],
  assistantInfoSuccessList: [
    {
      id: "assistantInfoSuccessList" + 0,
      avatar: myInfo,
      avatarWidth: ".71rem",
      avatarHeight: ".63rem",
      title: "我的信息",
      titleFontsize: ".34rem",
      listItemOutermostBackground: "#F5F7FB",
      listItemOutermostHeight: "1.4rem",
      sideDecorateStyles: "#F5F7FB",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#FFFFFF",
      itemArrow: arrow,
      itemJumpRouter: "/myinfo",
    },
    {
      isChangeList: "yes",
      goapply: {
        id: "assistantInfoSuccessList" + 11,
        avatar: myTrain,
        avatarWidth: ".67rem",
        avatarHeight: ".71rem",
        title: "我要培训",
        titleFontsize: ".34rem",
        listItemOutermostBackground: "#F5F7FB",
        listItemOutermostHeight: "1.4rem",
        sideDecorateStyles: "#F5F7FB",
        isdisabled: "",
        itemMarginTop: ".2rem",
        itemSpacingbackground: "#FFFFFF",
        itemArrow: arrow,
        itemJumpRouter: "/applytrain",
      },
      gotrain: {
        id: "assistantInfoSuccessList" + 1,
        avatar: myTrain,
        avatarWidth: ".67rem",
        avatarHeight: ".71rem",
        title: "我的培训",
        titleFontsize: ".34rem",
        listItemOutermostBackground: "#F5F7FB",
        listItemOutermostHeight: "1.4rem",
        sideDecorateStyles: "#F5F7FB",
        isdisabled: "",
        itemMarginTop: ".2rem",
        itemSpacingbackground: "#FFFFFF",
        itemArrow: arrow,
        itemJumpRouter: "/mytrain",
      },
    },
    {
      id: "assistantInfoSuccessList" + 2,
      avatar: goWork,
      avatarWidth: ".72rem",
      avatarHeight: ".64rem",
      title: "我要就业",
      titleFontsize: ".34rem",
      listItemOutermostBackground: "#F5F7FB",
      listItemOutermostHeight: "1.4rem",
      sideDecorateStyles: "#F5F7FB",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#FFFFFF",
      itemArrow: arrow,
      itemJumpRouter: "/recruitmarket",
    },
    {
      id: "assistantInfoSuccessList" + 3,
      avatar: award,
      avatarWidth: ".62rem",
      avatarHeight: ".67rem",
      title: "我的奖补",
      titleFontsize: ".34rem",
      listItemOutermostBackground: "#F5F7FB",
      listItemOutermostHeight: "1.4rem",
      sideDecorateStyles: "#F5F7FB",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#FFFFFF",
      itemArrow: arrow,
      itemJumpRouter: "/myaward",
    },
    // {
    //   id: "assistantInfoSuccessList" + 4,
    //   avatar: demand,
    //   avatarWidth: ".64rem",
    //   avatarHeight: ".65rem",
    //   title: "我的需求",
    //   titleFontsize: ".34rem",
    //   listItemOutermostBackground: "#F5F7FB",
    //   listItemOutermostHeight: "1.4rem",
    //   sideDecorateStyles: "#F5F7FB",
    //   isdisabled: "",
    //   itemMarginTop: ".2rem",
    //   itemSpacingbackground: "#FFFFFF",
    //   itemArrow: arrow,
    // },
  ],
  employList: [
    {
      id: "employList" + 0,
      avatar: applyMoveInto,
      avatarWidth: ".66rem",
      avatarHeight: ".66rem",
      title: "申请入驻",
      titleFontsize: ".34rem",
      // "listItemOutermostBackground":"#7979da",
      listItemOutermostHeight: "1.3rem",
      // "sideDecorateStyles":"#7979da",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#F7FBFF",
      itemArrow: arrow,
      itemJumpRouter: "/employapply",
    },
  ],
  employListNoJump: [
    {
      id: "employList" + 0,
      avatar: applyMoveInto,
      avatarWidth: ".66rem",
      avatarHeight: ".66rem",
      title: "申请入驻",
      titleFontsize: ".34rem",
      listItemOutermostHeight: "1.3rem",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#F7FBFF",
      itemArrow: arrow,
      itemJumpRouter: "",
    },
  ],

  recruitList: [
    {
      id: "recruitList" + 0,
      avatar: talentMarket,
      avatarWidth: ".99rem",
      avatarHeight: ".96rem",
      title: "人才市场",
      titleFontsize: ".34rem",
      // "listItemOutermostBackground":"#7979da",
      listItemOutermostHeight: "1.3rem",
      // "sideDecorateStyles":"#7979da",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#F7FBFF",
      itemArrow: arrow,
      itemJumpRouter: "/talentmarket",
    },
    {
      id: "recruitList" + 1,
      avatar: talentCommunication,
      avatarWidth: ".94rem",
      avatarHeight: ".88rem",
      title: "人才沟通",
      titleFontsize: ".34rem",
      // "listItemOutermostBackground":"#7979da",
      listItemOutermostHeight: "1.3rem",
      // "sideDecorateStyles":"#7979da",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#F7FBFF",
      itemArrow: arrow,
      itemJumpRouter: "/demandcommunication",
    },
    {
      id: "recruitList" + 2,
      avatar: demandPublish,
      avatarWidth: ".98rem",
      avatarHeight: ".98rem",
      title: "需求发布",
      titleFontsize: ".34rem",
      // "listItemOutermostBackground":"#7979da",
      listItemOutermostHeight: "1.3rem",
      // "sideDecorateStyles":"#7979da",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#F7FBFF",
      itemArrow: arrow,
      itemJumpRouter: "/demandrelease",
    },
  ],
  recruitListStatus: [
    {
      recruitListStatus: "tips",
      id: "recruitListStatus" + 0,
      avatar: talentMarket,
      avatarWidth: ".99rem",
      avatarHeight: ".96rem",
      title: "人才市场",
      titleFontsize: ".34rem",
      // "listItemOutermostBackground":"#7979da",
      listItemOutermostHeight: "1.3rem",
      // "sideDecorateStyles":"#7979da",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#F7FBFF",
      itemArrow: arrow,
    },
    {
      recruitListStatus: "tips1",
      id: "recruitListStatus" + 1,
      avatar: talentCommunication,
      avatarWidth: ".94rem",
      avatarHeight: ".88rem",
      title: "人才沟通",
      titleFontsize: ".34rem",
      // "listItemOutermostBackground":"#7979da",
      listItemOutermostHeight: "1.3rem",
      // "sideDecorateStyles":"#7979da",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#F7FBFF",
      itemArrow: arrow,
    },
    {
      id: "recruitListStatus" + 2,
      avatar: demandPublish,
      avatarWidth: ".98rem",
      avatarHeight: ".98rem",
      title: "需求发布",
      titleFontsize: ".34rem",
      // "listItemOutermostBackground":"#7979da",
      listItemOutermostHeight: "1.3rem",
      // "sideDecorateStyles":"#7979da",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#F7FBFF",
      itemArrow: arrow,
      itemJumpRouter: "/demandrelease",
    },
  ],
  studentsList: [
    {
      id: "studentsList" + 0,
      avatar: applyMoveInto,
      avatarWidth: ".66rem",
      avatarHeight: ".66rem",
      title: "申请入驻",
      titleFontsize: ".34rem",
      listItemOutermostHeight: "1.3rem",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#F7FBFF",
      itemArrow: arrow,
      itemJumpRouter: "/studentsapply",
    },
  ],
  studentsListNoJump: [
    {
      id: "studentsList" + 0,
      avatar: applyMoveInto,
      avatarWidth: ".66rem",
      avatarHeight: ".66rem",
      title: "申请入驻",
      titleFontsize: ".34rem",
      listItemOutermostHeight: "1.3rem",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#F7FBFF",
      itemArrow: arrow,
      itemJumpRouter: "",
    },
  ],
  myAwardList: [
    {
      id: 1,
      avatar: entryAward,
      avatarWidth: ".7rem",
      avatarHeight: ".7rem",
      title: "入职奖补申请",
      titleFontsize: ".34rem",
      listItemOutermostHeight: "1.3rem",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#F7FBFF",
      itemArrow: arrow,
      itemJumpRouter: "/entryaward",
      JumpRouter: "/entryaward",
    },
    {
      id: 2,
      avatar: stationAward,
      avatarWidth: ".7rem",
      avatarHeight: ".7rem",
      title: "岗位奖补申请",
      titleFontsize: ".34rem",
      listItemOutermostHeight: "1.3rem",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#F7FBFF",
      itemArrow: arrow,
      itemJumpRouter: "/stationaward",
      JumpRouter: "/stationaward",
    },
    {
      id: 3,
      avatar: talentAward,
      avatarWidth: ".7rem",
      avatarHeight: ".7rem",
      title: "人才奖补申请",
      titleFontsize: ".34rem",
      listItemOutermostHeight: "1.3rem",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#F7FBFF",
      itemArrow: arrow,
      itemJumpRouter: "/talentaward",
      JumpRouter: "/talentaward",
    },
    {
      id: 4,
      avatar: rentPurchaseAward,
      avatarWidth: ".7rem",
      avatarHeight: ".7rem",
      title: "租购房奖补申请",
      titleFontsize: ".34rem",
      listItemOutermostHeight: "1.3rem",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#F7FBFF",
      itemArrow: arrow,
      itemJumpRouter: "",
      JumpRouter: "/rentpurchaseaward",
    },
    {
      id: 5,
      avatar: competitionAward,
      avatarWidth: ".7rem",
      avatarHeight: ".7rem",
      title: "竞赛奖励申请",
      titleFontsize: ".34rem",
      listItemOutermostHeight: "1.3rem",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#F7FBFF",
      itemArrow: arrow,
      itemJumpRouter: "/competitionaward",
      JumpRouter: "/competitionaward",
    },
    {
      id: 6,
      avatar: rankAward,
      avatarWidth: ".7rem",
      avatarHeight: ".7rem",
      title: "等级鉴定奖励申请",
      titleFontsize: ".34rem",
      listItemOutermostHeight: "1.3rem",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#F7FBFF",
      itemArrow: arrow,
      itemJumpRouter: "/rankaward",
      JumpRouter: "/rankaward",
    },
    {
      id: 7,
      avatar: trainAward,
      avatarWidth: ".7rem",
      avatarHeight: ".7rem",
      title: "培训鉴定奖励申请",
      titleFontsize: ".34rem",
      listItemOutermostHeight: "1.3rem",
      isdisabled: "",
      itemMarginTop: ".2rem",
      itemSpacingbackground: "#F7FBFF",
      itemArrow: arrow,
      itemJumpRouter: "/trainaward",
      JumpRouter: "/trainaward",
    },
  ],
  subjectList: [
    {
      id: "subjectList" + 1,
      type: "normal",
      title: "录播课",
      describe: "更多课程内容",
      avatar: recordCourse,
      itemJumpRouter: "/recordCourse",
    },
    {
      id: "subjectList" + 2,
      type: "normal",
      title: "收藏",
      describe: "查看收藏课程",
      avatar: collection,
      itemJumpRouter: "/collection",
    },
    {
      id: "subjectList" + 3,
      type: "normal",
      title: "考试预约",
      describe: "点击预约考试",
      avatar: subscribe,
      itemJumpRouter: "/testAppoint",
    },
  ],
};
