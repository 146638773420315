import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Form, Input, Button, TextArea, DatePicker, Picker } from "antd-mobile";
import { GetYear } from "../../utils/method.js";
import TwoButton from "../TwoButton";
import PublicPicker from "../PublicPicker";
import CustomUploadButton from "../CustomUploadButton";
import "./index.less";

function InfoTableItem(props) {
  const [form] = Form.useForm();
  const {
    echoStartTime,
    echoEndTime,
    initData,
    echoIsTimerPicker,
    FormItemData,
    buttonsValue,
    basicColumns,
    trainLevelColumns,
    trainSchoolColumns,
    educationColumns,
    workCityOptions,
    expectPositionColumns,
    salaryRequireColumns,
    entryPositionColumns,
    rentalTypeColumns,
    prizeCertificateColumns,
    onFinish,
    twoButtonValues,
    getEducation,
    submitRelease,
    getentryPosition,
    getTimer1,
    getTimer,
    getSalaryRequire,
  } = props;
  const [value, setValue] = useState();
  const [applyButton, setApplyButton] = useState("submitApply");
  useEffect(() => {
    setInitData();
    changeSubmit(submitRelease);
  });
  const [pickerVisible, setPickerVisible] = useState(false);
  const [picker, setPicker] = useState(false);
  const getCheck = (paramsValue) => {
    props.getGetCheck(paramsValue);
  };
  const getRentalType = (v) => {
    props.getRentalType(v);
  };
  const getWorkCity = (v) => {
    props.getWorkCity(v);
  };

  const entryTime = () => {
    setPickerVisible(true);
  };
  const finishTime = () => {
    setPicker(true);
  };
  const changeSubmit = (param) => {
    setApplyButton(param);
  };
  const setInitData = () => {
    form.setFieldsValue({ ...initData });
  };
  const min = GetYear(-50);
  return (
    <div className="infoTableItem">
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
        footer={
          <Button block className="submitButton" type="submit" color="primary">
            {applyButton === "submitRelease"
              ? "提交发布"
              : applyButton === undefined
              ? "提交申请"
              : ""}
          </Button>
        }
      >
        {FormItemData?.map((item) => (
          <div className="publicFormItem" key={item.id}>
            {item?.itemState === "lowBase" ? (
              <div className="infoTableItemTitle">
                <span className="leftSide" />
                {item?.itemTitle}
              </div>
            ) : item?.itemState === "baseDiv" ? (
              <div className="baseDivBox" />
            ) : item?.itemState === "base" ? (
              <Form.Item
                name={item.itemName}
                label={item.itemLabel}
                rules={[
                  { required: item?.isRequired, message: item?.itemMessage },
                ]}
              >
                <Input placeholder={item.itemPlaceHolder} />
              </Form.Item>
            ) : item?.itemState === "basePro" ? (
              <>
                <span className="baseProStar" style={{ color: "#F93A42" }}>
                  {item?.isStarRequired}
                </span>
                <Form.Item
                  style={{ display: "inline-block" }}
                  label={item.itemLabel}
                  rules={[
                    { required: item?.isRequired, message: item?.itemMessage },
                  ]}
                ></Form.Item>
                <DatePicker
                  min={min}
                  precision="month"
                  visible={pickerVisible}
                  onClose={() => {
                    setPickerVisible(false);
                  }}
                  onConfirm={(v) => {
                    setValue(v);
                    props.getStartTimer(v);
                  }}
                >
                  {(value) =>
                    value ||
                    (echoStartTime && echoStartTime !== "undefined") ? (
                      <span className="pickerValues" onClick={entryTime}>
                        {value ? dayjs(value).format("YYYY-MM") : echoStartTime}
                      </span>
                    ) : echoStartTime && echoStartTime !== "undefined" ? (
                      <span className="pickerTips" onClick={entryTime}>
                        {echoStartTime}
                      </span>
                    ) : (
                      <span
                        className="pickerTips"
                        onClick={entryTime}
                        style={{ color: "#cccccc" }}
                      >
                        {item?.entryPlaceHolder}
                      </span>
                    )
                  }
                </DatePicker>
                <span className="infoPro">&nbsp;—&nbsp;</span>
                <DatePicker
                  min={min}
                  precision="month"
                  visible={picker}
                  onClose={() => {
                    setPicker(false);
                  }}
                  onConfirm={(v) => {
                    setValue(v);
                    props.getEndTimer(v);
                  }}
                >
                  {(value) =>
                    value || (echoEndTime && echoEndTime !== "undefined") ? (
                      <span className="pickerValues" onClick={finishTime}>
                        {value ? dayjs(value).format("YYYY-MM") : echoEndTime}
                      </span>
                    ) : echoEndTime && echoEndTime !== "undefined" ? (
                      <span className="pickerTips" onClick={finishTime}>
                        {echoEndTime}
                      </span>
                    ) : (
                      <span
                        className="pickerTips"
                        onClick={finishTime}
                        style={{ color: "#cccccc" }}
                      >
                        {item?.finishPlaceHolder}
                      </span>
                    )
                  }
                </DatePicker>
              </>
            ) : item?.itemState === "twoSalary" ? (
              <>
                <span
                  className="baseProStar"
                  style={{
                    color: "#F93A42",
                    position: "relative",
                    left: ".32rem",
                    marginLeft: "-.09rem",
                  }}
                >
                  {item?.isStarRequired}
                </span>
                <Form.Item
                  style={{ display: "inline-block" }}
                  label={item.itemLabel}
                  rules={[{ message: item?.itemMessage }]}
                >
                  <Picker
                    columns={props.startSalaryColumns}
                    visible={pickerVisible}
                    onClose={() => {
                      setPickerVisible(false);
                    }}
                    // value={value}
                    onConfirm={(v) => {
                      setValue(v);
                      props.getStartSalary(v);
                    }}
                  >
                    {(value) =>
                      value.length > 0 ? (
                        <span className="pickerValues" onClick={entryTime}>
                          {value?.map((i) => i?.label) ?? "未选择"}
                        </span>
                      ) : (
                        <span
                          className="pickerTips"
                          onClick={entryTime}
                          style={{ color: "#cccccc" }}
                        >
                          {item?.entryPlaceHolder}
                        </span>
                      )
                    }
                  </Picker>
                  <span className="infoPro">&nbsp;—&nbsp;</span>
                  <Picker
                    columns={props.endSalaryColumns}
                    visible={picker}
                    onClose={() => {
                      setPicker(false);
                    }}
                    // value={value}
                    onConfirm={(v) => {
                      setValue(v);
                      props.getEndSalary(v);
                    }}
                  >
                    {(value) =>
                      value.length > 0 ? (
                        <span className="pickerValues" onClick={finishTime}>
                          {value?.map((i) => i?.label) ?? "未选择"}
                        </span>
                      ) : (
                        <span
                          className="pickerTips"
                          onClick={finishTime}
                          style={{ color: "#cccccc" }}
                        >
                          {item?.finishPlaceHolder}
                        </span>
                      )
                    }
                  </Picker>
                </Form.Item>
              </>
            ) : item?.itemState === "baseTextArea" ? (
              <Form.Item
                name={item.itemName}
                label={item.itemLabel}
                rules={[
                  { required: item?.isRequired, message: item?.itemMessage },
                ]}
              >
                <TextArea placeholder={item?.itemPlaceHolder} />
              </Form.Item>
            ) : item?.itemState === "baseClickButtonSex" ? (
              <div className="buttonForm">
                {item?.isRequired === true ? (
                  <span
                    style={{
                      color: "#ff3141",
                      position: "absolute",
                      // left: ".18rem",
                      top: ".3rem",
                    }}
                  >
                    *
                  </span>
                ) : (
                  ""
                )}
                <Form.Item
                  style={{ display: "inline-block", width: "100%" }}
                  // name={item.itemName}
                  label={item.itemLabel}
                >
                  <TwoButton
                    twoButton={twoButtonValues}
                    buttonsValue={buttonsValue}
                    getCheck={getCheck}
                  />
                </Form.Item>
              </div>
            ) : item?.itemState === "baseClick" ? (
              <PublicPicker
                formatData={props.formatData}
                precision={props.precision}
                formatData1={props.formatData1}
                precision1={props.precision1}
                item={item}
                echoIsTimerPicker={echoIsTimerPicker}
                basicColumns={basicColumns}
                trainLevelColumns={trainLevelColumns}
                trainSchoolColumns={trainSchoolColumns}
                educationColumns={educationColumns}
                workCityOptions={workCityOptions}
                expectPositionColumns={expectPositionColumns}
                salaryRequireColumns={salaryRequireColumns}
                entryPositionColumns={entryPositionColumns}
                rentalTypeColumns={rentalTypeColumns}
                prizeCertificateColumns={prizeCertificateColumns}
                getTrainSchool={props.getTrainSchool}
                getTrainLevel={props.getTrainLevel}
                getTimer={getTimer}
                getTimer1={getTimer1}
                getEducation={getEducation}
                getSalaryRequire={getSalaryRequire}
                getRentalType={getRentalType}
                getWorkCity={getWorkCity}
                getentryPosition={getentryPosition}
              />
            ) : (
              <Form.Item
                // name={item.itemName}
                // label={item.itemLabel}
                rules={[
                  { required: item?.isRequired, message: item?.itemMessage },
                ]}
              >
                <div className="uploadTop">
                  <span className="uploadStar" style={{ color: "#F93A42" }}>
                    {item?.isStarRequired}
                  </span>
                  <span className="uploadTitle">{item.itemLabel}</span>
                  <span className="uploadTips">{item.itemImgTitle}</span>
                </div>
                <div className="uploadWrap">
                  <CustomUploadButton
                    params={item?.itemName}
                    getPicture={props.getPicture}
                  />
                </div>
              </Form.Item>
            )}
          </div>
        ))}
      </Form>
    </div>
  );
}

export default InfoTableItem;
