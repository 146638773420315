import React from "react";
import { InfiniteScroll } from "antd-mobile";
import TimeImg from "../../image/recordCourseImg/time.png";
import "./index.less";

function CourseList(props) {
  const { data, loadMore, hasMore } = props;

  return (
    <div className="courseList">
      <div>
        {data?.map((i) => (
          <div
            className="courseListItem flexBoxBetween"
            key={i.id}
            onClick={() => props.handleRead({ id: i?.id, video: i?.video })} //回调
          >
            <div className="itemLeft">
              <img className="itemPicture" src={i.video_img} alt="" />
              <div className="pictureTime flexBoxBetween">
                <img className="itemTimeImg" src={TimeImg} alt="" />
                <span className="itemTime">{i.duration_show}</span>
              </div>
            </div>
            <div className="itemRight">
              <div className="itemContent">{i.title}</div>
              <div className="itemTips">
                {i?.tag?.map((item, index) => (
                  <span key={index} className="itemTipsSpan">
                    {item.name}
                  </span>
                ))}
              </div>
              {i.is_cat === 2 ? (
                <div className="read isRead">已看过</div>
              ) : (
                <div className="read unRead">未看过</div>
              )}
            </div>
          </div>
        ))}
      </div>
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
    </div>
  );
}

export default CourseList;
