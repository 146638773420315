import React from "react";
import "./index.less";

function PublicTips(props) {
  const { data } = props;
  return (
    <div className="publicTips">
      <img src={data?.image} alt="" />
      <div className="tipsInfo">{data?.title}</div>
    </div>
  );
}

export default PublicTips;
