import React from "react";

import "./index.less";

function TwoButton(props) {
  const { twoButton, buttonsValue } = props;
  const checkedHand = (param) => {
    return () => {
      props.getCheck(param);
    };
  };
  return (
    <div className="twoButton">
      <div className="awardIsEntryButton">
        <div
          onClick={checkedHand(twoButton.checkValue)}
          className={
            buttonsValue === twoButton.checkValue
              ? "checkStyle"
              : "notCheckStyle"
          }
        >
          {twoButton.checkValueText}
        </div>
        <div
          onClick={checkedHand(twoButton.anotherValue)}
          className={
            buttonsValue === twoButton.anotherValue
              ? "checkStyle"
              : "notCheckStyle"
          }
        >
          {twoButton.anotherValueText}
        </div>
      </div>
    </div>
  );
}

export default TwoButton;
