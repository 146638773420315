import { postRequest } from "./request";

// 用户登录鉴权模拟
export const reqLogin = (id) => {
  return postRequest("/auth/frontend_login", { id });
};
// 用户登录鉴权微信
export const wechat = (params) => {
  return postRequest("/auth/wechat", params);
};
// 我的信息审核状态
export const myInfoStatus = () => postRequest("/api/member/status");
// 我的信息提交
export const infoSubmit = (params) => postRequest("/api/member/save", params);
// 我的信息回显
export const getMyInfoApi = () => postRequest("/api/member/get_user_info");
// 护理员培训申请
export const trainApplyApi = (params) =>
  postRequest("/api/my_training/apply", params);
// 护理员申请状态
export const myTrainStatus = () => postRequest("/api/my_training/apply_info");
//我的培训首页
export const myTrainApi = () => postRequest("/api/my_training/index");
//录播课列表
export const recordCourseList = (data) => postRequest("/api/course/list", data);
// 录播课点击播放
export const recordCourseClick = (id) =>
  postRequest("/api/course/click", { id });
// 顺序练习获取题目
export const getOrderTrain = () => postRequest("/api/exercise/order_exercise");
// 顺序练习收藏
export const collectApi = (params) =>
  postRequest("/api/exercise/collect", params);
// //顺序练习收藏列表
// export const collectList = (id) => postRequest("/api/exercise/collect", { id });
// 顺序练习分析
export const analysisApi = (params) =>
  postRequest("/api/exercise/analysis", params);
// 顺序练习提交
export const submitApi = (params) =>
  postRequest("/api/exercise/submit", params);
// 考试预约-报名列表
export const examAppoint = (params) => postRequest("/api/enroll/list", params);
// 考试预约-点击报名
export const enrollApi = (id) => postRequest("/api/enroll/enroll", { id });
// 考试预约-详情
export const enrollDetailsApi = (id) =>
  postRequest("/api/enroll/detail", { id });
// 考试预约提示
export const tipsApi = (params) => postRequest("/api/member/tips", params);
// 养老机构申请入驻
export const nursingApi = (params) =>
  postRequest("/api/settle/nursing", params);
// 培训机构申请入驻
export const cultivateApi = (params) =>
  postRequest("/api/settle/cultivate", params);
// 申请状态查询
export const applyStatus = () => postRequest("/api/settle/status");
// 申请成功
export const applySuccess = (params) =>
  postRequest("/api/settle/success", params);
// 我的奖补-基础信息
export const myAwardBaseInfo = () => postRequest("/api/prize/config_data");
// 我的奖补-状态及政策
export const statusPolicy = () => postRequest("/api/prize/my_prize");
// 租购房奖补
export const rentApi = (params) => postRequest("/api/prize/lease", params);
// 入职奖补
export const entryApi = (params) => postRequest("/api/prize/entry", params);
// 培训鉴定奖补
export const trainApi = (params) => postRequest("/api/prize/training", params);
// 等级鉴定
export const rankApi = (params) => postRequest("/api/prize/grade", params);
// 竞赛奖励
export const competiteApi = (params) =>
  postRequest("/api/prize/contest", params);
// 人才奖补
export const talentApi = (params) => postRequest("/api/prize/talent", params);
//岗位奖补
export const stationApi = (params) => postRequest("/api/prize/station", params);
// 收藏列表
export const collectListApi = (params) =>
  postRequest("/api/collect/list", params);
// 收藏获取题目
export const collectSubject = (params) =>
  postRequest("/api/collect/exercise", params);
// 收藏列表分析
export const collectAnalysis = (params) =>
  postRequest("/api/collect/analysis", params);
// 收藏页面点击收藏
export const clickCollect = (params) =>
  postRequest("/api/collect/collect", params);
// 收藏页面提交
export const collectSubmit = (params) =>
  postRequest("/api/collect/submit", params);
// 头像上传
export const pictureUpload = (params) => postRequest("/api/image", params);
// 头像上传base64
export const pictureUploadBase = (params) =>
  postRequest("/upload/image_base64", params);
// 需求发布状态查询
export const needsStatus = (params) =>
  postRequest("/api/employ/status", params);
// 岗位发布信息获取
export const getdemandApi = (params) =>
  postRequest("/api/employ/config_data", params);
// 需求发布信息提交
export const needsSubmit = (params) => postRequest("/api/employ/save", params);
// 人才市场列表
export const peopleMarket = (params) => postRequest("/api/employ/list", params);
// 人才市场简历详情
export const resumeDetails = (id) => postRequest("/api/employ/detail", { id });
// 下载准考证
export const download = (id) => postRequest("/api/enroll/download", { id });
// 我要就业-岗位列表
export const jobList = (params) => postRequest("/api/job_hunting/list", params);
// 岗位详情
export const jobDetails = (id) =>
  postRequest("/api/job_hunting/detail", { id });
// 我的在线简历
export const resume = (id) => postRequest("/api/resume/my_resume", { id });
// 简历发布
export const publishApi = (params) =>
  postRequest("/api/resume/publish", params);
// 获取求职意向
export const getJob = (params) =>
  postRequest("/api/resume/job_intention", params);
// 地区列表
export const regionList = () => postRequest("/api/resume/region");
// 求职意向提交
export const jobSubmit = (params) =>
  postRequest("/api/resume/job_intention_submit", params);
// 我要就业沟通记录
export const communicateRecord = (params) =>
  postRequest("/api/message/list", params);
// 对应岗位或记录的所有对话内容
export const comRecDetails = (params) =>
  postRequest("/api/message/logs", params);
// 发送留言
export const sendMessage = (params) =>
  postRequest("/api/message/create", params);
// 未读消息标识
export const isReadStatus = (id) =>
  postRequest("/api/message/set_read_status", { id });
// 人才市场沟通记录
export const talentComRec = () => postRequest("/api/message/employer_record");
// 我要雇人发送留言
export const talentSendMessage = (params) =>
  postRequest("/api/message/employer_create", params);
// 我要雇人聊天页面数据
export const empChat = (params) =>
  postRequest("/api/message/employer_logs", params);
//模拟测试首页
export const mockTest = () => postRequest("/api/simulate");
// 随机获取一道试卷对应的试题
export const mockQuestions = (params) =>
  postRequest("/api/simulate/topic", params);
// 用户答题
export const doneSubmit = (params) =>
  postRequest("/api/simulate/do_question", params);
// 模拟测试返回上一题
export const toUpward = (params) =>
  postRequest("/api/simulate/prev_topic", params);
// 提交试卷
export const subTest = (params) => postRequest("/api/simulate/submit", params);
//模拟测试试题解析
export const TestDetail = (params) =>
  postRequest("/api/simulate/analysis", params);
// 删除留言记录
export const deleteRecord = (id) =>
  postRequest(`/api/message/delete_record/${id}`);
// 模拟考试收藏
export const testCollect = (params) =>
  postRequest("/api/simulate/collect", params);
