import React from "react";
import Idea from "../../image/orderTrain/idea.png";
import "./index.less";

function ProblemAnswer(props) {
  const { SubjectType, AnswerOptions, answerData } = props;
  return (
    <div className="problemAnswer">
      {SubjectType === 1 || SubjectType === 2 ? (
        <>
          <div className="topic topicAnswerChoice">
            <div className="topicTitle">
              <span className="title">{answerData?.type_name}</span>
              <span className="titleValue">
                <span className="titleCurrent">{answerData?.current_num}</span>/
                {answerData?.total_num}
              </span>
            </div>
            <div className="topBackground"></div>
            <div className="subjectBriefwrap">
              <div className="subjectTitle">{answerData?.title}</div>
              <div className="subjectOption">
                {AnswerOptions?.map((i) =>
                  i?.is_select === 1 ? (
                    <div className="optionItem trueAnswer" key={i.id}>
                      {i.label}
                    </div>
                  ) : i?.is_select === 2 ? (
                    <div className="optionItem falseAnswer" key={i.id}>
                      {i.label}
                    </div>
                  ) : (
                    <div className="optionItem" key={i.id}>
                      {i.label}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="answer">
            <div className="tureAnswer">
              <span className="tureAnswerTitle">正确答案</span>
              <span className="tureAnswerContent">
                {answerData?.correct_content}
              </span>
            </div>
            <div className="thisSkills">
              <span>
                <img className="thisSkillsTitle" src={Idea} alt="" />
              </span>
              <span className="thisSkillsContent">本题技巧</span>
            </div>
            <div
              className="answerAnalysis"
              dangerouslySetInnerHTML={{
                __html: answerData?.analysis,
              }}
            ></div>
          </div>
        </>
      ) : SubjectType === 4 ? (
        <>
          <div className="topic topicAnswerjudge">
            <div className="topicTitle">
              <span className="title">{answerData?.type_name}</span>
              <span className="titleValue">
                <span className="titleCurrent">{answerData?.current_num}</span>/
                {answerData?.total_num}
              </span>
            </div>
            <div className="topBackground"></div>
            <div className="subjectBriefwrap">
              <div className="subjectTitle">{answerData?.title}</div>
              <div className="subjectOption">
                {AnswerOptions?.map((i) =>
                  i?.is_select === 1 ? (
                    <div className="optionItem trueAnswer" key={i.id}>
                      {i.label}
                    </div>
                  ) : i?.is_select === 2 ? (
                    <div className="optionItem falseAnswer" key={i.id}>
                      {i?.label}
                    </div>
                  ) : (
                    <div className="optionItem" key={i?.id}>
                      {i?.label}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="answer answerJudge">
            <div className="tureAnswer">
              <span className="tureAnswerTitle">正确答案</span>
              <span className="tureAnswerContent">
                {answerData?.correct_content}
              </span>
            </div>
            <div className="thisSkills">
              <span>
                <img className="thisSkillsTitle" src={Idea} alt="" />
              </span>
              <span className="thisSkillsContent">本题技巧</span>
            </div>
            <div
              className="answerAnalysis"
              dangerouslySetInnerHTML={{
                __html: answerData?.analysis,
              }}
            ></div>
          </div>
        </>
      ) : (
        <>
          <div className="topic topicAnswerBrief">
            <div className="topicTitle">
              <span className="title">{answerData?.type_name}</span>
              <span className="titleValue">
                <span className="titleCurrent">{answerData?.current_num}</span>/
                {answerData?.total_num}
              </span>
            </div>
            <div className="topBackground"></div>
            <div className="subjectBriefwrap">
              <div className="subjectTitle">{answerData?.title}</div>
              <div className="subjectBriefAnswer">
                <div className="briefPicture">
                  {/* 如果图片存在，设置对应高度动态判断 */}
                  {/* <div className="briefContent">{answerData?.content}</div> */}
                  <div
                    className="briefContent"
                    dangerouslySetInnerHTML={{
                      __html: answerData?.content,
                    }}
                  ></div>
                  {/* <img
                  style={{ width: "6.3rem" }}
                  className="briefPictureContent"
                  src={mookPicture}
                  alt=""
                /> */}
                </div>
                <div className="subjectBriefAnswerContent">
                  {Object.prototype.toString.call(answerData?.answer) ===
                  "[object String]"
                    ? answerData?.answer
                    : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="answer answerBrief">
            <div className="tureAnswer">
              <span className="tureAnswerTitle">正确答案</span>
              <div
                className="tureBriefAnswerContent"
                dangerouslySetInnerHTML={{
                  __html: answerData?.correct_content,
                }}
              ></div>
            </div>
            <div className="thisSkills">
              <span>
                <img className="thisSkillsTitle" src={Idea} alt="" />
              </span>
              <span className="thisSkillsContent">本题技巧</span>
            </div>
            <div
              className="answerAnalysis"
              dangerouslySetInnerHTML={{
                __html: answerData?.analysis,
              }}
            ></div>
          </div>
        </>
      )}
    </div>
  );
}

export default ProblemAnswer;
