import React from "react";
import Idea from "../../image/orderTrain/idea.png";
import "./index.less";

function TestProblemAnswer(props) {
  const { SubjectType, AnswerOptions, answerData } = props;
  return (
    <div className="testProblemAnswer">
      {SubjectType === 1 ? (
        <>
          <div className="topic topicAnswerChoice">
            <div className="topicTitle">
              <span className="title">单选题</span>
              <span className="titleValue">
                <span className="titleCurrent">{answerData?.done}</span>/
                {answerData?.count}
              </span>
            </div>
            <div className="topBackground"></div>
            <div className="subjectBriefwrap">
              <div className="subjectTitle">{answerData?.title}</div>
              <div className="subjectOption">
                {AnswerOptions?.map((i) =>
                  i?.is_select === 1 ? (
                    <div className="optionItem falseAnswer" key={i.id}>
                      {i.label}
                    </div>
                  ) : i?.is_select === 2 ? (
                    <div className="optionItem trueAnswer" key={i.id}>
                      {i.label}
                    </div>
                  ) : (
                    <div className="optionItem" key={i.id}>
                      {i.label}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="answer">
            <div className="tureAnswer">
              <span className="tureAnswerTitle">正确答案</span>
              <span className="tureAnswerContent">
                {answerData?.correct_prefix}
              </span>
            </div>
            <div className="thisSkills">
              <span>
                <img className="thisSkillsTitle" src={Idea} alt="" />
              </span>
              <span className="thisSkillsContent">本题技巧</span>
            </div>
            <div
              className="answerAnalysis"
              dangerouslySetInnerHTML={{ __html: answerData?.answer_text }}
            ></div>
          </div>
        </>
      ) : SubjectType === 2 ? (
        <>
          <div className="topic topicAnswerChoice">
            <div className="topicTitle">
              <span className="title">多选题</span>
              <span className="titleValue">
                <span className="titleCurrent">{answerData?.done}</span>/
                {answerData?.count}
              </span>
            </div>
            <div className="topBackground"></div>
            <div className="subjectBriefwrap">
              <div className="subjectTitle">{answerData?.title}</div>
              <div className="subjectOption">
                {AnswerOptions?.map((i) =>
                  i?.is_select === 1 ? (
                    <div className="optionItem falseAnswer" key={i.id}>
                      {i.label}
                    </div>
                  ) : i?.is_select === 2 ? (
                    <div className="optionItem trueAnswer" key={i.id}>
                      {i.label}
                    </div>
                  ) : (
                    <div className="optionItem" key={i.id}>
                      {i.label}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="answer">
            <div className="tureAnswer">
              <span className="tureAnswerTitle">正确答案</span>
              <span className="tureAnswerContent">
                {answerData?.correct_prefix}
              </span>
            </div>
            <div className="thisSkills">
              <span>
                <img className="thisSkillsTitle" src={Idea} alt="" />
              </span>
              <span className="thisSkillsContent">本题技巧</span>
            </div>
            <div
              className="answerAnalysis"
              dangerouslySetInnerHTML={{ __html: answerData?.answer_text }}
            ></div>
          </div>
        </>
      ) : SubjectType === 4 ? (
        <>
          <div className="topic topicAnswerjudge">
            <div className="topicTitle">
              <span className="title">判断题</span>
              <span className="titleValue">
                <span className="titleCurrent">{answerData?.done}</span>/
                {answerData?.count}
              </span>
            </div>
            <div className="topBackground"></div>
            <div className="subjectBriefwrap">
              <div className="subjectTitle">{answerData?.title}</div>
              <div className="subjectOption">
                {AnswerOptions?.map((i) =>
                  i?.is_select === 1 ? (
                    <div className="optionItem falseAnswer" key={i.id}>
                      {i.label}
                    </div>
                  ) : i?.is_select === 2 ? (
                    <div className="optionItem trueAnswer" key={i.id}>
                      {i?.label}
                    </div>
                  ) : (
                    <div className="optionItem" key={i?.id}>
                      {i?.label}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="answer answerJudge">
            <div className="tureAnswer">
              <span className="tureAnswerTitle">正确答案</span>
              <span className="tureAnswerContent">
                {answerData?.correct_prefix}
              </span>
            </div>
            <div className="thisSkills">
              <span>
                <img className="thisSkillsTitle" src={Idea} alt="" />
              </span>
              <span className="thisSkillsContent">本题技巧</span>
            </div>
            <div
              className="answerAnalysis"
              dangerouslySetInnerHTML={{ __html: answerData?.answer_text }}
            ></div>
          </div>
        </>
      ) : (
        <>
          <div className="topic topicAnswerBrief">
            <div className="topicTitle">
              {SubjectType === 3 ? (
                <span className="title">简答题</span>
              ) : (
                <span className="title">案例分析题</span>
              )}
              <span className="titleValue">
                <span className="titleCurrent">{answerData?.done}</span>/
                {answerData?.count}
              </span>
            </div>
            <div className="topBackground"></div>
            <div className="subjectBriefwrap">
              <div className="subjectTitle">{answerData?.title}</div>
              <div className="subjectBriefAnswer">
                <div className="briefPicture">
                  {/* 如果图片存在，设置对应高度动态判断 */}
                  <div
                    className="briefContent"
                    dangerouslySetInnerHTML={{ __html: answerData?.content }}
                  ></div>
                </div>
                <div className="subjectBriefAnswerContent">
                  {answerData?.answer_my}
                </div>
              </div>
            </div>
          </div>
          <div className="answer answerBrief">
            <div className="tureAnswer">
              <span className="tureAnswerTitle">正确答案</span>
              <div
                className="tureBriefAnswerContent"
                dangerouslySetInnerHTML={{ __html: answerData?.answer_text }}
              ></div>
            </div>
            {/* <div className="thisSkills">
              <span>
                <img className="thisSkillsTitle" src={Idea} alt="" />
              </span>
              <span className="thisSkillsContent">本题技巧</span>
            </div>
            <div className="answerAnalysis">{answerData?.analysis}</div> */}
          </div>
        </>
      )}
    </div>
  );
}

export default TestProblemAnswer;
