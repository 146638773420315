export const allPickerData = {
  windUpCertificateColumns: [
    [
      { label: "高中", value: "Mon" },
      { label: "本科", value: "Tues" },
      { label: "大专", value: "Wed" },
      { label: "一本", value: "Thur" },
      { label: "二本", value: "Fri" },
    ],
    [
      { label: "一级证书", value: "am" },
      { label: "二级证书", value: "pm" },
    ],
  ],
  schoolColumns: [
    [
      { label: "初级学校", value: "ass1" },
      { label: "中级学校", value: "ass2" },
      { label: "高级学校", value: "ass3" },
    ],
  ],
  assColumns: [
    [
      { label: "初级", value: "xx1" },
      { label: "中级", value: "xx2" },
      { label: "高级", value: "高gao级" },
    ],
  ],
  educationColumns: [
    [
      { label: "专科", value: "a0" },
      { label: "本科", value: "a1" },
    ],
  ],
  workCityColumns: [
    {
      label: "浙江",
      value: "浙江",
      children: [
        {
          label: "杭州",
          value: "杭州",
          children: [
            {
              label: "西湖区",
              value: "西湖区",
            },
            {
              label: "上城区",
              value: "上城区",
            },
            {
              label: "余杭区",
              value: "余杭区",
              disabled: true,
            },
          ],
        },
        {
          label: "温州",
          value: "温州",
          children: [
            {
              label: "鹿城区",
              value: "鹿城区",
            },
            {
              label: "龙湾区",
              value: "龙湾区",
              disabled: true,
            },
            {
              label: "瓯海区",
              value: "瓯海区",
            },
          ],
        },
        {
          label: "宁波",
          value: "宁波",
          children: [
            {
              label: "海曙区",
              value: "海曙区",
            },
            {
              label: "江北区",
              value: "江北区",
            },
            {
              label: "镇海区",
              value: "镇海区",
            },
          ],
        },
      ],
    },
    {
      label: "安徽",
      value: "安徽",
      children: [
        {
          label: "合肥",
          value: "合肥",
          children: [
            {
              label: "包河区",
              value: "包河区",
            },
            {
              label: "蜀山区",
              value: "蜀山区",
            },
            {
              label: "瑶海区",
              value: "瑶海区",
            },
          ],
        },
        {
          label: "芜湖",
          value: "芜湖",
          children: [
            {
              label: "镜湖区",
              value: "镜湖区",
            },
            {
              label: "弋江区",
              value: "弋江区",
            },
            {
              label: "湾沚区",
              value: "湾沚区",
            },
          ],
        },
      ],
    },
    {
      label: "江苏",
      value: "江苏",
      children: [
        {
          label: "南京",
          value: "南京",
          children: [
            {
              label: "玄武区",
              value: "玄武区",
            },
            {
              label: "秦淮区",
              value: "秦淮区",
            },
            {
              label: "建邺区",
              value: "建邺区",
            },
          ],
        },
        {
          label: "苏州",
          value: "苏州",
          children: [
            {
              label: "虎丘区",
              value: "虎丘区",
            },
            {
              label: "吴中区",
              value: "吴中区",
            },
            {
              label: "相城区",
              value: "相城区",
            },
          ],
        },
      ],
    },
  ],
  // 固定值
  expectPositionColumns: [
    [
      { label: "家庭护理员", value: 1 },
      { label: "养老护理员", value: 2 },
    ],
  ],
  salaryRequireColumns: [
    [
      { label: "10000以上", value: "d1" },
      { label: "12000左右", value: "d2" },
      { label: "16000左右", value: "d3" },
    ],
  ],
  entryPositionColumns: [
    [
      { label: "在职护理员", value: "E1" },
      { label: "入职岗位", value: "E2" },
    ],
  ],
  rentalTypeColumns: [
    [
      { label: "一居室", value: "r1" },
      { label: "二居室", value: "r2" },
      { label: "三居室", value: "r3" },
    ],
  ],
  prizeCertificateColumns: [
    [
      { label: "计算机一级证书", value: "y4" },
      { label: "护理员一级证书", value: "y5" },
      { label: "计算机二级证书", value: "y6" },
    ],
  ],
};
