import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Toast } from "antd-mobile";
import InfoTableHeader from "../../../components/InfoTableHeader";
import InfoTableItem from "../../../components/InfoTableItem";
import { infoTableItemData } from "../../../utils/allInfoTableItem";
import options from "../../../utils/address";
import { myAwardBaseInfo, rentApi } from "../../../services";
import "./index.less";

function RentPurchaseAward() {
  const navigate = useNavigate();
  const [timerValue, setTimerValue] = useState("");
  const [timerValue1, setTimerValue1] = useState("");
  const [rentalType, setRentalType] = useState("");
  const [workCity, setWorkCity] = useState([]);
  const [res, setRes] = useState();
  useEffect(() => {
    fn();
  }, []);

  const fn = async () => {
    let res = await myAwardBaseInfo();
    setRes(res?.data);
  };

  const getTimer = (v) => {
    setTimerValue(v);
  };
  let startTimer = moment(timerValue).format("YYYY-MM-DD");
  const getTimer1 = (v) => {
    setTimerValue1(v);
  };
  let endTimer = moment(timerValue1).format("YYYY-MM-DD");
  const getRentalType = (v) => {
    setRentalType(v);
  };
  const getWorkCity = (v) => {
    setWorkCity(v);
  };
  let address = workCity
    ?.map((i) => {
      return i.label;
    })
    .join("");
  const rentalTypeColumns =
    res?.data_rent_apply?.map((item) => {
      item.label = item.name;
      item.value = item.id;
      return item;
    }) || [];
  const addData = {
    apply_type_id: rentalType[0],
    detailed_address: address,
    start_time: startTimer,
    end_time: endTimer,
  };
  const onFinishRentPurchase = async (values) => {
    if (addData?.start_time === "Invalid date") {
      Toast.show(`请选择租赁开始时间`, 2);
    } else if (addData?.end_time === "Invalid date") {
      Toast.show(`请选择租赁结束时间`, 2);
    } else {
      let res = await rentApi({ ...values, ...addData });
      if (res.code === 404) {
        navigate("/applytips", {
          state: {
            type: res.data.type,
          },
        });
      }
    }
  };
  return (
    <div className="rentPurchaseAward">
      <InfoTableHeader />
      <InfoTableItem
        initData={{ ...res }}
        precision="day"
        formatData="YYYY-MM-DD"
        precision1="day"
        formatData1="YYYY-MM-DD"
        getTimer={getTimer}
        getTimer1={getTimer1}
        getWorkCity={getWorkCity}
        getRentalType={getRentalType}
        FormItemData={infoTableItemData?.rentPurchaseAwardForm}
        workCityOptions={options}
        rentalTypeColumns={[rentalTypeColumns]}
        onFinish={onFinishRentPurchase}
      />
    </div>
  );
}

export default RentPurchaseAward;
