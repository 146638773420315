import React from "react";
import "./index.less";

function BalloonLeft(props) {
  const { chatContent, avatar } = props;
  return (
    <div className="balloonLeftWrap">
      <img className="avatarleft" src={avatar} alt="" />
      <div className="balloonLeft">{chatContent}</div>
    </div>
  );
}

export default BalloonLeft;
