import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InfoTableHeader from "../../../components/InfoTableHeader";
import InfoTableItem from "../../../components/InfoTableItem";
import { infoTableItemData } from "../../../utils/allInfoTableItem";
import { cultivateApi } from "../../../services/index";
import "./index.less";

function StudentsApply() {
  const navigate = useNavigate();

  const onFinishStudentsApply = async (values) => {
    let res = await cultivateApi(values);
    if (res.code === 404) {
      navigate("/applytips", {
        state: {
          type: res.data.type,
        },
      });
    }
  };
  return (
    <div className="studentsApply">
      <InfoTableHeader />
      <InfoTableItem
        FormItemData={infoTableItemData?.studentsApplyForm}
        onFinish={onFinishStudentsApply}
      />
    </div>
  );
}

export default StudentsApply;
