import React from "react";
import Icon from "../../image/questionType/icon.png";
import { List } from "antd-mobile";
import "./index.less";

function QuestionType(props) {
  const { Number } = props;
  return (
    <div className="questionType">
      <div className="questionTypeTop">
        <span>
          <img
            style={{ width: ".56rem", height: ".56rem" }}
            src={Icon}
            alt=""
          />
        </span>
        <span style={{ marginTop: ".1rem" }}>题型</span>
      </div>
      <div className="questionTypeList">
        <div className="typeItem">
          <List header="基础用法">
            {Number?.map((i) => (
              <List.Item key={i.type} onClick={() => props.handleType(i.type)}>
                {i.type_name}
                <span className="typeNumber">{i.num}</span>
              </List.Item>
            ))}
          </List>
        </div>
      </div>
    </div>
  );
}

export default QuestionType;
