import React, { useState, useEffect, useRef } from "react";
import { Input } from "antd-mobile";
import { useLocation } from "react-router-dom";
import BalloonLeft from "../../../components/BalloonLeft";
import BalloonRight from "../../../components/BalloonRight";
import SendOut from "../../../image/chatImg/sendOut.png";
import Refresh from "../../../image/chatImg/refresh.png";
import Head from "../../../image/chatImg/head.png";
import { comRecDetails, sendMessage } from "../../../services/index.js";
import "./index.less";

function Chat() {
  const { state } = useLocation();
  const messagesEndRef = useRef(null);
  const messagesTopRef = useRef(null);
  const [value, setValue] = useState("");
  const [data, setData] = useState("");
  const [message, setMes] = useState([]);
  useEffect(() => {
    let body = document.body;
    body.style.backgroundColor = "#ffffff";
    fn();
    if (message.length <= 5) {
      scrollToTop();
    } else {
      scrollToBottom();
    }
  }, []);
  window.addEventListener(
    "popstate",
    function (e) {
      let body = document.body;
      body.style.backgroundColor = "#F7FBFF";
    },
    false
  );
  useEffect(() => {
    scrollToBottom();
  }, [message]);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToTop = () => {
    messagesTopRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const fn = async () => {
    let res = await comRecDetails(state);
    setData(res?.data);
    setMes(res?.data?.message);
  };
  const sendOut = async () => {
    if (state.message_id) {
      let res = await sendMessage({
        ...state,
        content: value,
      });
      if (res.code === 1) {
        setMes([...message, { content: value, source: "member" }]);
        setValue("");
      }
    } else {
      let res = await sendMessage({
        ...state,
        content: value,
        job_id: data?.member?.job_id,
      });
      if (res.code === 1) {
        setMes([...message, { content: value, source: "member" }]);
        setValue("");
      }
    }
  };
  return (
    <div className="chat">
      <div className="chatTop">
        <div className="contactInfo flexBoxBetween">
          <span className="telphone">联系方式：{data?.position?.phone}</span>
          {/* <span
            onClick={handleInterest}
            className={isHaveInterest === true ? "interestDefault" : "interest"}
          >
            <CloseCircleFill />
            <span>不感兴趣</span>
          </span> */}
        </div>
        <div className="businessCard">
          <div className="itemLineOne flexBoxBetween">
            <span className="OneTitle">{data?.position?.title}</span>
            <span className="OneMoney">{data?.position?.wages_name}</span>
          </div>
          <div className="itemLineTwo">
            <div className="itemLineTwoLf">
              <span className="TwoSpan">
                {data?.position?.region?.parent?.parent?.name}
              </span>
              <span className="TwoSpan">{data?.position?.empiric_name}</span>
              <span className="TwoSpan">
                {data?.position?.min_education_name}
              </span>
            </div>
            <div className="itemLineTwoRg">
              <span>
                {data?.position?.region?.parent?.parent?.name}&nbsp;
                {data?.position?.region?.parent?.name}&nbsp;
                {data?.position?.region?.name}
              </span>
            </div>
          </div>

          <div className="itemLineThreechat">
            <span className="ThreeSpan1">{data?.position?.yanglao?.name}</span>
            {data?.position?.yanglao?.name &&
            data?.position?.yanglao?.people_num ? (
              <span className="ThreeSpanLine"></span>
            ) : (
              ""
            )}

            <span className="ThreeSpan2">
              {data?.position?.yanglao?.people_num}
            </span>
          </div>
        </div>
        <div className="tipNews">最新消息，请刷新查看</div>
      </div>
      <div className="messageBoard">
        <div ref={messagesTopRef} />
        {message?.map((i, index) =>
          message[index]?.source === "organization" ? (
            <BalloonLeft
              key={index}
              avatar={
                data?.org_member?.avatar || data?.org_member?.headimgurl || Head
              }
              chatContent={message[index]?.content}
            ></BalloonLeft>
          ) : message[index]?.source === "member" ? (
            <BalloonRight
              key={index}
              avatar={data?.member?.avatar || data?.member?.headimgurl || Head}
              chatContent={message[index]?.content}
            ></BalloonRight>
          ) : (
            ""
          )
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="chatBottom flexBoxBetween">
        <Input
          placeholder="请输入内容"
          value={value}
          onChange={(val) => {
            setValue(val);
          }}
          onEnterPress={sendOut}
        />
        <img className="senoutImg" src={SendOut} alt="" onClick={sendOut} />
        <img
          className="refreshImg"
          src={Refresh}
          alt=""
          onClick={() => {
            fn();
          }}
        />
      </div>
    </div>
  );
}

export default Chat;
