import React from "react";
import { Toast } from "antd-mobile";
import "./index.less";

function PublicApplySuccess(props) {
  const { data, sourse } = props;

  const copyAccessKeyOne = (params) => {
    var copyDOM = document.querySelector(params);
    var range = document.createRange();
    window.getSelection().removeAllRanges();
    range.selectNode(copyDOM);
    window.getSelection().addRange(range);
    var successful = document.execCommand("copy");
    if (successful) {
      Toast.show({
        icon: "success",
        content: "复制成功!",
      });
    } else {
      Toast.show({
        icon: "fail",
        content: "复制失败，请手动复制!",
      });
    }
    // 移除选中的元素
    window.getSelection().removeAllRanges();
  };
  return (
    <div className="publicApplySuccess">
      <img className="tipsPicture" src={data?.image} alt="" />
      <div className="tipsTitle">{data?.title}</div>
      <div dangerouslySetInnerHTML={{ __html: data?.show_text }}></div>
      {sourse ? (
        <div className="tipsBackDetails">
          <div className="details ContentOne">
            <div className="detailsTitle flexBoxBetween">
              <span className="titleLeft">后台地址:</span>
              <span
                className="titleReight"
                onClick={() => copyAccessKeyOne(".ContentOne p")}
              >
                一键复制
              </span>
            </div>
            <p className="detailsContent ">{sourse?.url}</p>
          </div>
          <div className="details ContentTwo">
            <div className="detailsTitle flexBoxBetween">
              <span className="titleLeft">后台账号:</span>
              <span
                className="titleReight"
                onClick={() => copyAccessKeyOne(".ContentTwo p")}
              >
                一键复制
              </span>
            </div>
            <p className="detailsContent">{sourse?.username}</p>
          </div>
          <div className="details ContentThree">
            <div className="detailsTitle flexBoxBetween">
              <span className="titleLeft">后台密码:</span>
              <span
                className="titleReight"
                onClick={() => copyAccessKeyOne(".ContentThree p")}
              >
                一键复制
              </span>
            </div>
            <p className="detailsContent">{sourse?.password}</p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default PublicApplySuccess;
